import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import User from '../models/UserStore'

const Context = React.createContext({
  totalNotifications: 0,
  loading: null,
  resetTotalNotifications: () => {}
})

export const TotalNotificationsProvider = ({ children }) => {
  const location = useLocation()
  const [totalNotifications, setTotalNotifications] = useState(0)

  useEffect(() => {
    getUserNotifications()
  }, [location.key])

  const resetTotalNotifications = () => {
    setTotalNotifications(0)
  }

  const getUserNotifications = async () => {
    const userId = User.getUid()
    if (userId === 'null' || !userId) return
    try {
      const { data } = await User.getTotalNotifications(userId)
      setTotalNotifications(data.total)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Context.Provider value={{ totalNotifications, resetTotalNotifications }}>
      {children}
    </Context.Provider>
  )
}

export default Context
