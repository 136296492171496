import { observable, decorate, action } from 'mobx'
import User from '../models/UserStore'

class UserStore {
  userProfile = {}
  currentUser ={}
  loading = false
  preferences = {}
  countries = []
  professions = []
  tempToken = ''

  getProfile() {
    const userId = User.getUid()
    this.loading = true
    User.getProfile(userId)
      .then(({ data }) => {
        this.userProfile = data
        this.preferences = data.preferences
        this.countries = data.form.country
        this.professions = data.form.profession
      })
      .catch(error => console.error(error))
      .finally(() => {
        this.loading = false
      })
  }

  getUserProfile() {
    const userId = User.getUid()
    this.loading = true
    User.getUserProfile(userId)
      .then(({ data }) => {
        this.currentUser = data
      })
      .catch(error => console.error(error))
      .finally(() => {
        this.loading = false
      })
  }

  setTempToken(token) {
    this.tempToken = token
  }

  deleteTempToken() {
    this.tempToken = ''
  }
}

export default decorate(UserStore, {
  userProfile: observable,
  currentUser: observable,
  preferences: observable,
  countries: observable,
  professions: observable,
  tempToken: observable,
  getProfile: action,
  getUserProfile: action,
  setTempToken: action,
  deleteTempToken: action
})
