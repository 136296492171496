import axios from 'axios'
import { API_HOST, API } from '../constants/Constants'
import Storage from 'localStorage'

export default class ApiUser {
  static login = (email, password) => {
    return axios.post(`${API_HOST}/api/v1/user/sign_in`, {
      email: email,
      password: password
    })
  }

  static register = user => {
    return axios.post(`${API_HOST}/api/v1/user`, user)
  }

  static rememberPass = email => {
    return axios.post(`${API_HOST}/api/v1/action/change/password`, {
      email: email
    })
  }

  static updatePassword = (token, password) => {
    return axios.post(`${API_HOST}/api/v1/action/update/password`, {
      token: token,
      password: password
    })
  }

  static uploadImage = form => {
    return API.post(`https://api.seenapse.it/action/photoupload`, form)
  }

  static getUser = id => {
    var langStore = Storage.getItem('lang')
    return API.get(`${API_HOST}/api/v1/user/${id}?lang=${langStore}`)
  }

  static update = (user_id, user) => {
    return API.put(`${API_HOST}/api/v1/user/${user_id}`, user)
  }

  static createUserBySocial = user => {
    return axios.post(`${API_HOST}/api/v1/user`, user)
  }

  static deleteAccount = user_id => {
    return API.delete(`${API_HOST}/api/v1/user/${user_id}`)
  }
}
// http://localhost:3000/#/login/TWITTER/FOUND/5941/false/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo1OTQxLCJhZG1pbiI6ZmFsc2UsImlhdCI6MTU4MzEzMDc2OSwiZXhwIjoxNTg0MzQwMzY5fQ.yRa5T7Olhc25ePtUwfZI4VrLFZvwapH91qV1grVFTcQ
