import { useState } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { parseProxyData } from '../../../../utils/Utils';
import { ShareBranchy } from '../../../../services/seenapse';
import useNavigation from '../../../../hooks/useNavigation';
import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
})


const initialState = hookstate({
  isOpenModal: false,
  isLoding: false,
});

export default function useShare() {
  const { isOpenModal, isLoding } = useHookstate(initialState);

  const { searchParams } = useNavigation()

  const onOpenModal = () => isOpenModal.set(true);
  const onCloseModal = () => isOpenModal.set(false);

  const onChangeLoading = (value) => isLoding.set(value);

  const onSubmitProject = (values) => {
    onChangeLoading(true);
    ShareBranchy({
      projectUid: searchParams.projectUid,
      email: values.email.trim()
    })
      .then(() => {
        onCloseModal();
        onChangeLoading(false);
      })
      .catch((error) => {
        console.log('Error:', error);
      })
      .finally(() => {
        onChangeLoading(false);
      })
  };

  return {
    isOpenModal: parseProxyData(isOpenModal.get()),
    isLoding: parseProxyData(isLoding.get()),
    onOpenModal,
    onCloseModal,
    onSubmitProject
  }
}
