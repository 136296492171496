import React, { useEffect } from 'react';
import Storage from 'localStorage';
import Loader from '../../components/loader';
import { SsoService } from '../../services/seenapse';
import { parseJwt } from '../../utils/Utils';

const SSO = 'sso';
export default function Logout() {
  const clearSession = () => {
    Storage.clear();
    // eslint-disable-next-line no-undef
    window.location.href = ('/');
  };

  const addSsoService = () => {
    const token = Storage.getItem('token') || '';
    const parseToken = token && parseJwt(token);
    if (parseToken.type === SSO) {
      Storage.clear();
      // eslint-disable-next-line no-undef
      // window.location = 'https://api-v2.seenapse.it/sso/auth/signout/idp1?target_url=https://seenapse.ai';
      // window.location = 'https://api-v2.seenapse.it/sso/auth/signout/pprd?target_url=https://seenapse.ai';
      window.location = 'https://api-v2.seenapse.it/sso/auth/signout/prod?target_url=https://seenapse.ai';
    } else {
      clearSession();
    }
  };

  useEffect(() => {
    addSsoService();
  });


  return (
    <Loader style={{ margin: '0 auto', zIndex: 9 }} />
  );
}


// https://test-edutools.tec.mx/saml_login
// https://api-v2.seenapse.it/sso/auth/signin/pprd
// https://tecgpt-pprd.azurewebsites.net
// t-rafajimenez@itesm.mx
// kJ1%cU1%cI2$