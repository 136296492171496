import React from 'react';
import Translate from '../../../../utils/Translate';
import Close from '../../../../components/icons/close';
import useShare, { validationSchema } from './useShare';
import { Form, Formik } from 'formik';
import Spinner from '../../../../components/spinner';

export default function ShareModal({ isOpenDialog, onCloseModal, projectName }) {
  const {
    onSubmitProject,
    isLoding
  } = useShare()

  if (!isOpenDialog) return null;

  return (
    <div className="project-dialog">
      <div className="project-dialog-ctn !h-auto">
        <div className="project-dialog-close">
          <Close color="black" onClick={onCloseModal} />
        </div>
        <div className="project-dialog-title mb-10">
          {Translate.get('pinnShareProject', 'Share project:')}
          <span className="project-dialog-title-name"> {projectName}</span>
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: '' }}
          onSubmit={onSubmitProject}
        >
          {({ errors, handleChange, values, touched }) => {
            return (
              <Form>
                <div className="project-dialog-description !h-full relative">
                  <input
                    autoFocus
                    name="email"
                    value={values.email}
                    onChange={handleChange('email')}
                    className="generate-ideas-input"
                    placeholder='e.g. "example@gmail.com"'
                  />
                  {errors.email && touched.email ? <div className="text-red-500 text-[14px] mt-2 absolute top-7 left-1">{errors.email}</div> : null}
                  <div className='flex justify-between items-center gap-5 mt-7'>
                    <button
                      onClick={onCloseModal}
                      className="generate-ideas-button-secondary w-full"
                    >
                      {Translate.get('cancel', 'Cancel')}
                    </button>
                    <button
                      type="submit"
                      disabled={isLoding}
                      className="generate-ideas-button w-full !disabled:opacity-50 disabled:bg-[#D6DD3D]"
                    >
                      {isLoding
                        ? <Spinner style={{ margin: '0 auto', borderLeftColor: '#000' }} />
                        : Translate.get('pinSaveButton', 'Share')
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
