import React, {Component, Fragment} from 'react';
import { inject, observer } from 'mobx-react';
import Detail from '../../containers/Detail';

class NewSeenapse extends Component {
  componentDidMount() {
    if (!this.props.match.params.parent) {
      this.props.history.push('/');
      return;
    } 
    document.querySelector('body').classList.remove('locked');
    document.querySelector('body').style.top = ``;
    this.props.modalStore.removeBlur();
  }

  render() {
    const {match} = this.props; 
    return (
      <Fragment>
        <Detail
            open={true}
            isNew={true}
            parent={match.params.parent}
            child={match.params.child}
          />
      </Fragment>
    )
  }
}

export default inject(
  'modalStore'
)(observer(NewSeenapse));
