import React, { useEffect, useState, Fragment, Component } from 'react';
import { useHistory } from 'react-router-dom';
import User from '../models/UserStore';
import mixpanel from 'mixpanel-browser';
import Intercom from '@intercom/messenger-js-sdk';
import Loader from '../components/loader';
import MessageLogin from '../shared/alert/MessageLogin';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase/app'
import firebaseConfig from '../config/firebase'
import { validateTrialFinish } from '../utils/Utils';
import useUser from '../hooks/useUser';

firebase.initializeApp(firebaseConfig)

const AppComponent = (props) => {
  const [userId] = useState(User.getUid());
  const { user, loadingUser } = useUser()

  const { modalStore } = props;
  const history = useHistory();
  const isClimate = history.location.pathname.includes('climate');

  useEffect(() => {
    if (user.id) {
      mixpanel.identify(userId);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const currentDate = new Date();
      const signupDateUnixTimestamp = Math.floor(currentDate.getTime() / 1000);

      Intercom({
        app_id: 'kyddub70',
        user_id: userId,
        name: user.name,
        email: user.email,
        created_at: signupDateUnixTimestamp,
      });

      const showTrialFinish = validateTrialFinish(user)
      if (showTrialFinish) {
        return history.push('/subscription');
      }

      if (
        userId
        && !isClimate
        && !showTrialFinish
        && history.location.pathname === '/app'
      ) {
        history.push('/ai/project/chat');
      }
    }
  }, [user]);

  return (
    <Fragment>
      {modalStore.showMessageLogin && <MessageLogin />}
      {loadingUser && (
        <Loader
          style={{
            marginTop: 80,
            zIndex: 500,
            backdropFilter: 'blur(12px)'
          }}
        />
      )}
    </Fragment>
  );
};

class App extends Component {
  render() {
    return <AppComponent {...this.props} />
  }
}

export default inject('modalStore')(observer(App));
