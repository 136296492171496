import React from 'react'
import { IMAGES_BUCKET } from '../../config'
import withLoadingBackground from '../../utils/withLoadingBackground'

const SeenapseParent = function(props){
  return <a
    target="_blank"
    href={props.url}
    className="seenapseImg top"
    style={{
      backgroundColor: props.backgroundColor,
      backgroundImage:`url(${IMAGES_BUCKET}${props.img})`
    }}
  ><span>{props.name}</span></a>
}

export default withLoadingBackground(SeenapseParent);