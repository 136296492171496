import React from 'react';

export default function Folder({ onClick }) {
  return (
    <button type="button" className="icon-button" onClick={onClick}>
      <svg
        width="25"
        height="20"
        viewBox="0 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9168 3.73685V2.93684C22.9171 2.45889 22.7296 2.00035 22.3958 1.66185C22.0618 1.32346 21.6085 1.13266 21.1356 1.13158H12.0053L11.673 0.661621C11.3797 0.24649 10.9063 0.00012207 10.4017 0H2.60416C1.91374 0.00088501 1.25182 0.278397 0.763657 0.771698C0.275497 1.26501 0.000854492 1.93388 0 2.63159V17.3684C0.000854492 18.0661 0.275497 18.735 0.763657 19.2283C1.25182 19.7216 1.91371 19.9991 2.60416 20H22.3958C23.0862 19.9991 23.7482 19.7216 24.2363 19.2283C24.7245 18.735 24.9991 18.0661 25 17.3684V6.3158C24.9992 5.70935 24.7917 5.12172 24.4121 4.65199C24.0327 4.18227 23.5046 3.85901 22.9168 3.73685ZM21.1356 2.18423C21.3323 2.18523 21.5207 2.26494 21.6593 2.40605C21.7979 2.54716 21.8756 2.73807 21.8752 2.93686V3.68422H14.0777C13.9093 3.68422 13.7515 3.6021 13.6537 3.46371L12.7495 2.18423H21.1356ZM23.9584 17.3685C23.9584 17.7873 23.7937 18.1889 23.5007 18.485C23.2077 18.7811 22.8102 18.9474 22.3958 18.9474H2.60419C2.1898 18.9474 1.79233 18.7811 1.49939 18.485C1.2063 18.1889 1.04169 17.7873 1.04169 17.3685V2.63167C1.04169 2.21292 1.20628 1.81126 1.49939 1.51523C1.79236 1.21907 2.1898 1.05272 2.60419 1.05272H10.4017C10.57 1.05272 10.7279 1.13484 10.8256 1.27322L12.8048 4.07532C13.0986 4.49055 13.5726 4.73692 14.0777 4.73692H22.396C22.8104 4.73692 23.2079 4.90326 23.5008 5.19943C23.7939 5.4955 23.9585 5.89711 23.9585 6.31587L23.9584 17.3685Z"
          fill="#D6DD3D"
        />
      </svg>
    </button>
  );
}
