import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import './notification.css'

import User from '../../models/UserStore'
import BuildPeopleResult from './BuildPeopleResult'
import BuildSeenapseResult from './BuildSeenapseResult'

const NotificationResult = ({ element, index }) => {
  const match = useRouteMatch()
  const userId = User.getUid()
  const [loading, setLoading] = useState(false)
  const [notify, setNotify] = useState(element)

  const handleUpdateFollow = followedId => {
    new Array(notify).filter(item => {
      if (item.id === followedId) {
        const isFollow = item.i_follow_it ? false : true
        const elementFiltered = { ...item, i_follow_it: isFollow }
        setNotify(elementFiltered)
      }
    })
  }

  const handleUnfollow = async followedId => {
    setLoading(true)
    const params = {
      user_id: userId,
      followed_id: followedId
    }
    try {
      await User.followAndUnflollow(params)
      handleUpdateFollow(followedId)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div key={index} className='notification-container'>
      <BuildPeopleResult element={notify} index={index} />
      <BuildSeenapseResult
        element={notify}
        index={index}
        match={match}
        loading={loading}
        onClick={handleUnfollow}
      />
    </div>
  )
}
export default NotificationResult
