
import React from 'react';

export default function Trash({ onClick, ...props }) {
  return (
    <button
      {...props}
      type="button"
      className={`${props.className} icon-button text-[#D6DD3D]`}
      onClick={onClick}
      >
      <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.79091 5.41666H2.24092V17.0833C2.24184 17.8566 2.57402 18.598 3.16451 19.1448C3.75507 19.6916 4.55576 19.9991 5.39091 20H13.4909C14.326 19.9991 15.1268 19.6916 15.7173 19.1448C16.3078 18.598 16.64 17.8566 16.6409 17.0833V5.41666H17.0909C17.4489 5.41666 17.7924 5.28497 18.0455 5.05051C18.2987 4.81612 18.4409 4.49818 18.4409 4.16666V3.33333C18.4409 3.00183 18.2987 2.68384 18.0455 2.44948C17.7923 2.21503 17.4489 2.08333 17.0909 2.08333H13.0409V1.24998C13.0409 0.918488 12.8987 0.60051 12.6455 0.36615C12.3923 0.131683 12.049 0 11.6909 0H7.19092C6.83289 0 6.48947 0.131668 6.23634 0.36615C5.98314 0.60051 5.84091 0.918488 5.84091 1.24998V2.08333H1.79091C1.43289 2.08333 1.08946 2.21501 0.836349 2.44948C0.583145 2.68385 0.440918 3.00183 0.440918 3.33333V4.16666C0.440918 4.49818 0.583115 4.81615 0.836349 5.05051C1.08949 5.28496 1.43289 5.41666 1.79091 5.41666ZM15.7409 17.0833C15.7402 17.6357 15.5029 18.1652 15.0811 18.5557C14.6593 18.9462 14.0875 19.166 13.4909 19.1667H5.39091C4.7944 19.166 4.22247 18.9462 3.80072 18.5557C3.37894 18.1652 3.14166 17.6357 3.1409 17.0833V5.41666H15.7409V17.0833ZM6.74094 1.24998C6.74094 1.1395 6.78836 1.03351 6.87271 0.955368C6.95709 0.877258 7.07156 0.833328 7.19092 0.833328H11.6909C11.8102 0.833328 11.9247 0.877258 12.0091 0.955368C12.0935 1.03351 12.1409 1.1395 12.1409 1.24998V2.08333H6.74094V1.24998ZM1.3409 3.33333C1.3409 3.22284 1.38837 3.11684 1.47275 3.03871C1.5571 2.96059 1.67155 2.91666 1.79091 2.91666H17.0909C17.2103 2.91666 17.3247 2.96059 17.4091 3.03871C17.4935 3.11684 17.5409 3.22284 17.5409 3.33333V4.16666C17.5409 4.27718 17.4935 4.38316 17.4091 4.46127C17.3247 4.53941 17.2103 4.58333 17.0909 4.58333H1.79091C1.67155 4.58333 1.55713 4.53941 1.47275 4.46127C1.38837 4.38316 1.3409 4.27718 1.3409 4.16666V3.33333Z"
          fill="currentColor"
        />
        <path
          d="M5.84042 17.7517C6.19821 17.7517 6.54138 17.6096 6.79428 17.3565C7.04733 17.1036 7.18942 16.7604 7.18942 16.4027V6.95963C7.18942 6.47763 6.93228 6.03235 6.51495 5.79132C6.0976 5.55035 5.58328 5.55035 5.16592 5.79132C4.7486 6.03235 4.49146 6.47763 4.49146 6.95963V16.4027C4.49146 16.7604 4.63354 17.1036 4.88657 17.3565C5.13953 17.6096 5.48264 17.7517 5.84042 17.7517ZM5.39078 6.95963C5.39078 6.79898 5.4765 6.65054 5.6156 6.57019C5.75473 6.48993 5.92615 6.48993 6.06528 6.57019C6.20441 6.65057 6.2901 6.79898 6.2901 6.95963V16.4027C6.2901 16.5634 6.20441 16.7117 6.06528 16.7921C5.92615 16.8724 5.75473 16.8724 5.6156 16.7921C5.4765 16.7117 5.39078 16.5633 5.39078 16.4027V6.95963Z"
          fill="currentColor"
        />
        <path
          d="M9.4408 17.7517C9.79852 17.7517 10.1417 17.6096 10.3947 17.3565C10.6476 17.1036 10.7898 16.7604 10.7898 16.4027V6.95963C10.7898 6.47763 10.5327 6.03235 10.1153 5.79132C9.698 5.55035 9.18359 5.55035 8.7663 5.79132C8.34894 6.03235 8.0918 6.47763 8.0918 6.95963V16.4027C8.0918 16.7604 8.23389 17.1036 8.48694 17.3565C8.73987 17.6096 9.08301 17.7517 9.4408 17.7517ZM8.99109 6.95963C8.99109 6.79898 9.07684 6.65054 9.21594 6.57019C9.35504 6.48993 9.52649 6.48993 9.66559 6.57019C9.80475 6.65057 9.89044 6.79898 9.89044 6.95963V16.4027C9.89044 16.5634 9.80475 16.7117 9.66559 16.7921C9.52649 16.8724 9.3551 16.8724 9.21594 16.7921C9.07684 16.7117 8.99109 16.5633 8.99109 16.4027V6.95963Z"
          fill="currentColor"
        />
        <path
          d="M13.0414 17.7517C13.3992 17.7517 13.7423 17.6096 13.9952 17.3565C14.2483 17.1036 14.3904 16.7604 14.3904 16.4027V6.95963C14.3904 6.47763 14.1332 6.03235 13.7159 5.79132C13.2986 5.55035 12.7842 5.55035 12.3669 5.79132C11.9496 6.03235 11.6924 6.47763 11.6924 6.95963V16.4027C11.6924 16.7604 11.8345 17.1036 12.0875 17.3565C12.3405 17.6096 12.6837 17.7517 13.0414 17.7517ZM12.5917 6.95963C12.5917 6.79898 12.6775 6.65054 12.8165 6.57019C12.9557 6.48993 13.1271 6.48993 13.2662 6.57019C13.4054 6.65057 13.4911 6.79898 13.4911 6.95963V16.4027C13.4911 16.5634 13.4054 16.7117 13.2662 16.7921C13.1271 16.8724 12.9557 16.8724 12.8165 16.7921C12.6775 16.7117 12.5917 16.5633 12.5917 16.4027V6.95963Z"
          fill="currentColor"
        />
      </svg>
    </button>
  );
}
