import React from 'react';
import './loader.css';
import loader from '../../assests/img/Loader.gif';

const Loader = ({ style }) => (
  <div className="loader" style={style}>
    <img src={loader} alt="loader" />
  </div>
);

export default Loader;
