import React from 'react';
import { createPortal } from 'react-dom';
import { inject, observer } from 'mobx-react';


import './dialog.css';

const Modal = ({ modalStore, children, active, onClose }) => {
  const classes = ["dialog"]
  return (
    active && (
      <div
        role="button"
        className={classes.join(" ")}
        style={{
          filter: `blur(${
            modalStore.blurOnModalSession ? modalStore.blurSession : "0"
          })`,
        }}
      >
        <div role="button" className="background" onClick={onClose} />
        {children}
      </div>
    )
  )
}

const Dialog = ({
  children,
  onClose,
  active,
  testId,
  modalStore,
}) => {
  const target = document.getElementById('modal-root');
  return createPortal(
    <Modal
      active={active}
      testId={testId}
      modalStore={modalStore}
      onClose={onClose}
    >
      {children}
    </Modal>,
    target
  )
}
export default inject('modalStore')(observer(Dialog));

