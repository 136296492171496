import React from "react"
import { inject, observer } from "mobx-react"
import Link from "react-router-dom/Link"
import { withRouter } from "react-router-dom"
import Storage from "localStorage"
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share"
import LogoFacebook from "react-ionicons/lib/LogoFacebook"
import LogoTwitter from "react-ionicons/lib/LogoTwitter"
import IosMail from "react-ionicons/lib/IosMail"

import UserStore from "../../models/UserStore"
import { updateFavs } from "../../services/favs"
import withLoadingBackground from "../../utils/withLoadingBackground"
import avatar from "../../assests/img/avatar-default.png"
import "./SeenapseActions.css"
import { signOut, parseJwt } from "../../utils/Utils"

export class SeenapseActions extends React.Component {
  state = {
    uid: UserStore.getUid(),
    share: false,
    liked: false,
    favs: 0,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.favs) {
      const liked = !!nextProps.favs.data.find(
        (fav) => fav.id === parseInt(this.state.uid)
      )
      this.setState({ liked, favs: nextProps.favs.data.length })
    }
  }
  getResultFavs = () => {
    return updateFavs(this.props.id).catch((e) =>
      console.log(e, "error al consumir el endpoint de favs")
    )
  }
  likeIt = () => {
    if (!this.state.uid) {
      this.props.modalStore.openMessageLogin()
      return
    }
    this.getResultFavs().then(({ data }) => {
      if (data.errors.length > 0) {
        return signOut(this.props.history)
      }
      if (this.state.uid) {
        if (!data.errors.length) {
          const liked = !this.state.liked
          let favs = this.state.favs
          if (liked) {
            favs++
          } else {
            favs--
          }
          this.setState({
            liked,
            favs,
          })
        }
      }
    })
  }
  changeShareStatus = (share) => {
    this.setState({ share })
  }

  render() {
    const { user, palias, alias, backgroundColor, match, user_id } = this.props
    let {
      match: {
        params: { userId: userId },
      },
      handleOpenModal,
    } = this.props
    const { share, uid } = this.state
    const url = `${window.location.origin}${match.url}`
    userId = (userId && parseInt(userId)) || parseInt(user_id)
    const token = Storage.getItem("token") || ""
    const parseToken = token && parseJwt(token)
    return (
      <React.Fragment>
        <ul className="menu">
          <li
            onClick={parseToken.admin ? handleOpenModal : null}
            className={parseToken.admin ? "" : "hide"}
          >
            {/* <img src={idea} width="65%" alt="Idea" /> */}
          </li>
          {(userId === parseInt(uid) || parseInt(uid) === 131) && (
            <li>
              <Link className="edit" to={`/${palias}/${alias}/edit/`}>
                <span>Edit</span>
              </Link>
            </li>
          )}
          <li>
            {user && (
              <Link
                className="avatar-user"
                to={`/user/${userId}/seenapses`}
                style={{ backgroundImage: `url(${user.img}), url(${avatar})` }}
              />
            )}
            {!user && (
              <Link
                className="avatar-user"
                to={`/user/${userId}/seenapses`}
                style={{
                  backgroundColor,
                  backgroundImage: `url(${avatar})`,
                }}
              />
            )}
          </li>
        </ul>
        {share && (
          <div className="container-share">
            <p>Share this seenapse via:</p>
            <FacebookShareButton url={url}>
              <LogoFacebook /> Facebook
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <LogoTwitter /> Twitter
            </TwitterShareButton>
            <EmailShareButton url={url}>
              <IosMail /> Email
            </EmailShareButton>
            <p
              className="pointer"
              onClick={() => this.changeShareStatus(false)}
            >
              ok
            </p>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default inject("modalStore")(
  withLoadingBackground(observer(withRouter(SeenapseActions)))
)
