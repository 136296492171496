import Storage from 'localStorage'
import axios from 'axios'
import { API_HOST, API } from '../shared/constants/Constants'
/**
 * @class UserStore
 * Permite manejar la session del usuario en el local storage
 */
export default class UserStore {
  /**
   * @function saveSession
   * @argument {string} token token de usuario
   * @argument {int} user_id  id de usuario
   * @description Guarda una session de un usuario
   */
  static saveSession = (token, user_id, lang) => {
    // Storage.clear()
    Storage.setItem('token', token)
    Storage.setItem('uid', user_id)
  }

  /**
   * @function isLoggedIn
   * @description Verifica si éxiste una session en localStorage
   */
  static isLoggedIn = () => {
    let hasNewVersion = Storage.getItem('newVersion')
    if (!hasNewVersion) {
      Storage.clear()
      Storage.setItem('newVersion', 'langversion')
    }

    var langStore = Storage.getItem('lang')
    var translateLang = Storage.getItem('translate_lang')
    if (!langStore || !translateLang) {
      var userLang = navigator.language || navigator.userLanguage
      const lang = userLang.split('-')[0] === 'es' ? 'es' : 'en'
      Storage.setItem('lang', lang)
      Storage.setItem('translate_lang', lang)
    }

    return Storage.getItem('token') !== null ? true : false
  }

  /**
   * @function getUid
   * @description Obtiene el id del usuario
   */
  static getUid = () => {
    return Storage.getItem('uid')
  }

  /**
   * @description Obtiene los catalofgos de countries y professions
   */
  static getCatalogs() {
    const langStore = Storage.getItem('lang')
    return axios.get(`${API_HOST}/api/v1/user/catalogs?lang=${langStore}`)
  }

  /**
   * @function getUserProfile
   * @description Obtiene el perfil de un usuario
   */
  static getUserProfile(userId) {
    var langStore = Storage.getItem('lang')
    var pathname = window.location.pathname;
    var isDetail = pathname.indexOf('account') != -1 ? '&detail=true':'';
    if (!userId) {
      return Promise.resolve();
    }
    return API.get(`${API_HOST}/api/v1/user/${userId}?lang=${langStore}`+ isDetail)
  }

  /**
   * @function getUserProfile
   * @description Obtiene el perfil de un usuario
   */
  static getProfile(userId) {
    var langStore = Storage.getItem('lang')
    if (!userId) {
      return Promise.resolve();
    }
    return API.get(
      `${API_HOST}/api/v1/user/profile/${userId}?lang=${langStore}`
    )
  }

  static getNotifications(section) {
    let userId = { token: Storage.getItem('uid') }
    return API.get(`${API_HOST}/section/${section}`, userId)
  }

  static getTotalNotifications(userId) {
    return API.get(`${API_HOST}/api/v1/notifications?user_id=${userId}`)
  }

  static getAllNotifications(params) {
    return API.get(`${API_HOST}/api/v1/notifications/detail`, { params })
  }

  static followAndUnflollow(params) {
    return API.post(`${API_HOST}/api/v1/action/follower`, { ...params })
  }

  static setNotificationsViewed(params) {
    return API.post(`${API_HOST}/api/v1/notifications/seen`, { ...params })
  }

  static saveFirebaseToken(token) {
    let userId = { token: Storage.getItem('uid') }
    return API.post(`${API_HOST}/api/v1/firebase`, { user_id: userId, token })
  }

  /**
   * @function followStatus
   * @description Follow Unfollow
   * @argument uid Usuario a seguir
   * @argument cf false = seguir, true = dejar de seguir
   */
  static followStatus(uid) {
    let data = { followed_id: uid, user_id: Storage.getItem('uid') }
    return API.post(`${API_HOST}/api/v1/action/follower`, data)
  }

  static getResultsByContext(context, user_id, page, size) {
    var langStore = Storage.getItem('lang')
    let path = `/api/v1/user/${context}?user_id=${user_id}&page=${page}&size=${size}&lang=${langStore}`
    return API.get(`${API_HOST}${path}`)
  }
}
