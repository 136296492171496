import React, { Fragment, Component } from "react"
import { Formik, Form } from "formik"
import { Link } from "react-router-dom"
import { inject, observer } from "mobx-react"
import * as Yup from "yup"

import idea from "../../assests/img/idea.png"
import Translate from "../../utils/Translate"
import { wordFilter } from "../../utils/Utils"
import "./Inspired.css"
import Thumbnail from "./Thumbnail"

import { IMAGES_BUCKET } from "../../config"
import LoaderIA from "../../components/loaderIA"

const validationSchema = Yup.object().shape({
  brand: Yup.string().required("Required"),
  whatisit: Yup.string().required("Required"),
  keyattributes: Yup.string().required("Required"),
})

class Inspired extends Component {
  state = {}

  componentDidMount = () => {
    const { inspiredStore } = this.props
    inspiredStore.getValues()
  }

  handleSubmit = async (values, { setFieldError }) => {
    const { inspiredStore, child } = this.props
    const params = {
      ...values,
      seenapsedescription: child.description,
    }

    const validate = Object.entries(values)
      .map(([key, value]) => {
        const newValue = wordFilter(value)
        const lengthNewValue = newValue.length > 1
        const message =
          newValue.length > 0 &&
          `Sorry, we can't use ${
            lengthNewValue ? "these words:" : "this word:"
          } ${newValue}. ${
            lengthNewValue ? "Please change them." : "Please change it."
          }`

        setFieldError(key, message)
        this.setState((prevState) => ({
          ...prevState,
          [key]: newValue,
        }))
        return newValue.length > 0
      })
      .some((item) => item)
  }

  handleNewIdea = () => {
    const { inspiredStore } = this.props
    inspiredStore.clearIdeaCreate()
  }

  handleChangeValue = (event, setFieldValue) => {
    const { name, value } = event.currentTarget
    const { inspiredStore } = this.props
    setFieldValue(name, value)
    inspiredStore.saveValue(name, value)
  }

  render() {
    const {
      parent,
      child,
      inspiredStore: { ideaCreated, messages, sending, showModal },
    } = this.props

    const image = `url(${IMAGES_BUCKET}${parent.img})`
    const image2 = `url(${IMAGES_BUCKET}${child.img})`
    const isActive = showModal && messages

    return (
      <div className="inspired">
        <div className="inspired-images">
        <Thumbnail
            isGray
            disabledOnclick
            childId="1"
            key="2"
            background={image}
          />
          <Thumbnail
            isGray
            disabledOnclick
            childId="1"
            key="1"
            height="10px"
            background={image2}
          />
        </div>
        <div className="inspired-ctntitle">
          <img src={idea} alt="Idea" className="inspired-image" />
          <h2 className="inspired-subtitle">
            {Translate.get("ideasTitle", "Get ai-created ideas inspired by:")}
          </h2>
        </div>
        <h3
          className="inspired-title"
          dangerouslySetInnerHTML={{
            __html: `${parent && (parent.name || "")}`,
          }}
        />
        <h3
          className="inspired-title"
          dangerouslySetInnerHTML={{
            __html: `${child && (child.name || "")}`,
          }}
        />

        {isActive ? (
          <div className="inspired-ideas">
            <div className="inspired-message">
              <h2 className="inspired-ideasSubtitle">
                {Translate.get("ideasTitle", "here are some ideas")}
              </h2>
              {messages.map((message, index) => (
                <h3 key={index} className="inspired-ideasTitle inspired-bottom">
                  {message}
                </h3>
              ))}
            </div>
            <button
              id="register"
              className="inspired-button btnpadding"
              onClick={this.handleNewIdea}
            >
              {Translate.get("createOther", "Create another!")}
            </button>
          </div>
        ) : (
          <Fragment>
            <Formik
              initialValues={{
                brand: ideaCreated.brand || "",
                whatisit: ideaCreated.whatisit || "",
                keyattributes: ideaCreated.keyattributes || "",
                occasion: ideaCreated.occasion || "",
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <Form>
                    {sending ? (
                      <div className="inspired-loader">
                        <LoaderIA />
                      </div>
                    ) : (
                      <Fragment>
                        <div className="inspired-ctninput">
                          <label htmlFor="brand" className="inspired-label">
                            {Translate.get(
                              "someInfo",
                              "I'll need some info* from you:"
                            )}
                          </label>
                          <input
                            type="text"
                            placeholder={Translate.get(
                              "placeHolderSomeInfo",
                              "Write the brand, product, or bizname"
                            )}
                            className={
                              errors.brand
                                ? "inspired-input inspired-error"
                                : "inspired-input"
                            }
                            onChange={(e) =>
                              this.handleChangeValue(e, setFieldValue)
                            }
                            value={values.brand}
                            name="brand"
                            maxlength="25"
                          />
                          {errors.brand && touched.brand && (
                            <div className="inspired-required">
                              {errors.brand}
                            </div>
                          )}
                        </div>
                        <div className="inspired-ctninput">
                          <label htmlFor="whatisit" className="inspired-label">
                            {Translate.get("whatisit", "What is it?")}
                          </label>
                          <input
                            type="text"
                            placeholder={Translate.get(
                              "placeholderWhatIsIt",
                              "e.g. a bike, a laundry service, food, etc."
                            )}
                            className={
                              errors.whatisit
                                ? "inspired-input inspired-error"
                                : "inspired-input"
                            }
                            onChange={(e) =>
                              this.handleChangeValue(e, setFieldValue)
                            }
                            value={values.whatisit}
                            name="whatisit"
                            maxlength="50"
                          />
                          {errors.whatisit && touched.whatisit && (
                            <div className="inspired-required">
                              {errors.whatisit}
                            </div>
                          )}
                        </div>
                        <div className="inspired-ctninput">
                          <label
                            htmlFor="keyattributes"
                            className="inspired-label"
                          >
                            {Translate.get(
                              "keyattributes",
                              "What are its key attributes?"
                            )}
                          </label>
                          <input
                            placeholder=
                            {Translate.get(
                              "placeholderAttributes",
                              "e.g. design, flavor, ecological, etc."
                            )}
                            type="text"
                            className={
                              errors.keyattributes
                                ? "inspired-input inspired-error"
                                : "inspired-input"
                            }
                            onChange={(e) =>
                              this.handleChangeValue(e, setFieldValue)
                            }
                            value={values.keyattributes}
                            name="keyattributes"
                            maxlength="75"
                          />
                          {errors.keyattributes && touched.keyattributes && (
                            <div className="inspired-required">
                              {errors.keyattributes}
                            </div>
                          )}
                        </div>
                        <div className="inspired-ctninput">
                          <label htmlFor="occasion" className="inspired-label">
                            {Translate.get(
                              "occasion",
                              "What's the occasion? (optional)"
                            )}
                          </label>
                          <input
                            placeholder={Translate.get(
                              "placeholderOcassion",
                              "e.g. launch, seasonality, promotion, etc."
                            )}
                            type="text"
                            className={
                              errors.occasion
                                ? "inspired-input inspired-error"
                                : "inspired-input"
                            }
                            onChange={(e) =>
                              this.handleChangeValue(e, setFieldValue)
                            }
                            value={values.occasion}
                            name="occasion"
                            maxlength="50"
                          />
                          {errors.occasion && touched.occasion && (
                            <div className="inspired-required">
                              {errors.occasion}
                            </div>
                          )}
                        </div>
                        <button
                          id="register"
                          type="submit"
                          disabled={isSubmitting}
                          className="inspired-button"
                        >
                          {Translate.get("go", "Go!")}
                        </button>
                      </Fragment>
                    )}
                  </Form>
                )
              }}
            </Formik>
          </Fragment>
        )}
        <h4 className="inpired-privacytext">
          {Translate.get("privacyIdea", privacy)}
          <Link to="/privacy" className="text-underline">
            <span>{Translate.get("privacyPolicy", "Privacy Policy")}</span>
          </Link>
        </h4>
      </div>
    )
  }
}
export default inject("inspiredStore")(observer(Inspired))

const privacy = `
*Don’t worry, we won’t store this info permanently, only during your active session. It’ll be deleted automatically afterwards. See our
`
