/* eslint-disable no-undef */
import { useState } from 'react';
import { DeleteProject } from '../../services/seenapse';
import useBranchyStore from '../../hooks/store/useBranchyStore';
import { getUid } from '../../utils/Utils';

export default function useDelete() {
  const [loading, setLoading] = useState(false);
  const { onDeletePinnProject } = useBranchyStore();

  const onDeleteProject = ({ uid, callback }) => {
    setLoading(true);
    DeleteProject({ uid, userUid: getUid() })
      .then(() => {
        onDeletePinnProject(uid);
        callback?.()
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  };

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied');
      })
      .catch((err) => {
        console.error('Copy failed!: ', err);
      });
  };

  return { onDeleteProject, copy, loading };
}
