import React, { useEffect } from 'react';
import ChatComponent from './components/ChatComponent';
import useBranchyStore from '../../hooks/store/useBranchyStore';
import ShareModal from '../components/modal/share';
import useShare from '../components/modal/share/useShare';
import useUser from '../../hooks/useUser';
import { validateTrialFinish } from '../../utils/Utils';
import Subscription from '../../app/subscription';

// Main App component
export default function Branchy() {
  const { user } = useUser()
  const { projectName, getListProjects } = useBranchyStore();
  const { isOpenModal, onCloseModal } = useShare()
  const showTrialFinish = validateTrialFinish(user)
  const showRenew = user.days >= 7 && user.has_subscription === 2;

  useEffect(() => {
    getListProjects()
  },[])

  return (
    <div className="App bg-body-bg min-h-screen flex flex-col">
      <div
        className="flex items-center bg-black rounded-lg p-2 cursor-pointer"
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      >
        <h1 className="text-white font-thin text-3xl">{showTrialFinish || showRenew ? '' : projectName}</h1>
      </div>
      <div className="flex-grow flex items-top justify-left">
        {showTrialFinish || showRenew ? <Subscription
          user={user}
          country={'us'}
          showRenew={showRenew}
          showTrialFinish={showTrialFinish}
        /> : <ChatComponent />}
      </div>
      <ShareModal
        projectName={projectName}
        isOpenDialog={isOpenModal}
        onCloseModal={onCloseModal}
      />
    </div>
  );
}
