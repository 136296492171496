import React from 'react';
import Slider from 'react-slick';
import ImgComment1 from '../../../assests/img/image1.png';
import ImgComment2 from '../../../assests/img/image2.png';
import ImgComment3 from '../../../assests/img/image3.png';
import ImgComment4 from '../../../assests/img/image4.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

const dataComment = [
  {
    id: 1,
    title: "“I use Seenapse daily to boost my creativity, and the best part is that I don't have to explain what a creative rationale, a concept, or a post is because it already understands everything. It's almost like chatting and creating together with another high-level Creative.”",
    img: ImgComment1,
    name: 'Cassandre Aouragh',
    position: 'Creative Director, MONTALVO.',
  },
  {
    id: 2,
    title: '“I love your tool! Robust, reliable, creative! Tempted to keep it a secret —it’s that good— but everyone deserves to know.”',
    img: ImgComment2,
    name: 'Jamie King',
    position: 'Cofounder, Rockstar Games.',
  },
  {
    id: 3,
    title: '“It’s an incredible asset for fueling brainstorming sessions and actively involving ‘unconventional creatives’ in the ideation process, empowering them to play a pivotal role.”',
    img: ImgComment3,
    name: 'Gilad Kat',
    position: 'Founder, Blue Oyster by The Network.',
  },
  {
    id: 4,
    title: '“It’s awesome! Besides you guys have a look and feel that is playful and really have worked around to go beyond the “expected” ideas on a normal brainstorming.”',
    img: ImgComment4,
    name: 'Catalina Triana',
    position: 'Founder, EngageBox.',
  },
];

const settings = {
  className: 'slider variable-width comments-slide',
  dots: false,
  autoplay: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  adaptiveHeight: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 0,
        slidesToScroll: 1,
      },
    },
  ],
};

function CommentsSlider(props) {
  const { index, comment, ...otherProps } = props;
  return (
    <div {...otherProps}>
      <div className="comments">
        <p className="comments-title">{comment.title}</p>
        <div className="comments-data">
          <figure className="comments-data-image">
            <img src={comment.img} alt="" />
          </figure>
          <div className="comments-data-info">
            <p className="comments-name">{comment.name}</p>
            <p className="comments-position">{comment.position}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Comments() {
  return (
    <div className="container-comments">
      <Slider {...settings}>
        {dataComment.map(comment => (
          <CommentsSlider index={comment.id} comment={comment} />
        ))}
      </Slider>
    </div>
  );
}
