import { hookstate, useHookstate } from '@hookstate/core';
import { useHistory } from 'react-router-dom';
import { AddProject, ChangeProjectStatus } from '../../services/seenapse';

const initialState = hookstate({
  creating: false,
  isUpdating: false,
  isOpenDialog: false,
});

export default function useAddProjectstore() {
  const history = useHistory();
  const state = useHookstate(initialState);

  const onOpenDialog = () => state.isOpenDialog.set(true);
  const onCloseDialog = () => state.isOpenDialog.set(false);

  const onRedirect = ({ name, uid }) => {
    history.push({
      pathname: `/ai/project/chat/${uid}`,
      search: `?project=${name}`,
    });
  };

  const onAddProject = ({
    userId, projectId, lang, callback,
  }) => {
    state.creating.set(true);
    AddProject({
      project: {
        user_id: userId,
        description: projectId,
        lang,
      },
    })
      .then((response) => {
        if (callback) {
          callback();
        }

        onCloseDialog();

        onRedirect({ uid: response.data.data.uid, name: projectId });
      })
      .catch(error => console.error(error))
      .finally(() => state.creating.set(false));
  };

  const onChangeStatus = ({ uid, callback }) => {
    state.isUpdating.set(true);
    ChangeProjectStatus({ params: { project: { status: 1 } }, uid })
      .then(() => callback())
      .catch(error => console.error(error))
      .finally(() => state.isUpdating.set(false));
  };

  return {
    ...state.get(),
    onOpenDialog,
    onCloseDialog,
    onAddProject,
    onChangeStatus,
  };
}
