const firebase = {
  apiKey: 'AIzaSyClQnAgfzj5kP2OyBAlPXD6_X-MO4T9txo',
  authDomain: 'seenapse-48b52.firebaseapp.com',
  databaseURL: 'https://seenapse-48b52.firebaseio.com',
  projectId: 'seenapse-48b52',
  storageBucket: 'seenapse-48b52.appspot.com',
  messagingSenderId: '928675928201',
  appId: '1:928675928201:web:6e1c2692e9b85615a54e58',
  measurementId: 'G-16S03F7GDH'
}
export default firebase
