import React from 'react'
import SeenapseActions from './SeenapseActions'

export default function SeenapseContent(props) {
  const { parent, child, height } = props
  if (!parent && !child) {
    return <div />
  }
  return (
    <div className="content">
      <h2
        style={{ fontSize: Math.max((height * 18) / 512, 10) }}
        dangerouslySetInnerHTML={{
          __html: `${parent && (parent.name || "")} & ${
            child && (child.name || "")
          }`,
        }}
      />
      {child && (
        <p
          style={{ fontSize: Math.max((height * 14) / 512, 8) }}
          dangerouslySetInnerHTML={{ __html: child.description }}
        />
      )}
      <SeenapseActions index={3} {...child} {...props}/>
    </div>
  )
}
