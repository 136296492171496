import React from 'react';
import { Link } from 'react-router-dom';

import { buildImageUrl } from '../account/AccountResults';
import DefaultAvatar from '../../assests/img/avatar-default.png';
import Translate from '../../utils/Translate';

const optionsOfNotifications = [
  {
    text: 'Seenapsed',
    type: 'children',
  },
  {
    text: Translate.get('favorited', 'Favorited'),
    type: 'fav',
  },
  {
    text: Translate.get('followed', 'Followed'),
    type: 'follower',
  },
];

const BuildPeopleResult = ({ element, index }) => {
  if (!element) {
    return null;
  }
  const { id } = element;

  const optionFiltered = optionsOfNotifications.filter(
    item => item.type === element.type,
  );

  const image = element.type === 'follower' ? element.avatar : element.by.avatar;
  const name = element.type === 'follower' ? element.name : element.by.name;

  return (
    <Link
      key={index}
      to={`/user/${id}/seenapses`}
      className="link-seenapse underline mt-5 mb-0"
    >
      <div>
        {optionFiltered.map(({ text }, index) => (
          <p className="notify-seenapse" key={index}>
            {text}
          </p>
        ))}
        <div className="notify-people mr-1 mr-md-4" key={index}>
          <div
            className="picUs notify-image"
            style={{
              backgroundImage: `url(${buildImageUrl({ img: image })}),
            url(${DefaultAvatar})`,
            }}
          />
          <div className="names-images notify-left">{name}</div>
        </div>
      </div>
    </Link>
  );
};
export default BuildPeopleResult;
