import React from 'react';
import ImgInstagram from '../../assests/img/seenapse/others/i1.png';
import ImgTwitter from '../../assests/img/seenapse/others/i2.png';
import Translate from '../../utils/Translate';

export default function Footer() {
  return (
    <footer id="footer" className="footer-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 p-lg-0">
            <div className="footer-widget">
              <div className="footer-menu">
                <ul className="global-list footer-list">
                  <li>
                    <a href="/privacy">
                      {Translate.get('footerPrivacy', 'Privacy Policy')}
                    </a>
                  </li>
                  <li>
                    <a href="/terms">
                      {Translate.get('footerTerms', 'Terms & Conditions')}
                    </a>
                  </li>
                  <li>
                    <a href="mailto:contact@seenapse.ai">
                      {Translate.get('footerContact', 'Contact')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-lg-0">
            <div className="row align-items-center">
              <div className="col-lg-3 p-lg-0">
                <div className="footer-widget">
                  <div className="footer-social">
                    <ul className="global-list">
                      <li>
                        <a href="https://instagram.com/seenapse">
                          <img
                            alt="Img"
                            src={ImgInstagram}
                            className="price-img_fluid"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 p-lg-0">
                <div className="footer-widget">
                  <p>
                    <a href="/"> © Seenapse 2023</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
