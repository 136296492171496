import React, { Component, Fragment } from 'react'
import ReactCrop from 'react-image-crop'
import { IMAGES_BUCKET } from '../../config'
import { getThumbnails } from '../../services/seenapse'
import Preview from './Preview'
import Translate from '../../utils/Translate'
import { isImageUrl } from '../../utils/Utils'
import 'react-image-crop/dist/ReactCrop.css'
import './SeenapseURLLoader.css'

export default class SeenapseURLLoader extends Component {
  SERVERLESS_API = 'https://seenapseimg.vampaynani.now.sh/api'
  urlLoaderContainer = null
  fileUrl = null
  imageRef = null
  state = {
    url: null,
    images: [],
    selected: null,
    cropping: false,
    imageToCrop: null,
    crop: { unit: '%', width: 30, aspect: 1 / 1 },
    croppedImageUrl: null,
    placeholder: false,
    loading: false
  }
  componentDidUpdate() {
    const { child } = this.props
    if (child && !(this.state.url || this.state.loading)) {
      this.setState(
        {
          placeholder: true
        },
        () => this.submitUrl(child.url)
      )
    }
  }
  onFormSubmit = e => {
    e.preventDefault()
    const { value } = e.target.url
    if (value) this.submitUrl(value)
  }
  onInputBlur = e => {
    e.preventDefault()
    const { value } = e.target
    if (value) this.submitUrl(value)
  }
  submitUrl = url => {
    if (this.state.loading) return
    
    if (isImageUrl(url)) {
      this.setState({ images: [url], selected: 0, url: url })
      setTimeout(
        () =>
          this.props.updateForm({
            url: url,
            img: url,
            page_title: 'image'
          }),
        100
      )
      return
    }

    this.setState({
      loading: true,
      url: url
    })

    getThumbnails(url)
    .then(res => {
      let images = res.data.images
        .filter(img => {
          return img.indexOf('.gif') === -1
        })
        .slice(0, 8)

      if (images.length === 0) {
        images = [res.data.image]
        this.setState({ selected: 0 })
      }

      this.setState({ loading: false, images })
            
      this.props.updateForm({
        url: url,
        page_title: res.data.title
      })

    }).catch(e => {
      this.props.updateForm({
        url: url,
        page_title: "N/A"
      })
      this.setState({ loading: false, images: [], imgError: true })
    })
  }
  selectImage = index => {
    this.setState({ selected: index })
    this.props.updateField('img', this.state.images[index])
  }
  createImage = (image, index) => {
    const urlLoaderContainerRect = this.urlLoaderContainer.getBoundingClientRect()
    const angle = (Math.PI / 8) * 2
    const radius = urlLoaderContainerRect.width / 2
    const origin = {
      x: urlLoaderContainerRect.width / 2,
      y: urlLoaderContainerRect.height / 2
    }
    return (
      <Preview
        onClick={() => this.selectImage(index)}
        src={image}
        key={index}
        index={index}
        angle={angle}
        radius={radius}
        origin={origin}
      />
    )
  }
  changePicture = e => {
    e.preventDefault()
    this.setState({
      selected: null,
      cropping: false,
      imageToCrop: null,
      croppedImageUrl: null,
      placeholder: false
    })
    this.props.updateField('img', '')
  }
  cropPicture = e => {
    e.preventDefault()
    const { images, selected, placeholder } = this.state
    const imgUrl = placeholder
      ? `${IMAGES_BUCKET}${this.props.child.img}`
      : images[selected]
    fetch(`${this.SERVERLESS_API}/imageProxy?url=${imgUrl}`)
      .then(res => res.blob())
      .then(blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          this.setState({ imageToCrop: reader.result, loading: false })
        }
      })
    this.setState({ cropping: true, loading: true })
  }
  onImageLoaded = image => {
    this.imageRef = image
    this.imageRef.setAttribute('crossorigin', 'anonymous')
  }
  onCropComplete = crop => {
    this.makeClientCrop(crop)
  }
  onCropChange = crop => {
    this.setState({ crop })
  }
  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    canvas.width = crop.width
    canvas.height = crop.height

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return canvas.toDataURL()
  }
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop)
      this.setState({ croppedImageUrl })
    }
  }
  setCroppedImage = e => {
    e.preventDefault()
    const data = this.state.croppedImageUrl
    fetch(`${this.SERVERLESS_API}/dataToFile`, {
      method: 'POST',
      body: JSON.stringify({ data }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(({ file }) => {
        this.props.updateField('img', file.location)
      })
    this.setState({ cropping: false })
  }
  cancelCroppedImage = e => {
    e.preventDefault()
    this.setState({ cropping: false, croppedImageUrl: null })
  }
  changeLink = e => {
    e.preventDefault()
    this.setState({
      images: [],
      selected: null,
      cropping: false,
      croppedImageUrl: null,
      placeholder: false
    })
    this.props.updateForm({
      img: '',
      page_title: '',
      url: ''
    })
  }
  setUrl = e => {
    this.setState({url: e.currentTarget.value});
  }
  render() {
    const { height } = this.props
    const {
      images,
      selected,
      placeholder,
      cropping,
      imageToCrop,
      crop,
      croppedImageUrl
    } = this.state
    return (
      <div
        className='seenapse-url-loader'
        ref={div => (this.urlLoaderContainer = div)}
      >
        {(selected !== null || placeholder || croppedImageUrl) && (
          <Fragment>
            {!cropping && (
              <div
                className='seenapseImg bottom'
                style={{
                  backgroundColor: this.props.backgroundColor,
                  backgroundImage: `url(${
                    croppedImageUrl
                      ? croppedImageUrl
                      : placeholder
                        ? `${IMAGES_BUCKET}${this.props.child.img}`
                        : images[selected]
                    })`
                }}
              />
            )}
            {cropping && this.state.loading && (
              <p
                style={{
                  fontSize: Math.max((height * 10) / 512, 5)
                }}
              >
                Loading...
              </p>
            )}
            {cropping && imageToCrop && (
              <div className='crop-container'>
                <ReactCrop
                  src={imageToCrop}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
                <div className='crop-actions-container'>
                  <a
                    href='#cancel'
                    className='action yellow cancel'
                    onClick={this.cancelCroppedImage}
                    style={{
                      fontSize: Math.max((height * 13) / 512, 10)
                    }}
                  >
                    Cancel
                  </a>
                  <a
                    href='#crop'
                    className='action yellow change-link'
                    onClick={this.setCroppedImage}
                    style={{
                      fontSize: Math.max((height * 13) / 512, 10)
                    }}
                  >
                    Crop
                  </a>
                </div>
              </div>
            )}
            <div className='actions-container'>
              <a
                href='#change-link'
                className='action yellow change-link'
                onClick={this.changeLink}
                style={{
                  fontSize: Math.max((height * 13) / 512, 10)
                }}
              >
                Change link
              </a>
              <a
                href='#change-picture'
                className='action purple change-picture'
                onClick={this.changePicture}
                style={{
                  fontSize: Math.max((height * 13) / 512, 10)
                }}
              >
                Change picture
              </a>
              <a
                href='#crop-picture'
                className='action purple crop-picture'
                onClick={this.cropPicture}
                style={{
                  fontSize: Math.max((height * 13) / 512, 10)
                }}
              >
                Crop picture
              </a>
            </div>
          </Fragment>
        )}
        {images.length === 0 && !placeholder && (
          <Fragment>
            <form onSubmit={this.onFormSubmit}>
              <input
                onChange={this.setUrl}
                onBlur={this.onInputBlur}
                name='url'
                value={this.state.url}
                style={{
                  fontSize: Math.max((height * 18) / 512, 10)
                }}
                type='text'
                placeholder='URL'
              />
            </form>
            
            {!this.state.loading && !this.state.imgError ? (
              <p
                style={{
                  fontSize: Math.max((height * 10) / 512, 5)
                }}
              >
                {Translate.get(
                  'urlReference',
                  'Type or paste here the URL that you want your seenapse to link to as a reference'
                )}
              </p>
            ) : !this.state.imgError && (
                <p
                  style={{
                    fontSize: Math.max((height * 10) / 512, 5)
                  }}
                >
                  Loading...
                </p>
              )}

              {this.state.imgError && <p style={{
                    fontSize: Math.max((height * 14) / 512, 5),
                  }}>
                    {Translate.get(
                    'urlBad',
                    'We cannot obtain images from this URL'
                  )}</p>}
          </Fragment>
        )}
        {images.length > 0 &&
          selected === null &&
          !placeholder &&
          images.map(this.createImage)}
      </div>
    )
  }
}
