import React from 'react';
import Card from './Card';
import { findNewestCard, parseResponseContent } from '../utils/cardUtils';

const Conversation = ({
  conversation,
  onSendMessage,
  currentId,
}) => {
  // Find the newest card in the conversation
  const { conv: newestConv } = findNewestCard([conversation]);

  // Function to check if a card is the newest one
  const isNewestCard = newestConv ? conversation.id === newestConv[0].id : false;
  const buttonOptions = parseResponseContent(conversation.content)
  const options = buttonOptions.length > 0 ? buttonOptions : conversation.options
  return (
    <div className="conversation" id={currentId}>
      <div className="cards-container space-y-4">
        {/* Render all cards in the conversation */}
        <Card
          key={conversation.id}
          card={conversation}
          isUser={conversation.role === 'user'}
          options={options}
          isNewest={conversation.id === conversation.length - 1}
          shouldFocus={isNewestCard && conversation.role !== 'user'}
          onSendMessage={message => onSendMessage(message, conversation)}
        />
      </div>
      {/* Render branches if they exist */}
      {conversation.branches && (
        <div className="branches mt-4 flex space-x-4">
          {conversation.branches.map((branch, branchIndex) => {
            return (
              <Conversation
                key={branchIndex}
                currentId={branch.id}
                conversation={branch}
                onSendMessage={onSendMessage}
                baseIndex={branchIndex}
              />
            )
          })}
        </div>
      )}
    </div>
  );
};

export default Conversation;
