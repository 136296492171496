import React, { Component } from 'react'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import User from '../../models/UserStore'
import Translate from './../../utils/Translate'

import './SeenapseForm.css'

class SeenapseForm extends Component {
  state = {
    userId: User.getUid(),
    user: null,
    name: '',
    description: ''
  }
  componentDidMount() {
    User.getUserProfile(this.state.userId).then(res => {
      this.setState({
        user: res.data
      })
    })
  }
  componentDidUpdate() {
    const { child } = this.props
    if (child && !this.state.name) {
      const { name, description } = child
      this.setState({
        name,
        description
      })
      this.props.updateForm({ name, description })
    }
  }
  updateField = (field, value) => {
    this.setState({
      [field]: value
    })
    this.props.updateField(field, value)
  }
  render() {
    const {
      error,
      loading,
      parent,
      height,
      history,
      publishSeenapse
    } = this.props
    const { user } = this.state
    if (!this.state.userId) return <Redirect to='/' />
    return (
      <form className='content content-form' autoComplete='off'>
        <p className='error'>{error}</p>
        <h2
          style={{ fontSize: Math.max((height * 18) / 512, 10) }}
          dangerouslySetInnerHTML={{
            __html: `${(parent && parent.name) || ''} & `
          }}
        />
        <input
          type='text'
          placeholder={Translate.get('writeYourTitle', 'Write your title here')}
          name='title'
          style={{ fontSize: Math.max((height * 15) / 512, 10) }}
          value={this.state.name}
          onChange={e => this.updateField('name', e.target.value)}
        />
        <textarea
          style={{ fontSize: Math.max((height * 14) / 512, 8) }}
          name='description'
          placeholder={Translate.get(
            'describeYourSeenapse',
            'Describe your seenapse in 350 characters or less'
          )}
          value={this.state.description}
          onChange={e => this.updateField('description', e.target.value)}
        />
        <ul className='menu'>
          <li
            style={{ fontSize: Math.max((height * 13) / 512, 10) }}
            className='cancel'
            onClick={() => {
              history.goBack()
            }}
          >
            {Translate.get('cancel', 'Cancel')}
          </li>
          {!loading && (
            <li
              style={{ fontSize: Math.max((height * 13) / 512, 10) }}
              className='publish'
              onClick={publishSeenapse}
            >
              {Translate.get('publish', 'Publish')}
            </li>
          )}
          {loading && (
            <li
              style={{ fontSize: Math.max((height * 13) / 512, 10) }}
              className='publish'
            >
              Loading...
            </li>
          )}
        </ul>
        {user && (
          <div className='avatar-user-container'>
            <Link
              className='avatar-user'
              to={`/user/${this.state.userId}/seenapses`}
              style={{ backgroundImage: `url(${user.img})` }}
            />
          </div>
        )}
      </form>
    )
  }
}

export default inject('modalStore')(observer(withRouter(SeenapseForm)))
