import React from 'react';

import SeenapseResults from '../SeenapseResults';
import Translate from '../../utils/Translate';
import { PATH_IMAGE } from '../account/AccountResults';
import loader from '../../assests/img/Loader.gif';

const BuildSeenapseResult = ({
  element, index, match, onClick, loading,
}) => {
  const { url } = match;

  if (element.type === 'follower') {
    return (
      <div className="notify-btn-content">
        <button
          type="submit"
          className="button-unfollow"
          onClick={() => onClick(element.id)}
          disabled={loading}
        >
          {loading && (
            <img src={loader} alt="loader" className="notify-loader" />
          )}
          {element.i_follow_it
            ? Translate.get('follow', 'Follow back')
            : Translate.get('unfollow', 'Unfollow')}
        </button>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundSize: 'contain',
        backgroundPosition: 'top',
        paddingTop: 0,
        width: 240,
      }}
      className="search-results mx-2 mx-md-4"
      key={index}
    >
      <SeenapseResults
        currentPath={url}
        seenapse={element}
        index={index}
        pathImage={PATH_IMAGE}
        style={{ width: '140px', height: '140px' }}
        childStyle={{ width: '140px', height: '140px', left: '100px' }}
      />
    </div>
  );
};
export default BuildSeenapseResult;
