import React from 'react'
import './Alert.css'

export default function AlertModal(props) {
  if (props.open) {
    const showMessage = props.message
    return (
      <div className='lb on' onClick={props.onOk}>
        <div className='message almost'>
          <div className='content'>
            {showMessage}
          </div>
        </div>
      </div>
    )
  }
  return <div />
}
