import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import Translate from '../../utils/Translate';
import PlanetImage from '../../assests/img/planet.png';
import Spinner from '../../components/spinner';
import useClimates, { initialValues, validationSchema } from './useClimates';
import Footer from './footer';
import mixpanel from 'mixpanel-browser';

export default function ClimateSolutions() {
  const { onSubmit, loading } = useClimates();
  useEffect(() => {
    mixpanel.track('Visit', {'Behavior': 'Climate'})
  }, []);

  return (
    <div className="climate-ctn">
      <h2 className="climateTitle">
        {Translate.get('climateTitle', 'Brainstorming for climate solutions')}
      </h2>
      <figure className="climate-image">
        <img src={PlanetImage} alt="planet" />
      </figure>
      <p className="climate-information-ideas">
        {Translate.get(
          'climateInformationIdeas',
          'The climate crisis demands ideas as brave and diverse as the world we share. At Seenapse, we want to contribute by doing what we do best — helping people to come up with innovative ideas.',
        )}
      </p>
      <p className="climate-information-innovation">
        {Translate.get(
          'climateInformationInnovation',
          "We're giving climate-focused NGOs and their agency partners free access to Seenapse, to help them rethink products, reengineer processes, create compelling awareness campaigns, find new ways to hold governments and corporations accountable, or push boundaries in sustainable innovation.",
        )}
      </p>
      <p className="climate-information-apply">
        {Translate.get(
          'climateInformationApply',
          'Apply for our Brainstorming for Climate Solutions program here:',
        )}
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange, values, errors, touched,
        }) => (
          <Form className="climate-form">
            <div className="climate-dialog-input">
              <label
                htmlFor="projectTitle"
                className="climate-dialog-label"
                id="projectTitle"
              >
                {Translate.get('climateName', 'YOUR NAME')}
              </label>
              <input
                width="190px"
                type="text"
                value={values.name}
                maxLength="50"
                id="projectTitle"
                placeholder=""
                name="name"
                className="climate-ideas-input"
                onChange={handleChange('name')}
              />
              {touched.name && errors.name && (
              <span className="climate-required">{errors.name}</span>
              )}
            </div>
            <div className="climate-dialog-input">
              <label
                htmlFor="projectTitle"
                className="climate-dialog-label"
                id="projectTitle"
              >
                {Translate.get('climateWorkEmail', 'YOUR WORK E-MAIL')}
              </label>
              <input
                width="190px"
                type="text"
                value={values.email}
                maxLength="50"
                id="projectTitle"
                name="email"
                className="climate-ideas-input"
                onChange={handleChange('email')}
              />
              {touched.email && errors.email && (
                <span className="climate-required">{errors.email}</span>
              )}
            </div>
            <div className="climate-dialog-input">
              <label
                htmlFor="projectTitle"
                className="climate-dialog-label"
                id="projectTitle"
              >
                {Translate.get('climateWebsite', "ONG'S WEBSITE")}
              </label>
              <input
                width="190px"
                type="text"
                value={values.website}
                maxLength="50"
                id="projectTitle"
                name="website"
                className="climate-ideas-input"
                onChange={handleChange('website')}
              />
              {touched.website && errors.website && (
              <span className="climate-required">{errors.website}</span>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                type="submit"
                className="climate-ideas-button"
                style={{ textAlign: 'center' }}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    style={{ margin: '0 auto', borderLeftColor: '#000' }}
                  />
                ) : (
                  Translate.get('climateButtonApply', 'Apply')
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Footer />
    </div>
  );
}
