import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '../app/signin/Avatar';
import HamburgerMenu from '../assests/img/hamburger.png';
import UserMenu from '../app/signin/UserMenu';
import UserMenuExtend from '../app/signin/UserMenuExtend';
import SignInActions from '../app/signin/SignInActions';
import Context from '../stores/Notificationstore';
// import firebase from 'firebase/app'

const ShowMenu = ({
  isLogged,
  user,
  onOpenModal,
  isOpenAvatarMenu,
  expand,
  expandedLogIn,
  expandedSignUp,
  isSearch,
  onCloseModal,
  altAttribute,
  isOpenMenuExtend,
  signInAction,
  switchSignInAction,
  signOut,
  lang,
  handleChangeValueLang,
}) => {
  const history = useHistory();
  const { totalNotifications } = useContext(Context);
  const [modeValue, setModeValue] = useState('chat');

  // if (firebase.messaging.isSupported()) {
  //   const messaging = firebase.messaging()
  //   messaging.onMessage(payload => {
  //     setNotification({ total: payload.data.total })
  //   })
  // }

  const handleChangeModeValue = (event) => {
    const { value } = event.currentTarget;
    setModeValue(value);
    if (value === 'form') {
      history.push('/');
      onCloseModal();
    }
  };


  return (
    <Fragment>
      <div className="text-right d-none d-sm-none d-md-none d-lg-block d-xl-block">
        {isLogged ? (
          <Avatar
            user={user}
            onOpenModal={onOpenModal}
            isOpenAvatarMenu={isOpenAvatarMenu}
            notification={totalNotifications}
          />
        ) : (
          <SignInActions
            expand={expand}
            expandedLogIn={expandedLogIn}
            expandedSignUp={expandedSignUp}
            isSearch={isSearch}
            onCloseModal={onCloseModal}
          />
        )}
      </div>
      <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
        <figure onClick={() => onOpenModal('openMenuExtend')}>
          <img src={HamburgerMenu} alt={altAttribute} />
        </figure>
        {isOpenMenuExtend && (
          <UserMenuExtend
            lang={lang}
            handleChangeValueLang={handleChangeValueLang}
            isOpenMenuExtend={isOpenMenuExtend}
            onCloseModal={onCloseModal}
            isLogged={isLogged}
            signInAction={signInAction}
            switchSignInAction={switchSignInAction}
            signOut={signOut}
            user={user}
            notification={totalNotifications}
            modeValue={modeValue}
            handleChangeModeValue={handleChangeModeValue}
          />
        )}
      </div>
      {isOpenAvatarMenu && (
        <UserMenu
          isOpenAvatarMenu={isOpenAvatarMenu}
          onCloseModal={onCloseModal}
          user={user}
          signOut={signOut}
          notification={totalNotifications}
        />
      )}
    </Fragment>
  );
};
export default ShowMenu;
