import { hookstate, useHookstate } from "@hookstate/core";
import Axios from "axios";
import { useEffect } from "react";
import { parseProxyData } from "../../utils/Utils";

const initialState = hookstate({
  country: '',
  loadingCountries: false,
});

export default function useCountrystore() {
  const { country, loadingCountries } = useHookstate(initialState)

  useEffect(() => {
    fetchCountry()
  }, [])

  const fetchCountry = async () => {
    loadingCountries.set(true)
    Axios.get('https://api.ipregistry.co/', {
      params: {
        hostname: true,
        key: '42hkqliczzu2r0bf'
      }
    })
      .then((response) => {
        country.set(response.data.location.country.code);
      })
      .catch((error) => console.log('Error fetching country data', error))
      .finally(() => loadingCountries.set(false))
  };

  return {
    country: parseProxyData(country.get()),
    loadingCountries: parseProxyData(loadingCountries.get())
  }
}
