import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
	margin-top: 5%;
	padding: 0 12%;
	& span {
		padding: 20px 0;
		margin-bottom: 40px;
		color: white;
		font-size: 14px;
		font-style: italic;
	}
`;

const H2 = styled.p`
  color: white;
  font-family: HelveticaNeue;
  line-height: 1.5;
  font-weight: 200;
  text-align: center;
  font-size: calc(20px + 4 * ((100vw - 320px) / (1600 - 320)));
`;

const Ol = styled.ol`
	color: white;
	font-weight: 200;
`;

const Links = styled(Link)`
  color: rgb(202, 233, 60);

&:hover{
	color: rgb(202, 233, 60);
	text-decoration: underline;
	-webkit-text-decoration-color: #d4e245;
	text-decoration-color: #d4e245;
}
`;
const Anclas = styled.a`
	color: rgb(202, 233, 60);

&:hover{
	color: rgb(202, 233, 60);
	text-decoration: underline;
	-webkit-text-decoration-color: #d4e245;
	text-decoration-color: #d4e245;
}
`;

const Terms = () => (
  <Container className="container">
    <p>
We will be delighted to have you as our user and we will make our best effort to have our platform
available for you at all times for as long as it exists.
In return, we ask you to use Seenapse responsibly. This is all detailed in the text below.
We also take our users' privacy very seriously— you can read about it
      <Links to="/privacy" style={{ marginLeft: 6 }}>here</Links>
    </p>
    <H2>Terms of Service:</H2>
    <p>
The following terms and conditions govern all use of the Seenapse website and all content,
services and products available at or
through the website. The Website is owned and operated by Seenapse,
SAPI de CV ("The Company"). The Website is offered subject to
your acceptance without modification of all of the terms and conditions contained herein and all other operating rules,
policies (including, without limitation,
      {' '}
      <Anclas href="https://automattic.com/privacy/" target="_blank">The Company's Privacy Policy</Anclas>
) and procedures that may be
published from time to time on this Site by The Company (collectively, the "Agreement").

    </p>
    <p>
Please read this Agreement carefully before accessing or using the Website.
By accessing or using any part of the web site, you
agree to become bound by the terms and conditions of this agreement.
If you do not agree to all the terms and conditions of this
agreement, then you may not access the Website or use any services.
If these terms and conditions are considered an offer by The
Company, acceptance is expressly limited to these terms.
The Website is available only to individuals who are at least 13 years old.

    </p>
    <Ol>
      <li>
Your Seenapse Account and content.
If you create content on the Website,
you are responsible for maintaining the security of your account and content,
and you are fully responsible for all activities that occur under the account
and any other actions taken in connection with the content. You must immediately notify The Company
of any unauthorized uses of your account or any other breaches of security. The Company will not be
liable for any acts or omissions by You, including any damages of any kind incurred as a result of
such acts or omissions.

      </li>
      <li>
Responsibility of Contributors.
If You contribute material to the Website (a seenapse Title, and Description, collectively "Content"),
You are entirely responsible for the content of, and any harm resulting from, that Content.
That is the case regardless of whether the Content in question constitutes text,
graphics, an audio file, or computer software. By making Content available, you represent and warrant that:

        <p style={{ marginTop: 16 }}>
a. the downloading, copying and use of the Content will not infringe the proprietary rights,
including but not limited to the copyright,
patent, trademark or trade secret rights, of any third party;
        </p>

        <p>
b. if your employer has rights to intellectual property you create,
you have either (i) received permission from your employer to post or make available the Content,
including but not limited to any software,
or (ii) secured from your employer a waiver as to all rights in or to the Content;

        </p>
        <p>
				c. you have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;
        </p>
        <p>
				d. the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive content, or links to a site that does;
        </p>

        <p>
				e. the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);
        </p>

        <p>
				f. the Content is not pornographic, does not contain threats or incite violence, and does not violate the privacy or publicity rights of any third party; and
        </p>

        <p>
				g.your content is not getting advertised via unwanted electronic messages such as spam links on newsgroups, email lists, other contents and web sites, and similar unsolicited promotional methods.

        </p>
      </li>
      <li>
				Responsibility of Website Visitors. The Company has not reviewed, and cannot review, all of the material posted to the Website, nor that which The Company generated by machine learning or artificial intelligence algorithms, and cannot therefore be responsible for that material's content, use or effects. By operating the Website, The Company does not represent or imply that it endorses the material there posted, or that it believes such material to be accurate, useful or non-harmful. The Website may contain/generate content that is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, and other errors. The Website may also contain material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights, of third parties, or the downloading, copying or use of which is subject to additional terms and conditions, stated or unstated. The Company disclaims any responsibility for any harm resulting from the use by visitors of the Website, or from any downloading or copying by those visitors of content there posted.
      </li>
      <li>
				Copyright Infringement and DMCA Policy. As The Company asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to by Seenapse violates your copyright, you are encouraged to notify The Company in accordance with The Company's Digital Millennium Copyright Act (â€œDMCAâ€) Policy. The Company will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. The Company will terminate a visitor's access to and use of the Website if, under appropriate circumstances, the visitor is determined to be a repeat infringer of the copyrights or other intellectual property rights of The Company or others. In the case of such termination, The Company will have no obligation to provide a refund of any amounts previously paid to The Company.
      </li>
      <li>
				Intellectual Property. This Agreement does not transfer from The Company to you any of The Company's or third party intellectual property, and all right, title and interest in and to such property will remain (as between the parties) solely with The Company. The Company, Seenapse, the Seenapse logo, and all other trademarks, service marks, graphics and logos used in connection with Seenapse, or the Website are trademarks or registered trademarks of The Company or The Company's licensors. Other trademarks, service marks, graphics and logos used in connection with the Website may be the trademarks of other third parties. Your use of the Website grants you no right or license to reproduce or otherwise use any The Company or third-party trademarks. However, you are free to use any content generated by the machine learning/artificial intelligence algorithms that you requested, with the warnings and limitations set forth in point 3 above.

      </li>
      <li>
				Feedback. If you choose to submit comments, ideas or feedback, you agree that we are free to use them without any restriction or compensation to you. By accepting your submission, Seenapse does not waive any rights to use similar or related Feedback previously known to Seenapse, or developed by its employees, or obtained from sources other than you.

      </li>
      <li>
				Changes. The Company reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Website following the posting of any changes to this Agreement constitutes acceptance of those changes. The Company may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.

      </li>
      <li>
				Termination. The Company may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement or your Seenapse account, you may simply discontinue using the Website. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.

      </li>
      <li>
				Disclaimer of Warranties. The Website is provided “as is”. The Company and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither The Company nor its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted. You understand that you obtain content or services through the Website at your own discretion and risk.

      </li>
      <li>
				Limitation of Liability. In no event will The Company, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to The Company under this agreement during the twelve (12) month period prior to the cause of action. The Company shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.

      </li>
      <li>
				General Representation and Warranty. You represent and warrant that (i) your use of the Website will be in strict accordance with the The Company Privacy Policy, with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the country in which you reside) and (ii) your use of the Website will not infringe or misappropriate the intellectual property rights of any third party.

      </li>
      <li>
				Indemnification. You agree to indemnify and hold harmless The Company, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys' fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.

      </li>
      <li>
				Miscellaneous. This Agreement constitutes the entire agreement between The Company and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of The Company, or by the posting by The Company of a revised version. Except to the extent applicable law, if any, provides otherwise, this Agreement, any access to or use of the Website will be governed by the laws of Mexico, excluding its conflict of law provisions, and the proper venue for any disputes arising out of or relating to any of the same will be the federal courts located in Mexico City, Mexico. The prevailing party in any action or proceeding to enforce this Agreement shall be entitled to costs and attorneys' fees. If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties' original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. You may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; The Company may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.


      </li>
    </Ol>
    <span>
				Note: Our Terms of Service were adapted from the Wordpress.com site and service. Thanks to the Automattic guys who have graciously made them available through a Creative Commons license.
    </span>
  </Container>

);

export default Terms;
