import { observable, decorate } from 'mobx'

class ModalStore {
  blur = '0'
  blurSession = '0'
  blurOnModal
  blurOnHeader = false
  blurOnModalSession = false
  isOpen
  showMessageLogin = false
  isClickable = true
  showFooter = true
  isOpenAvatarMenu = false
  isOpenMenuExtend = false

  onFooter(show) {
    this.showFooter = show
  }

  addBlur() {
    this.blur = '12px'
  }

  addBlurOnModal() {
    this.blur = '12px'
    this.blurOnModal = true
  }

  addBlurOnHeader() {
    this.blur = '12px'
    this.blurOnHeader = true
  }

  removeBlurOnModal() {
    this.blur = '0'
    this.blurOnModal = false
  }

  addBlurOnModalSession() {
    this.blurSession = '8px'
    this.blurOnModalSession = true
  }

  removeBlurOnModalSession() {
    this.blurSession = '0'
    this.blurOnModalSession = false
  }

  removeBlurOnHeader() {
    this.blur = '0'
    this.blurOnHeader = false
  }

  removeBlur() {
    this.blur = '0'
  }

  openSignInModal() {
    this.isOpen = true
  }

  closeSignInModal() {
    this.isOpen = false
  }

  openMessageLogin() {
    this.showMessageLogin = true
    this.isClickable = false
  }

  onCloseMessageLogin() {
    this.showMessageLogin = false
    this.isClickable = true
  }

  onOpenMenu(action) {
    this.isOpenAvatarMenu = action === 'openAvatarMenu'
    this.isOpenMenuExtend = action === 'openMenuExtend'
  }

  onCloseMenu() { 
    this.isOpenAvatarMenu = false
    this.isOpenMenuExtend = false
  }
}

export default decorate(ModalStore, {
  blur: observable,
  blurSession: observable,
  blurOnModal: observable,
  blurOnHeader: observable,
  blurOnModalSession: observable,
  isOpen: observable,
  showMessageLogin: observable,
  isClickable: observable,
  showFooter: observable,
  isOpenAvatarMenu: observable,
  isOpenMenuExtend: observable,
})
