import React, {  Component } from 'react'
import socketIOClient from 'socket.io-client'
import Modal from 'react-responsive-modal'
import Translate from '../../utils/Translate'
import Storage from "localStorage"
import { inject, observer } from "mobx-react"
import Projects from '../projects/'
import { withRouter } from 'react-router-dom'
import avatarDefault from '../../assests/img/avatar-default.png';
import './UserMenu.css'

class UserMenu extends Component {
  state = {
    data: {},
    lang: null,
    modeValue: Storage.getItem('mode'),
  }

  componentDidMount() {
    const socket = socketIOClient('https://api.seenapse.it')
    socket.emit('totalNotifications', { user_id: '6678' })

    socket.on('notifications', data => {
      this.setState({
        data,
        lang: Storage.getItem('lang')
      })
    })
  }

  handleChangeValue = (event) => {
    const { value } = event.currentTarget
    this.setState({lang: value})
    Storage.setItem('lang', value)
    Storage.setItem('translate_lang', value)
  }

  // Temporaly deactive
  // handleChangeModeValue = (event) => {
  //   const { value } = event.currentTarget
  //   const {history} = this.props
  //   this.setState({ modeValue: value })
  //   Storage.setItem('mode', value);
  //   if (value === 'form') {
  //     history.push('/')
  //     this.props.onCloseModal()
  //   }
  // }

  handleProfile = () => {
    const {history} = this.props
    history.push('/update-profile')
  }

  render() {
    const { user, notification, lang, inspiredStore, ...props } = this.props
    // const { modeValue } = this.state

    const userAvatar = !user.img.includes('https') ? avatarDefault : user.img

    return (
      <Modal
        open={props.isOpenAvatarMenu}
        onClose={props.onCloseModal}
        classNames={{
          overlay: 'styles_overlay__CLSq-2',
          modal: `
        d-none d-sm-none d-md-none d-lg-block d-xl-block styles_modal__gNwvD3`
        }}
        center
      >
        <section>
          <div>
            <img src={userAvatar} alt='Avatar-signOut' />
            <span className='avatar-desktop'>{user.name}</span>
          </div>
          <div style={{marginTop: 15}}>
            <label style={{
                fontSize: 11,
                fontWeight: 'bold',
                color: 'white'
              }}
            >
              {Translate.get("language", "LANGUAGE")}
            </label>
            <div className="user-menu_wrapper">
              <select
                  name="lang"
                  className="user-menu_select"
                  value={Storage.getItem('lang') || 'en'}
                  onChange={(e) => {
                    this.handleChangeValue(e)
                    inspiredStore.setLang()
                  }
                  }
              >
                  <option value="en">{Translate.get("en", "English")}</option>
                  <option value="es">{Translate.get("es", "Spanish")}</option>
              </select>
            </div>
          </div>
          {/* Temporaly deactive */}
          {/* <div style={{marginTop: 15}}>
            <label style={{ fontSize: 11, fontWeight: 'bold', color: 'white'}}>{Translate.get("mode", "MODE")}</label>
            <div className="select-wrapper">
              <select
                className="generate-ideas-select"
                value={this.state.modeValue}
                name="mode"
                onChange={this.handleChangeModeValue}
              >
                <option value="chat">Chat</option>
                <option value="form">{Translate.get("forms", "Form")}</option>
              </select>
            </div>
          </div> */}
          <Projects user={this.props.user} onCloseModal={this.props.onCloseModal} />
          <div style={{display:'flex'}}>
            <div style={{margin: 'auto'}}>
            <button
                id="register"
                type="submit"
                className="generate-ideas-button"
                onClick={() => {
                  props.onCloseModal();
                  this.handleProfile();
                }}
            >
                {Translate.get('account', 'ACCOUNT')}
            </button>
            </div>
          </div>
          <div style={{display:'flex'}}>
            <div style={{margin: 'auto'}}>
              <button
                  id="register"
                  type="submit"
                  className="generate-ideas-button"
                  onClick={props.signOut}
              >
                  {Translate.get('signOut', 'SIGN OUT')}
              </button>
            </div>
          </div>
        </section>
      </Modal>
    )
  }
}
const menu = inject("inspiredStore")(observer(UserMenu))
export default withRouter(menu)
