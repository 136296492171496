import React, { Component } from "react";
import { TweenLite } from "gsap";
import "./Thumbnail.css";
import withLoadingBackground from "../../utils/withLoadingBackground";

class Preview extends Component {
  thumbnail = null;
  state = {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundImage: `url(${this.props.src})`
  };
  componentDidMount() {
    if (this.props.radius) {
      this.setPositions();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.radius && prevProps.src !== this.props.src) {
      this.setPositions(prevProps);
    }
  }
  setPositions = (prev = {}) => {
    const { index, radius, angle, origin } = this.props;
    const thumbnailBox = this.thumbnail.getBoundingClientRect();
    const initialLeft = origin.x - thumbnailBox.width / 2;
    const initialTop = origin.y - thumbnailBox.height / 2;
    const left = initialLeft + radius * Math.cos((index + 1) * angle);
    const top = initialTop + radius * Math.sin((index + 1) * angle);
    TweenLite.set(this.thumbnail, {
      position: "absolute",
      left: initialLeft,
      top: initialTop
    });
    TweenLite.to(this.thumbnail, 0.3, { css: { left, top }, delay: 0.5 });
    this.thumbnail.addEventListener("mouseover", function() {
      TweenLite.set(this, { zIndex: 100 });
      TweenLite.to(this, 0.3, { scale: 5 });
    });
    this.thumbnail.addEventListener("mouseout", function() {
      TweenLite.set(this, { zIndex: 1 });
      TweenLite.to(this, 0.3, { scale: 1 });
    });
  };

  render() {
    const { backgroundColor } = this.props;
    let classList = ["preview"];
    return (
      <div
        onClick={this.props.onClick}
        className={classList.join(" ")}
        ref={el => (this.thumbnail = el)}
        style={{ ...this.state, backgroundColor }}
      />
    );
  }
}

export default withLoadingBackground(Preview);
