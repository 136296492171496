import React from 'react';
import Translate from '../../../utils/Translate';
import PlanetImage from '../../../assests/img/planet.png';
import Footer from '../footer';

export default function ClimateThanksMessage() {
  return (
    <div>
      <div style={{ minHeight: 'calc(100vh - 150px)' }}>
        <h2 className="climateTitle">
          {Translate.get('climateTitle', 'Brainstorming for climate solutions')}
        </h2>
        <figure className="climate-image">
          <img src={PlanetImage} alt="planet" />
        </figure>
        <p className="climate-message-information">
          {Translate.get(
            'climateInformationMessage',
            'Thank you for your application!',
          )}
        </p>
        <p className="climate-soon-message">
          {Translate.get('climateSoonMessage', ' We’ll get in touch soon.')}
        </p>
      </div>
      <Footer />
    </div>
  );
}
