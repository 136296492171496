import React, {Component, Fragment} from 'react';
import { IMAGES_BUCKET } from '../../config';
import SeenapseGrandChildren from './SeenapseGrandChildren';
import withLoadingBackground from '../../utils/withLoadingBackground';

class SeenapseChild extends Component{
  render(){
    return <Fragment>
      <a
        target="_blank"
        href={this.props.url}
        ref="childImageElement"
        className="seenapseImg bottom"
        style={{
          backgroundColor: this.props.backgroundColor,
          backgroundImage:`url(${IMAGES_BUCKET}${this.props.img})`
        }}
      ><span>{this.props.name}</span></a>
      <SeenapseGrandChildren childImageElement={this.refs.childImageElement} {...this.props} />
    </Fragment>
  }
}

export default withLoadingBackground(SeenapseChild);