import React from 'react';
import { inject, observer } from "mobx-react";
import Canvas from './Canvas';
import Modal from '../modal/Modal';
import SeenapseGrandParent from './SeenapseGrandParent';
import "./Seenapse.css";
import SeenapseParent from './SeenapseParent';
import SeenapseContent from './SeenapseContent';
import SeenapseChild from './SeenapseChild';
import Dialog from "../rootModal"
import Inspired from "./Inspired"

class Seenapse extends React.Component {
  state = {
    isPortrait: true,
    modalElement: null,
    sectionRect: { width: 0, height: 0 },
    openModal: false,
  }
  section = null
  container = null
  componentDidMount() {
    setTimeout(() => this.fixRatio(), 100)
    window.addEventListener("resize", this.fixRatio)
  }

  fixRatio = () => {
    if (this.state.modalElement) {
      const isPortrait = this.modalIsPortrait(this.state.modalElement)
      this.setState({ isPortrait })
    }
    if (this.section) {
      const { width, height } = this.section.getBoundingClientRect()
      this.setState({ sectionRect: { width, height } })
    }
  }
  setModalElement = (el) => {
    const isPortrait = this.modalIsPortrait(el)
    this.setState({ modalElement: el, isPortrait })
  }
  modalIsPortrait = (el) => {
    if (el) {
      const { width, height } = el.getBoundingClientRect()
      return width <= height
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.fixRatio)
  }

  handleOpenModal = () => {
    this.setState({ openModal: !this.state.openModal }, () =>
      this.props.modalStore.addBlurOnModalSession()
    )
  }

  handleCloseModal = () => {
    this.setState({ openModal: !this.state.openModal }, () =>
      this.props.modalStore.removeBlurOnModalSession()
    )
  }

  render() {
    const { parent, child } = this.props
    const { openModal } = this.state

    return (
      <React.Fragment>
        <Modal
          onModalAvailable={this.setModalElement}
          onClose={this.props.onClose}
          open={this.props.open}
          isPortrait={this.state.isPortrait}
        >
          <div ref={(el) => (this.container = el)} className="seenapse">
            <SeenapseGrandParent
              pathname={this.props.location.pathname}
              height={this.state.sectionRect.height}
              {...parent}
            />
            <section ref={(el) => (this.section = el)}>
              <Canvas
                sectionRect={this.state.sectionRect}
                isPortrait={this.state.isPortrait}
              />
              <SeenapseParent index={1} {...parent} />
              <SeenapseContent
                parent={parent}
                child={child}
                height={this.state.sectionRect.height}
                handleOpenModal={this.handleOpenModal}
              />
              <SeenapseChild
                index={2}
                parentElement={this.section}
                pathname={this.props.location.pathname}
                isPortrait={this.state.isPortrait}
                sectionRect={this.state.sectionRect}
                {...child}
              />
            </section>
          </div>
        </Modal>
        <Dialog
          onClose={this.handleCloseModal}
          active={openModal}
        >
          <Inspired child={child} parent={parent} />
        </Dialog>
      </React.Fragment>
    )
  }
}

export default inject("modalStore")(observer(Seenapse))
