import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import UserStore from '../../models/UserStore'
import HeaderAccount from './HeaderAccount'
import AccountResults from './AccountResults'
import Loader from '../../components/loader'
import { getTypeResult } from '../../utils/Utils'
import { sendMetric } from '../../services/mixpanel'
import Storage from 'localStorage'
import './Account.css'

const langStore = Storage.getItem('lang')

/**
 * Selected items
 * followees
 * followers
 * favs
 *  seenapses
 */
class AccountContainer extends Component {
  state = {
    loader: false,
    userId: null,
    userAccount: null,
    notifications: null,
    isInNotifications: false,
    selected: this.props.match.params.section,
    params: this.props.match.params,
    displayUserInfo: this.routeContainsWord(
      this.props.location.pathname,
      'user'
    )
  }

  componentDidMount() {
    let { userId } = this.props.match.params
    sendMetric('User account', { userId: userId })
    const currentRoute = this.props.location.pathname
    this.props.modalStore.removeBlur()
    this.props.modalStore.removeBlurOnModal()
    this.setState(
      {
        isInNotifications: this.routeContainsWord(currentRoute, 'notifications')
      },
      () => {
        if (!this.state.displayUserInfo) {
          userId = UserStore.getUid()
        }
        if (userId) {
          this.fetchUser(userId)
        }
      }
    )
  }

  componentWillReceiveProps(next) {
    let nextUserId = next.match.params.userId
    const currentRoute = next.location.pathname
    const isDifferentUser = nextUserId && nextUserId !== this.state.userId
    const pageHasChanged = currentRoute !== this.props.location.pathname
    if (isDifferentUser || pageHasChanged) {
      this.props.modalStore.removeBlur()
      this.props.modalStore.removeBlurOnModal()
      this.setState(
        {
          selected: next.match.params.section,
          userId: nextUserId,
          isInNotifications: this.routeContainsWord(
            currentRoute,
            'notifications'
          )
        },
        () => {
          if (isDifferentUser) this.fetchUser(next.match.params.userId)
        }
      )
    } else {
      this.setState({ selected: next.match.params.section })
    }
  }

  getNotifications = userId => {
    this.setState({ loader: true })
    const params = {
      user_id: userId,
      lang: langStore,
      page: 1,
      size: 50
    }
    UserStore.getAllNotifications(params)
      .then(data => {
        this.setState({
          notifications: data.data
        })
      })
      .catch(error => console.error(error))
      .finally(() => {
        this.setState({ loader: false })
      })
  }

  fetchUser = userId => {
    this.getNotifications(userId)
    this.getDataUser(userId)
  }

  getDataUser(userId) {
    this.setState({
      loader: true
    })
    UserStore.getUserProfile(userId)
      .then(data => {
        this.setState({
          userAccount: data.data,
          userId: userId
        })
      })
      .finally(() => {
        this.setState({ loader: false })
      })
  }

  routeContainsWord(currentRoute, word) {
    return currentRoute.indexOf(`${word}`) !== -1
  }

  render() {
    const {
      userAccount,
      selected,
      userId,
      loader,
      params,
      notifications,
      isInNotifications
    } = this.state

    const {
      location: { pathname }
    } = this.props

    if (loader || !userAccount || (isInNotifications && !notifications)) {
      return <Loader />
    }
    return (
      <div>
        {!isInNotifications && (
          <HeaderAccount
            userData={userAccount}
            total={
              isInNotifications
                ? notifications.data.length
                : userAccount[selected] && userAccount[selected].nbHits
            }
            isInNotifications={isInNotifications}
            displayUserInfo={
              this.routeContainsWord(pathname, 'account') ||
              this.routeContainsWord(pathname, 'user')
            }
            selected={selected}
            params={params}
          />
        )}
        <AccountResults
          params={params}
          userId={userId}
          selected={selected}
          isNotifications={isInNotifications && !!notifications}
          results={isInNotifications ? notifications : userAccount[selected]}
          typeResult={getTypeResult(selected)}
        />
      </div>
    )
  }
}

export default inject('modalStore')(withRouter(observer(AccountContainer)))
