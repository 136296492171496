import React, { useEffect } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { isInSummaryModal } from '../../utils/Utils'
import Translate from './../../utils/Translate'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: fixed;
  ${'' /* background: rgba(0, 0, 0, 0.4); */}
`

const Container = styled.div`
  background-color: #91cae5;
  position: fixed;
  border-radius: 50%;
  width: 320px;
  height: 320px;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: calc(50vh - 160px);
  left: calc(50vw - 160px);
  padding: 0 3%;
`
const Text = styled.p`
  text-align: center;
  color: black;
  font-weight: 300;

  & > span {
    cursor: pointer;
  }
`

const MessageLogin = props => {
  const path = props.location.pathname
  const { modalStore } = props

  useEffect(() => {
    modalStore.addBlurOnModalSession()
  }, [])

  if (isInSummaryModal(path)) {
    modalStore.addBlurOnModal()
  } else {
    modalStore.addBlur()
  }

  const onClose = () => {
    modalStore.onCloseMessageLogin()
    modalStore.removeBlurOnModal()
  }

  return (
    <Wrapper>
      <Container>
        <Text>:(</Text>
        <Text>
          {Translate.get(
            'errMessageLogin',
            "You need to login to do that. Don't have an account yet? Sign up, it's free and it only takes a second!"
          )}
        </Text>
        <Text>
          <span onClick={onClose}>{Translate.get('close', 'close')}</span>
        </Text>
      </Container>
    </Wrapper>
  )
}

export default inject('modalStore')(observer(withRouter(MessageLogin)))
