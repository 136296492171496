import Translate from './../../utils/Translate'
import ApiUser from '../../shared/login/ApiUser'

export const validateForm = formData => {
  let errorMsg = {
    email: Translate.get('errEmailValid', 'Please enter a valid email address'),
    password_confirmation: Translate.get(
      'errPassConfirmation',
      'Please enter a valid email confirm password'
    ),
    password: Translate.get('errPass', 'Please enter a password'),
    name: Translate.get('errName', 'Please enter a name'),
    bio: Translate.get('errBio', 'Please enter a bio'),
    profession: Translate.get('errProfession', 'Please enter a profession'),
    country: Translate.get('errCountry', 'Please enter a country')
  }
  let errors = []
  for (let k in formData) {
    if (k !== 'bio') {
      if (formData[k].length === 0) {
        errors.push(errorMsg[k])
      }
    }
  }
  return errors
}

export const showImage = async img => {
  let imageSelected = null
  let files = img.target.files
  for (var i = 0; i < files.length; i++) {
    let file = files[i]
    let imageType = /image.*/
    if (!file.type.match(imageType)) {
      continue
    }

    if (file != undefined) {
      let formData = new FormData()
      formData.append('profilepic', file)

      try {
        const response = await ApiUser.uploadImage(formData)
        imageSelected = `https://api.seenapse.it/${response.data.file}`
      } catch (error) {
        console.error(error.response.data.errors)
      }
    }
  }
  return { imageSelected }
}

export const getErrors = error => {
  if (error.response.status === 400) {
    let labelError = Translate.get('errCreateAccount', 'There was an error creating your account');

    if (
      error.response.data.errors.email &&
      error.response.data.errors.email[0] === 'has already been taken'
    ) {
      labelError = Translate.get(
        'errEmailTaken',
        'This email account is already associated with a Seenapse account'
      )
    }
    if (
      error.response.data.errors.password_confirmation &&
      error.response.data.errors.password_confirmation[0] ===
        'does not match confirmation'
    ) {
      labelError = Translate.get(
        'errPasswordConfirmation',
        'Password does not match confirmation'
      )
    }
    return { labelError, showAlertError: true }
  }
}
