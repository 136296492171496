import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DefaultAvatar from '../../assests/img/avatar-default.png';
import { getUser } from '../../services/user';
import { sendMetric } from '../../services/mixpanel';
import Translate from './../../utils/Translate'

const Container = styled.div`
  padding: 0 12%;
`;

const H1 = styled.p`
  color: white;
  font-family:  HelveticaNeue;
  font-size: calc(24px + 4 * ((100vw - 320px) / (1600 - 320)));
  line-height: 1.5;
  font-weight: 200;
  text-align: center;
  margin: 16px 0;
`;

const H2 = styled(H1)`
  margin: 0px 0;
  font-size: calc(20px + 4 * ((100vw - 320px) / (1600 - 320)));
  text-align: left;
`
const Text = styled.p`
  margin: 10px 0 22px 0;
`;

const Contact = styled.a`
  color: rgb(202, 233, 60);

  &:hover{
    color: rgb(202, 233, 60);
    text-decoration: underline;
    -webkit-text-decoration-color: #d4e245;
    text-decoration-color: #d4e245;
  }
`
const Links = styled(Link)`
  display: inline-block;
  margin-right: 3%;
`;

const Avatar = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-image: url('${props => props.image}'), url('${DefaultAvatar}');
  background-repeat: no-repeat;
  background-size: cover;
`;

const usersId = [1, 5, 10, 3, 229, 711, 238];

class WhatIsSeenapse extends Component{
  state={
    contributors:{}
  }

  componentDidMount(){
    sendMetric('What is', {})
    usersId.forEach((id) => {
      getUser(id).then(({data}) => {
        this.setState({
          contributors: {
            ...this.state.contributors,
            [data.uid]:data
          }
        })
      })
    })
  }

  render(){
    const { contributors } = this.state;

    return(
      <Container className="container">
          <header>
            <H2>Seenapse is your new creative partner.</H2>
          </header>
            <div>
              <p>It’s always there, in your pocket or in your desktop, ready to brainstorm with you. It’s always in the mood, never gets tired, and never suffers from creative blocks. All it needs is the equivalent of buying it lunch once a month, and it will be happy cranking out names, headlines, scripts, and many other types of ideas for you.</p>
            </div>
          <div>
            <H2>It’s unlike anything else out there.</H2>
            <p>Different from other idea generators or marketing copy based on AI tools, Seenapse is a unique combination of the ingenuity of human lateral thinking and the speed and automation of artificial intelligence. This is why Seenapse generates divergent, interesting ideas, while the others generate ok ideas.
            </p>

            <H2>It’s fast.</H2>
            <p>You can generate hundreds of ideas in minutes. This means you can concentrate on spotting and polishing the ideas you know are winners, instead of glancing back and forth from the clock to the blank page.
            </p>

            <H2>It’s easy.</H2>
            <p>All you have to do is fill out some fields, specify how many ideas you want, and then copying those that you like (soon it will be able to send them directly to other tools, like Google Docs, Miro, Slack, etc.). And you can tweak the words you’re using and see if the results are closer to what you want.</p>

            <H2>Here’s a quick demo:</H2>
            <p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/7aosboGaVL4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </p>


           
            <p>{Translate.get('aboutPleaseFeel','Please feel free to reach to us with questions, comments, and suggestions. We also love to hear about success stories from seenapsers. Email us at ')}<Contact href="mailto:contact@seenapse.ai">contact@seenapse.ai</Contact>.
            </p>
            <p>{Translate.get('aboutCheers','Cheers')},</p>
            {usersId.map( userId => {
              const contributor = contributors[userId] || {};
              return(
                <Links
                  key={contributor.uid}
                  to={`/user/${contributor.uid}/seenapses`}
                >
                  <Avatar
                    image={contributor.img}
                  />
                </Links>
              )
            })}
            <Text>{Translate.get('aboutTheSeenapseTeam','The Seenapse Team')}</Text>
          </div>
      </Container>
    )
  }
}

export default WhatIsSeenapse;
