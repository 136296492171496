import React from 'react'
import { Link } from 'react-router-dom'
import { escapeSpecialCharacters } from '../utils/Utils'
import withLoadingBackground from '../utils/withLoadingBackground'

const SeenapseResults = props => {
  const { seenapse, currentPath, pathImage, backgroundColor } = props
  const pimg = seenapse.pimg
    ? seenapse.pimg.includes('svg')
      ? ''
      : seenapse.pimg
    : ''
  const img = seenapse.img
    ? seenapse.img.includes('svg')
      ? ''
      : seenapse.img
    : ''
  return (
    <Link
      to={`${currentPath}/${seenapse.palias}/${seenapse.alias}`}
      className='link-underline'
    >
      <div
        style={{
          ...props.style,
          backgroundColor: backgroundColor,
          backgroundImage: `url(${pathImage}${pimg})`
        }}
        className='result-parent format-images-results'
      />
      <div
        style={{
          ...props.childStyle,
          backgroundColor: backgroundColor,
          backgroundImage: `url(${pathImage}${img})`
        }}
        className='result-children format-images-results'
      />
      <div className='names-images'>
        <span
          dangerouslySetInnerHTML={escapeSpecialCharacters(seenapse.pname)}
        />
        <span className='and'> & </span>
        <span
          dangerouslySetInnerHTML={escapeSpecialCharacters(seenapse.name)}
        />
      </div>
    </Link>
  )
}

export default withLoadingBackground(SeenapseResults)
