import React, { Fragment } from 'react';
import Utils from '../../utils/Utils';
import './LoginLinksMobile.css';
import { API_HOST } from '../../shared/constants/Constants';

export default function LoginLinksMobile({ signInAction }) {
  return (
    <Fragment>
      <a
        className="icon-login-mobile no-underline"
        href={Utils.getLinkEmailAuth(signInAction)}
      >
        <span className="logo-links icon-email-mobile" />
      </a>
      <a
        className="icon-login-mobile no-underline"
        href={`${API_HOST}/auth/google`}
      >
        <span className="logo-links icon-google-mobile" />
      </a>
    </Fragment>
  );
}
