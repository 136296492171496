import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 12%;
`;

const H1 = styled.p`
  color: white;
  font-family: HelveticaNeue;
  font-size: calc(24px + 4 * ((100vw - 320px) / (1600 - 320)));
  line-height: 1.5;
  font-weight: 200;
  text-align: center;
  margin: 16px 0;
`;

const H2 = styled(H1)`
  font-size: calc(20px + 4 * ((100vw - 320px) / (1600 - 320)));
`;

const UL = styled.ul`
  color: white;
  font-weight: 200;
`;

const Privacy = () => (
  <Container className="container">
    <H1>Privacy Policy</H1>
    <H2>
    Your privacy is critically important to us. At Seenapse we have a few fundamental principles:
    </H2>
    <H2>When using it, you agree to the following terms and conditions:</H2>
    <UL>
      <li>
We don't ask you for personal information unless we truly need it.
      (We can't stand services that ask you for things like your gender or
      income level for no apparent reason.)
      </li>
      <li>
We don't share your personal information with anyone except to comply with the law, develop
      our products, or protect our rights.

      </li>
      <li>We don't store personal information on our servers unless required for the on-going operation of one of our services.</li>
      <li>We aim to make it as simple as possible for you to control what's visible to the public, seen by search engines, kept private, and permanently deleted.</li>
    </UL>
    <p>Below is our privacy policy which incorporates these goals.</p>
    <p>If you have questions about deleting or correcting your personal data please contact our support team.</p>
    <p>Seenapse SAPI de CV. ("Seenapse") operates this site. It is Seenapse's policy to respect your privacy regarding any information we may collect while operating it.</p>
    <H2>Website Visitors</H2>
    <p>Like most website operators, Seenapse collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Seenapse's purpose in collecting non-personally identifying information is to better understand how Seenapse's visitors use its website. From time to time, Seenapse may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
    <p>Seenapse also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users. Seenapse only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>

    <H2>Gathering of Personally-Identifying Information</H2>

    <p>Certain visitors to Seenapse's websites choose to interact with Seenapse in ways that require Seenapse to gather personally-identifying information. We ask visitors who sign up to provide a username, email address, occupation, and Country were they have lived the most. These last two we use to improve relevancy in your use of our platform. In each case, Seenapse collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Seenapse. Seenapse does not disclose personally-identifying information other than as described below.</p>

    <H2>Aggregated Statistics</H2>

    <p>Seenapse may collect statistics about the behavior of visitors to its websites. Seenapse may display this information publicly or provide it to others. However, Seenapse does not disclose personally-identifying information other than as described below.</p>

    <H2>Protection of Certain Personally-Identifying Information</H2>

    <p>
Seenapse discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Seenapse's behalf or to provide services available at Seenapse's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Seenapse's website, you consent to the transfer of such information to them. Seenapse will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Seenapse discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Seenapse believes in good faith that disclosure is reasonably necessary to
  protect the property or rights of Seenapse, third parties or the public at large. If you are a registered user of the Seenapse website and have supplied your email address, Seenapse may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Seenapse and our products. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Seenapse takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.

    </p>

    <H2>Cookies</H2>

    <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Seenapse uses cookies to help Seenapse identify and track visitors, their usage of Seenapse website, and their website access preferences. Seenapse visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Seenapse's websites, with the drawback that certain features of Seenapse's websites may not function properly without the aid of cookies.</p>

    <H2>Business Transfers</H2>

    <p>If Seenapse, or substantially all of its assets, were acquired, or in the unlikely event that Seenapse goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Seenapse may continue to use your personal information as set forth in this policy.</p>

    <H2>Ads</H2>

    <p>Ads appearing on any of our websites may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Seenapse and does not cover the use of cookies by any advertisers.</p>

    <H2>Privacy Policy Changes</H2>

    <p>Although most changes are likely to be minor, Seenapse may change its Privacy Policy from time to time, and in Seenapse's sole discretion. Seenapse encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>

    <p>Note: Our Privacy Policy was adapted from the Wordpress.com site and service. Thanks to the Automattic guys whom have graciously made it available through a Creative Commons license.</p>
  </Container>
);

export default Privacy;
