import React from 'react';
import { Link } from 'react-router-dom';
import notFound from '../../assests/img/404.png';
import './not-found.css';

const NotFound = () => (
  <section className="container-img-404">
    <picture className="img-404">
      <img src={notFound} alt="404" />
    </picture>
    <div className="text-404">
      <h3>Whoops!</h3>
      <p>It's a 404 error, meaning that page couldn't be found.</p>
      <p>
      Would you like to &nbsp;
        <Link
          to="/"
          className="underline link-seenapse"
        >
        go home?

        </Link>
      </p>
    </div>
  </section>
);

export default NotFound;
