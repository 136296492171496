import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import logo from '../../assests/img/logo.png';
import SignIn from '../../containers/SignIn';
import { isInSummaryModal } from '../../utils/Utils';
import './Header.css';

let classNamesHeader = {};

const getHeaderClassName = (pathName, isOpen, isPortrait) => {
  if (isInSummaryModal(pathName)) {
    if (isOpen) {
      classNamesHeader = {
        header: `${isPortrait ? 'header-summary' : 'header-summary-desktop'}`,
        menuHamburguer: 'menu-hamburguer-summary',
      };
    }
  } else {
    classNamesHeader = {
      menuHamburguer: 'menu-hamburger',
    };
  }
};

const Header = (props) => {
  const pathName = props.location.pathname;
  const { blur, blurOnHeader } = props.modalStore;
  const isSearch = (pathName !== '/' && pathName !== '/home');
  getHeaderClassName(pathName, true, props.isPortrait);

  const isClimate = pathName.includes('climate');

  const handleClickLogo = () => {
    if (props.user && props.user.uid) return;
    props.history.push('/');
  };

  return (
    <header
      style={{ filter: `blur(${blurOnHeader ? blur : '0'})` }}
    >
      <nav className="navbar navbar-light justify-content-between
            navbar-sm close-modal"
      >

        <div
          role="button"
          onClick={handleClickLogo}
          style={{ cursor: 'pointer' }}
          className={`navbar-brand img-fluid ${classNamesHeader.hiddenElements}`}
        >
          <img src={logo} alt="Logo-Seenapse" />
        </div>
        {!isClimate && (
          <div className="avatar-form-group">
            <SignIn
              history={props.history}
              isSearch={isSearch}
              altAttribute={classNamesHeader.menuHamburguer}
            />
          </div>
        )}
      </nav>
    </header>
  );
};

export default inject(
  'modalStore',
)(withRouter(observer(Header)));
