import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import SignInOptions from './SignInOptions';
import './SignInActions.css';
import logoLogin from '../../assests/img/icon_login_right.png';
import logoSignUp from '../../assests/img/icon_signup_right.png';
import Translate from '../../utils/Translate';

const SignInActions = ({
  expandedLogIn, expandedSignUp, expand, isSearch,
}) => (
  <Fragment>
    {(!expandedLogIn && !expandedSignUp)
        && (
        <Fragment>
          <div role="button" className="log-in" onClick={() => expand('login')}>
            <figure>
              <img src={logoLogin} alt="Login" />
            </figure>
            <span className="sign-in-actions">
              {Translate.get('logIn', 'LOG IN')}
            </span>
          </div>
          <div role="button" className="sign-up" onClick={() => expand('signup')}>
            <figure>
              <img src={logoSignUp} alt="sign-up" />
            </figure>
            <span className="sign-in-actions">
              {Translate.get('logInWith', 'SIGN UP')}
            </span>
          </div>
        </Fragment>
        )
    }
    {
      expandedLogIn
        && (
        <div role="button" className="sign-up align-with-icons" onClick={() => expand()}>
          <figure>
            <img src={logoLogin} alt="Login with" />
          </figure>
          <span className="sign-in-actions">
            {Translate.get('logIn', 'LOG IN WITH')}
          </span>
        </div>
        )
      }
    {
        expandedSignUp
        && (
        <div role="button" className="sign-up align-with-icons" onClick={() => expand()}>
          <figure>
            <img src={logoSignUp} alt="signup with" />
          </figure>
          <span className="sign-in-actions">
            {Translate.get('logInWith', 'SIGN UP WITH')}
          </span>
        </div>
        )
      }

    {(expandedLogIn || expandedSignUp)
        && (
        <SignInOptions
          expandedLogIn={expandedLogIn}
          isSearch={isSearch}
        />
        )
    }

    {
      expandedLogIn
    && (
    <p className="sign-in-instructions">
      {Translate.get('DontHaveAccount', 'Don`t have an account yet?')}
      {' '}
      <span
        role="button"
        className="link-seenapse underline"
        onClick={() => expand('signup')}
      >
        {Translate.get('signUpNow', 'Sign up now')}
      </span>
,
      {Translate.get('itsFree', 'it`s free, and it only takes a minute.')}
    </p>
    )
    }
    {
      expandedSignUp
      && (
      <p className="sign-in-instructions">
        {Translate.get('bySigning', 'By signing up you indicate that you have read and agree to the')}
        {' '}
&nbsp;
        <Link to="/terms" onClick={() => expand()}>
          <span className="link-seenapse underline">
            {Translate.get('terms', 'Terms of Service.')}
          </span>
        </Link>
      </p>
      )
    }
  </Fragment>
);

export default SignInActions;
