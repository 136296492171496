import React, { Fragment } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import LoginLinksMobile from './LoginLinksMobile';
import Avatar from './Avatar';
import './UserMenuExtend.css';
import Translate from '../../utils/Translate';
import Projects from '../projects';


const UserMenuExtend = props => (
  <Modal
    open={props.isOpenMenuExtend}
    onClose={props.onCloseModal}
    showCloseIcon={props.showCloseIcon}
    classNames={{
      transitionExitActive: 'transitionExitActive',
      overlay: 'styles_overlay__CLSq-2',
      modal: `d-block d-sm-block d-md-block
        d-lg-none d-xl-none styles_modal__gNwvD1`,
    }}
    center
  >
    {!props.isLogged && (
      <Fragment>
        <h3 className="text-item-modal">
          {props.signInAction
            ? Translate.get('logInTo', 'Log in to Seenapse:')
            : Translate.get(
              'CreateASeenapseAcc',
              'Create a Seenapse account through:',
            )}
        </h3>
        <LoginLinksMobile signInAction={props.signInAction} />
        <p className="instructions text-item-modal">
          {props.signInAction ? (
            <Fragment>
              {Translate.get(
                'DontHaveAccountYet',
                "Don't have an account yet?",
              )}
              <span
                className="link-seenapse underline"
                onClick={() => props.switchSignInAction()}
              >
                {Translate.get('signUpNow', 'Sign up now')}
              </span>
              ,
              {' '}
              {Translate.get(
                'itsFree',
                'it`s free, and it only takes a minute.',
              )}
            </Fragment>
          ) : (
            <Fragment>
              {Translate.get(
                'ifYouDont',
                'If you have a Seenapse account, please',
              )}
              {' '}
              &nbsp;
              <span
                className="link-seenapse underline"
                onClick={() => props.switchSignInAction('login')}
              >
                {Translate.get('logInMobile', 'login')}
              </span>
            </Fragment>
          )}
        </p>
      </Fragment>
    )}
    {props.isLogged && (
      <Fragment>
        <Avatar isOpenAvatarMenu={props.isOpenMenuExtend} user={props.user} />
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <label style={{ fontSize: 11, fontWeight: 'bold', color: 'white' }}>{Translate.get('language', 'LANGUAGE')}</label>
          <div className="user-menu_wrapper">
            <select
              className="user-menu_select"
              name="lang"
              value={props.lang}
              onChange={(e) => { { props.handleChangeValueLang(e); } }}
            >
              <option value="en">{Translate.get('en', 'English')}</option>
              <option value="es">{Translate.get('es', 'Spanish')}</option>
            </select>
          </div>
        </div>
        {/* {!props.showTrial &&
          <Projects user={props.user} onCloseModal={props.onCloseModal} />
        } */}
        <Projects user={props.user} onCloseModal={props.onCloseModal} />
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto' }}>
            <Link
              style={{ marginTop: 15 }}
              onClick={props.onCloseModal}
              to="/update-profile"
              className="about-mobile"
            >
              <button
                id="register"
                type="submit"
                className="generate-ideas-button"
              >
                {Translate.get('account', 'ACCOUNT')}
              </button>

            </Link>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto' }}>
            <button
              id="register"
              type="submit"
              className="generate-ideas-button"
              onClick={props.signOut}
            >
              {Translate.get('signOut', 'SIGN OUT')}
            </button>
          </div>
        </div>

      </Fragment>
    )}
  </Modal>
);

export default UserMenuExtend;
