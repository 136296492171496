import React, {Component} from 'react';
import Thumbnail from './Thumbnail';
import { IMAGES_BUCKET } from '../../config'
import newSeenapse from '../../assests/img/new.png';
import Translate from './../../utils/Translate';

export default class SeenapseGrandChildren extends Component{
  /* This function generates a circled group of certain number
   * @param {array} collection - Collection of items 
   * @param {object} origin - Center of the circle
   * @param {number} slices - Number of slices in the circle
   * @param {number} width - Length of the circle container
   * @param {number} start - Initial index
   * @param {number} total - Number of items in the circle
   * @param {array} positions - 0. Inicio, 1. Final
   */
  generateCircledGroup = (collection, origin, slices, width, start, total, positions) => {
    const group = collection.slice(start, start + total);
    const angle = Math.PI / slices * 2;
    const radius = width / 2;
    const [pos0, pos1] = positions;

    const difference = pos1 - pos0;
    const increment = difference / (total - 1);

    return group.map((thumbnail, index) => <Thumbnail 
      {...thumbnail} 
      childId={this.props.id}
      key={index}
      index={pos0 + index * increment}
      sectionRect={this.props.sectionRect}
      origin={origin} 
      radius={radius} 
      angle={angle} />)
  }

  //TODO: get positions dynamically based on a 12 slices circle
  getPositions = (origin, radius, angle) => {
    const {top: peTop} = this.props.parentElement.getBoundingClientRect();
    let pos0,pos1;
    const height = window.innerHeight/100*8;
    const boundaryTop = -peTop + height / 2;
    const boundaryBottom = window.innerHeight - peTop - height * 3;
    for(var i = 10; i < 14; i+=0.1){
      const  pos = origin.y + radius * Math.sin(i * angle) - height;
      console.log(pos, boundaryTop, boundaryBottom);
      if(!pos0 && pos >= boundaryTop - 10 && pos <= boundaryTop + 10){
        pos0 = i;
      }
      if(!pos1 && pos >= boundaryBottom - 10 && pos <= boundaryBottom + 10){
        pos1 = i;
      }
      if(pos0 && pos1) break;
    }
    return {pos0, pos1};
  }

  render(){
    if(this.props.children){
      const {top: peTop, left: peLeft} = this.props.parentElement.getBoundingClientRect();
      const {top: ciTop, left: ciLeft, width: ciWidth, height: ciHeight} = this.props.childImageElement.getBoundingClientRect();
      
      const origin = {
        x: ciLeft + ciWidth / 2 - peLeft,
        y: ciTop + ciHeight / 2 - peTop
      };
      
      const thumbnails = [{
        title: Translate.get('newSeenapse', 'New Seenapse'),
        path: `/${this.props.alias}/add`,
        background: `url(${newSeenapse})`
      }, ...this.props.children.data.map(child => {
        return {
          title: child.name,
          path: `${this.props.pathname.split('/').slice(0,-2).join('/')}/${this.props.alias}/${child.alias}`,
          background: `url(${IMAGES_BUCKET}${child.img})`
        }
      })];
      const rows = [
        {radius: 2.75, start: 0, itemsNum: 6, portrait: [-.25,6.25], landscape: [8.75,15.25]},
        {radius: 4.5, start: 7, itemsNum: 4, portrait: [2,4], landscape: [10.8,13.1]},
        {radius: 6.2, start: 12, itemsNum: 4, portrait: [2.3,3.7], landscape: [11.2,12.8]},
        {radius: 8, start: 17, itemsNum: 4, portrait: [2.4,3.6], landscape: [11.3,12.6]},
        {radius: 9.7, start: 22, itemsNum: 4, portrait: [2.5,3.5], landscape: [11.5,12.5]},
      ]
      return <aside>
        {rows.map(row => {
          return this.generateCircledGroup(thumbnails, origin, 12, 
            ciWidth * row.radius, row.start, row.itemsNum, 
            this.props.isPortrait ? row.portrait : row.landscape);
        })}
      </aside>
    }else{
      return null;
    }
  }
}