import React from 'react';
import ImageCasual from '../../../assests/img/seenapse/others/casual.png';
import ImagePersonal from '../../../assests/img/seenapse/others/1.png';
import ImageSmall from '../../../assests/img/seenapse/others/2.png';
import ImageBig from '../../../assests/img/seenapse/others/3.png';
import Translate from '../../../utils/Translate';

export function CasualComponent({ amount }) {
  return (
    <div className="subscription-col col-12 col-md-6 col-xl-3">
      <img className="casual" src={ImageCasual} alt="casual" style={{ paddingTop: 50 }} />
      <h2>Casual</h2>
      <div className="subscription-ctn">
        <p className="paragraph paragraph-sm text-cursive">
          {Translate.get('casualInformationHelp', 'When you only need help')}
        </p>
        <p className="paragraph paragraph-sm mb-4 text-cursive">
          {Translate.get('casualInformationWeek', 'a couple of times a week')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('casualInformationAccount', 'Individual account')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('casualInformationRequest', '50 requests per month')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('casualInformationLanguages', 'Languages: 40+')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('casualInformationIntegrations', 'Integrations: None')}
        </p>
      </div>
      <a
        href="https://pay.seenapse.ai/b/6oEg0Qeks8WUc5WfZc"
        type="button"
        className="btn btn-primary"
        rel="noopener noreferrer"
        target="_blank"
      >
        {amount}
      </a>
    </div>
  );
}

export function PersonalComponent({ amount }) {
  return (
    <div className="subscription-col col-12 col-md-6 col-xl-3">
      <img className="personal" src={ImagePersonal} alt="" />
      <h2>Personal</h2>
      <div className="subscription-ctn">
        <p className="paragraph paragraph-sm text-cursive">
          {Translate.get('personalInformationPartner', 'Your creative partner,')}
        </p>
        <p className="paragraph paragraph-sm mb-4 text-cursive">
          {Translate.get('personalInformationDay', 'day in, day out')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('personalInformationAccount', 'Individual account')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('personalInformationRequest', '250 requests per month')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('personalInformationLanguages', 'Languages: 40+')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('personalInformationIntegrations', 'Integrations: None')}
        </p>
      </div>
      <a
        href="https://pay.seenapse.ai/b/7sIbKA3FO6OMgmc7sI"
        type="button"
        className="btn btn-primary"
        rel="noopener noreferrer"
        target="_blank"
      >
        {amount}
      </a>
    </div>
  );
}

export function SmallTeamsComponent({ amount }) {
  return (
    <div className="subscription-col col-12 col-md-6 col-xl-3">
      <img className="small" src={ImageSmall} alt="" />
      <h2>Small teams</h2>
      <div className="subscription-ctn">
        <p className="paragraph paragraph-sm text-cursive">
          {Translate.get('smallInformationIdeal', 'Ideal for boutique')}
        </p>
        <p className="paragraph paragraph-sm mb-4 text-cursive">
          {Translate.get('smallInformationAgencies', 'agencies/consultancies')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('smallInformationUsers', 'Up to 10 users')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('smallInformationRequest', 'Unlimited requests')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('smallInformationLanguages', 'Languages: 40+')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('smallInformationIntegrations', 'Integrations: G Docs')}
        </p>
      </div>
      <a
        href="https://pay.seenapse.ai/b/4gw7ukfowgpm4Du6oD"
        type="button"
        className="btn btn-primary"
        rel="noopener noreferrer"
        target="_blank"
      >
        {amount}
      </a>
    </div>
  );
}

export function BigTeamsComponent() {
  return (
    <div className="subscription-col col-12 col-md-6 col-xl-3">
      <img className="big-teams" src={ImageBig} alt="" />
      <h2>Big teams</h2>
      <div className="subscription-ctn mb-2">
        <p className="paragraph paragraph-sm text-cursive">
          {Translate.get('teamsInformationCompany', 'Large company?')}
        </p>
        <p className="paragraph paragraph-sm mb-4 text-cursive">
          {Translate.get('teamsInformationEnterprise', 'We’re enterprise-ready!')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('teamsInformationUsers', 'Unlimited users')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('teamsInformationRequest', 'Unlimited requests')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('teamsInformationLanguages', 'Languages: 40+')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('teamsInformationCustom', 'Custom functionality')}
        </p>
        <p className="paragraph paragraph-sm">
          {Translate.get('teamsInformationIntegrations', '& integrations')}
        </p>
      </div>
      <a
        type="button"
        href="mailto:contact@seenapse.ai"
        className="btn btn-primary"
      >
        {Translate.get('footerContact', 'Contact')}
      </a>
    </div>
  );
}
