import React, { Component } from 'react'
import { TweenLite } from 'gsap'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'

import './Thumbnail.css'
import User from '../../models/UserStore'

import withLoadingBackground from '../../utils/withLoadingBackground'

class Thumbnail extends Component {
  state = {
    position: 'relative',
    left: 0,
    top: 0
  }

  thumbnail = null

  componentDidMount() {
    if (this.props.radius) {
      this.setPositions()
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.radius) {
      this.setPositions(prevProps)
    }
  }

  validateRoute = path => {
    const { history } = this.props
    if (path.includes('/add')) {
      if (!User.getUid()) {
        this.props.modalStore.openMessageLogin()
      } else {
        history.push(path)
      }
    } else {
      history.push(path)
    }
  }

  setPositions = (prev = {}) => {
    const { index, angle, origin, radius } = this.props
    const thumbnailBox = this.thumbnail.getBoundingClientRect()
    const initialLeft = origin.x - thumbnailBox.width / 2
    const initialTop = origin.y - thumbnailBox.height / 2
    const left = initialLeft + radius * Math.cos(index * angle)
    const top = initialTop + radius * Math.sin(index * angle)
    if (
      (initialLeft !== left && initialTop !== top) ||
      this.props.childId !== prev.childId
    ) {
      TweenLite.set(this.thumbnail, {
        position: 'absolute',
        left: initialLeft,
        top: initialTop
      })
      TweenLite.to(this.thumbnail, 0.3, { css: { left, top }, delay: 0.5 })
    }
  }
  render() {
    const {
      index,
      isGray,
      title,
      path,
      background,
      backgroundColor,
      disabledOnclick,
    } = this.props
    let classList = ['thumbnail']
    let thumbnailClassList = ['thumbnail_link']
    if (index <= 12) {
      classList.push('column')
    }
    if (isGray) {
      thumbnailClassList.push('no-border')
    }
    const sectionHeight = this.props.sectionRect
      ? this.props.sectionRect.height
      : this.props.height
    return (
      <div
        className={classList.join(' ')}
        ref={el => (this.thumbnail = el)}
        style={this.state}
      >
        <p
          style={{ fontSize: Math.max((sectionHeight * 12) / 512, 7) || 0 }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          role='button'
          onClick={disabledOnclick ? null: () => this.validateRoute(path)}
          className={thumbnailClassList.join(' ')}
          style={{ backgroundColor, backgroundImage: background }}
        />
      </div>
    )
  }
}

const withRouterThumbnail = withRouter(Thumbnail)
export default inject('modalStore')(withLoadingBackground(withRouterThumbnail))
