import Storage from 'localStorage';
/*
 *  import Translate from './../../utils/Translate';
 *   {Translate.get('', '')}
 */
export const translate = {
  es: {
    of: 'de',
    helloWhat: '¡Hola! ¿Qué te gustaría hacer hoy?',
    findInspiration: 'Encontrar inspiración',
    ifYouDont: 'Si ya tienes una cuenta de Seenapse',
    Search: 'Buscar',
    search: 'buscar',
    logIn: 'INGRESA AQUÍ',
    logInMobile: 'ingresa aquí',
    signUP: 'REGISTRARSE',
    logInWith: 'REGISTRARSE',
    logInTo: 'Inicia sesión en Seenapse',
    CreateASeenapseAcc:
      'Crea una cuenta de Seenapse con alguna de estas opciones:',
    Showing: 'Mostrando',
    closeMatches: 'cercanos',
    aproximateMatches: 'aproximados',
    ShowInstead: 'Mostar otros resultados',
    DontHaveAccount:
      '¿No tienes cuenta? Regístrate, es gratis y solo lleva un minuto.',
    DontHaveAccountYet: '¿No tienes una cuenta aún?',
    signUpWith: 'regístrate con',
    signUpNow: 'registrate ahora',
    bySigning: 'Al registrarse, indica que ha leído y acepta los ',
    itsFree: 'es gratis, solo tomará unos minutos',
    acceptTerms:
      'Al registrarte confirmas que leíste y aceptaste nuestros términos y condiciones.',
    yourEmail: 'Tu email',
    confirmEmail: 'Confirma tu email',
    password: 'Contraseña',
    newPassword: 'Nueva contraseña',
    passwordConfirm: 'Confirma tu contraseña',
    forgotYourPassword: '¿OLVIDASTE TU CONTRASEÑA?',
    name: 'nombre',
    yourProfession: 'ocupación',
    countryLived: 'País en el que has vivido más tiempo',
    country: 'país',
    bio: 'Bio',
    profilePic: 'foto',
    uploadPhoto: 'subir foto',
    fieldsRequired: 'Estos datos son requeridos',
    cancel: 'cancelar',
    register: 'Registrarse',
    about: 'ACERCA',
    whatIs: '¿Qué es Seenapse?',
    privacyPolicy: 'Política de Privacidad',
    terms: 'Términos y condiciones',
    searchAndNavigate:
      'Busca y navega a través de las asociaciones mentales de personas diversas en todo el mundo',
    shareInspiration: 'Compartir inspiración',
    helpOtherSeenapsers:
      'Ayuda a otros seenapsers a pelotear ideas contribuyendo tus asociaciones mentales a estas seenapses, y ',
    earnCredits: 'gana créditos',
    HowToUseSeenapse: 'Cómo usar Seenapse',
    whatIsSeenapseAndHow: ' y cómo sacarle el mayor provecho',
    whatIsSeenapse: 'Qué es Seenapse',
    account: 'CUENTA',
    notifications: 'NOTIFICACIONES',
    signOut: 'SALIR',
    favorites: 'favoritos',
    seenapseFavorited: 'Seenapses marcadas como favoritas',
    followers: 'seguidores',
    followees: 'seguidos',
    favorited: 'favorito',
    followed: 'seguido',
    follow: 'SEGUIR',
    unfollow: 'DEJAR DE SEGUIR',
    seenapsed: 'seenapsed',
    their: 'sus',
    editPrefs: 'editar preferencias',
    rank: 'Nivel',
    newbie: 'Novato',
    adept: 'Aficionado',
    skiled: 'Experimentado',
    expert: 'Experto',
    master: 'Maestro',
    chageProfilePic: 'cambiar avatar',
    uploadPhoto: 'subir imagen',
    remove: 'eliminar',
    removeCurrentImage: 'Quitar imagen actual',
    mailNotificationPrefs: 'Preferencias de notificaciones',
    asTheyHappen: 'al instante',
    newFollowers: 'Nuevos seguidores',
    seenapsesConnected: 'Seenapses conectadas a las tuyas',
    newSeenapsesFromFollowees: 'Nuevas seenapses de quienes sigues',
    faves: 'Favoritos',
    brainstormingReqs: 'Solicitudes de brainstorming',
    languagePreferences: 'Preferencias de idioma',
    iWantUseSeenapseIn: 'Quiero usar Seenapse en',
    savePreferences: 'guardar preferencias',
    informationWasUpdated: 'Tus datos fueron actualizados :)',
    dangerZone: 'Zona de peligro',
    deleteMyAccount: 'borrar mi cuenta',
    deleteAccountInfo:
      'Advertencia: esta acción es irreversible. Borrar tu cuenta eliminará todos tus datos personales de nuestros servidores. Si has hecho seenapses, su autoría será acreditada a “un seenapser”. ¿Estás seguro que deseas continuar?',
    yesDeleteMyAccount: 'Sí, por favor borren mi cuenta.',
    noDeletedKeep: 'No, sigamos seenapseando',
    accountHasDeleted: 'Tu cuenta ha sido eliminada. Ya te extrañamos :(',
    share: 'compartir',
    edit: 'editar',
    fave: 'marcar como favorito',
    delete: 'borrar',
    faves: 'favoritos',
    deleteSeenapse:
      '¿Estás seguro de que quieres borrar esta seenapse? Esta acción es irreversible.',
    yesDeleteIt: 'Sí, quiero borrarla',
    noItsCool: 'No, está bien',
    seenapseCannotDeleted:
      'Esta seenapse no puede borrarse porque otras personas ya le conectaron nuevas seenapses. No querrías dejarlas sueltas, ¿verdad?',
    noOfCourse: 'No, claro',
    newSeenapse: 'Nueva Seenapse',
    nameSeenapseValidation: 'Ponle nombre a tu seenapse (máximo 20 caracteres)',
    describeSeenapseIn:
      'Cuéntanos por qué asociaste una cosa con la otra, en menos de 350 caracteres',
    typePasteUrl:
      'Escribe o pega la URL a la que quieres que tu seenapse haga referencia',
    publish: 'publicar',
    publishing: 'publicando ...',
    exactMatches: 'Resultados',
    approximateMatches: 'Resultados Aproximados',
    people: 'personas',
    youNeedLogin:
      'Necesitas ingresar para hacer eso. Si no tienes una cuenta de Seenapse, regístrate. Es gratis y solo toma unos segundos.',
    errEmailValid: 'Por favor ingresa un email válido',
    errPassConfirmation: 'Por favor ingresa confirmación de tu password',
    errPass: 'Por favor ingresa una contraseña válida',
    errName: 'Por favor ingresa un nombre válido',
    errProfession: 'Por favor ingresa una profesión',
    errCountry: 'Por favor ingresa un país',
    errAvatar: 'Por favor ingresa un avatar válido',
    errInfoFields: 'Por favor revisa lo siguiente',
    errBio: 'Por favor ingresa una bio',
    almostTitle: '¡Listo!',
    almostText:
      'Sólo checa por favor en tu cuenta de correo el mensaje que te enviamos para confirmar y activar tu cuenta :)',
    aboutTitle: '¡Qué bueno que preguntas!',
    aboutSeenapseIs:
      'Seenapse es un motor de inspiración, que te ayuda a tener más y mejores ideas, más rápido.',
    aboutYouCanUseSeenapse:
      'Puedes usar Seenapse cuando estás un poco atorado, o con prisa, o simplemente cuando quieres beneficiarte de tener otros puntos de vista sobre la tarea creativa en la que estás trabajando. Que, en nuestra experiencia, es casi siempre.',
    aboutIts: 'Es como pelotear ideas con el mundo.',
    aboutHowToUseSeenapse: 'Cómo usar Seenapse',
    aboutUsingSeenapse:
      'Es muy fácil: digamos que estás trabajando en un proyecto relacionado con café, por ejemplo. Solo escribe “café” en el campo de búsqueda y tendrás muchos resultados que te llevarán por caminos de pensamiento diferentes a los habituales. Estos resultados son asociaciones mentales de otros usuarios de Seenapse alrededor del mundo.',
    aboutTheMost:
      'Lo más importante es esto: lo que encontrarás en Seenapse no son soluciones, sino cosas que pueden detonar la solución, la idea, en tu cabeza. Si ves los resultados como algo literal, no te servirá. Tienes que preguntarte “¿qué relación tiene esto con mi tarea?” De ese cuestionamiento es de donde surgirán las ideas.',
    aboutAlsoWhenever:
      'Ah, y cada vez que veas una seenapse y pienses “esto me recuerda a otra cosa”, ¡conéctala! Al sumar tus asociaciones mentales estarás ayudándoles a otros usuarios. Y cuando ellos conecten nuevas asociaciones a las tuyas, llevarán tu pensamiento en más nuevas direcciones. Todos ganamos.',
    aboutPleaseFeel:
      'Contáctanos cuando quieras para decirnos tus dudas, comentarios y sugerencias. También nos gusta mucho leer sobre cómo usan Seenapse en su trabajo. Escríbenos a ',
    aboutCheers: '¡Gracias!',
    aboutTheSeenapseTeam: 'El equipo Seenapse',
    yourAssociation: 'Tu asociación',
    errEmailTaken:
      'Esa cuenta de email ya tiene una cuenta de Seenapse asociada.',
    errPasswordConfirmation: 'Las contraseñas ingresadas no coinciden.',
    errMessageLogin:
      'Necesitas iniciar sesión para hacer esta acción. ¿Aún no tienes una cuenta? ¡Regístrate, es gratis y solo te llevará un segundo!',
    errCreateAccount: 'Hubo un error al crear tu cuenta',
    close: 'cerrar',
    resetYourPassword: 'RECUPERAR CONTRASEÑA',
    rememberPasswordSuccessInstructions: 'Instrucciones',
    rememberPasswordSuccessText:
      'Checa por favor en tu cuenta de correo el mensaje que te enviamos para recuperar tu password :)',
    errPassNotMatch: 'Las contraseñas no coinciden',
    writeYourTitle: 'Escribe el título de tu seenapse aquí',
    describeYourSeenapse: 'Describe tu seenapse en 350 caracteres o menos',
    urlReference: 'Teclea o pega aquí la URL a la que quieras hacer referencia',
    urlBad: 'No pudimos obtener imágenes de esta liga',
    publish: 'Publicar',
    cancel: 'Cancelar',
    follow: 'SEGUIR',
    unFollow: 'DEJAR DE SEGUIR',
    messageSeenapses: 'Mensaje de los socios de Seenapse',
    noResults: 'No tienes notificaciones en este momento :)',
    errImageDimension: 'Por favor añade una imagen menor a 200x200 pixels.',
    editYourAccount: 'Edita tu cuenta',
    errLogin: 'Email ó contraseña incorrectos',
    createOther: 'GENERAR MÁS',
    privacyIdea:
      '* No te preocupes, no almacenaremos esta información de forma permanente, sólo se usará durante esta sesión. Se eliminará automáticamente después. Consulta nuestra Política de Privacidad.',
    ideasTitle: 'IDEAS GENERADAS POR I.A. E INSPIRADAS POR:',
    someInfo: 'NECESITAMOS ALGUNOS DATOS:',
    placeHolderSomeInfo: 'Escribe el nombre de tu marca o producto',
    whatisit: '¿QUÉ ES?',
    placeholderWhatIsIt: 'e.g. un auto, tintorería, comida, etc.',
    placeholderAttributes: 'Palabras clave que lo definen',
    keyattributes: '¿Cuáles son sus atributos?',
    occasion: '¿Cuál es la ocasión?',
    placeholderOcassion: 'e.g. lanzamiento, temporada, promo, etc.',
    go: 'Generar ideas',
    generateIdeas: 'Tu Brief',
    getHundredsOfIdeas: 'Llena este formulario para generar tus ideas:',
    getHundreds: '¡Pero no te desesperes! Puedes elegir uno de los planes a continuación y continuar justo donde lo dejaste.',
    protagonist: 'Protagonista',
    placeHolderProtagonist: 'Escribe la marca, nombre',
    typeOfIdea: 'Tipo de ideas',
    concepts: 'Conceptos o puntas',
    naming: 'Nombres (naming)',
    taglines: 'Lemas (taglines)',
    lines: 'Hero content',
    headline: 'Titulares (cabezas)',
    low: 'Bajo',
    medium: 'Medio',
    high: 'Alto',
    thisAreRecommendedSeenapses:
      'Éstas son las seenapses que recomendamos para generar tus ideas con un grado de divergencia',
    youCanAddOrRemoveSeenapses:
      'También puedes quitar o agregar seenapses manualmente, haciendo clic en la que quieras y apagando o prendiendo el icono',
    onEachSeenapse: ' en cada seenapse.',
    whenYouClickTheButtonItWill:
      'Al hacer clic en el botón, generaremos para ti',
    beforeGeneratingIdeas: 'Antes de generarlas…',
    whichWillSubtract: 'lo que le restará ',
    credits: 'créditos ',
    fromYourBalance: 'a tu saldo de ',
    now: 'Ahora sí,',
    copyIdeas: 'Copiar ideas',
    ideasInspiredByHumans:
      ' ideas inspiradas por humanos y generadas por inteligencia artificial',
    subscribeIdeas: '',
    trial: '',
    socialpost: 'Post de social (parrilla)',
    product: 'Producto',
    videoScript: 'Guiones de video',
    brainstorm: 'Pelotear ideas',
    howUnexpected: '¿CUÁN INESPERADAS TE GUSTARÍA QUE SEAN TUS IDEAS?',
    aLittleBit: 'Un poco',
    quite: 'Bastante',
    very: 'Mucho',
    problem: 'Problema',
    placeHolderProblem: '¿Qué problema necesita resolver el producto?',
    yourIdea: 'Tu idea',
    tone: '¿CUÁL ES EL TONO DE VOZ?',
    placeHolderTone: 'e.g. Corporativo, conocedor, desenfadado, etc.',
    concept: '¿CUÁL ES EL CONCEPTO DE CAMPAÑA?',
    placeholderConcept: 'La gran idea o concepto de la marca',
    pillar: '¿QUÉ ES EL PILAR DE LA COMUNICACIÓN?',
    placeholderPillar: 'El pilar al que pertenece este post',
    topic: '¿Cual es el tópico?',
    placeholderTopic: 'Ej. estacionalidad, trending topic, etc.',
    language: 'LENGUAJE',
    mode: 'MODO',
    chat: 'Chat',
    forms: 'Formulario',
    en: 'Inglés',
    es: 'Español',
    yourIdeas: 'Tus ideas',
    theBestWay: 'La mejor forma de tener una buena idea es tener muchas ideas.',
    testDifferent:
      '¡Prueba con diferentes atributos, y copia y ajusta lo que te guste!',
    alertCopy:
      'Asegúrate de copiar y pegar las ideas en un documento, porque una vez que cierres la ventana, no las verás de nuevo.',
    landingTitle: 'Meet your new creative partner!',
    landingSubtitle:
      'Seenapse is a unique combination of human lateral thinking and AI’s speed that allows you to generate hundreds of divergent, creative possibilities in minutes.',
    landingButton: 'Try it for free now',
    landingInteresting: 'Great ideas, not just so-so',
    landingSurprising:
      'You know that ChatGPT and the rest generate ideas that are kind of obvious. Only Seenapse gives you interesting, surprising ideas that point to many different creative directions, inspired by humans, powered by AI.',
    landingDirector: 'You are the Creative Director',
    landingProposeTitle: 'You are the Creative Director',
    landingPropose:
      'Let Seenapse quickly propose dozens of divergent possibilities, from which you can spot those ugly ducklings you know you can turn into beautiful swans.',
    landingIssue: '99 problems but a pitch ain’t one',
    landingBusiness:
      'New business pitches are a big source of stress: you have to come up with great ideas in a short time — and in addition to your regular work! Dial the stress way down with Seenapse.',
    landingTeams: 'Inspiring teams at',
    landingFreelancers: 'Plus thousands of freelancers around the world!',
    landingWorks: 'How it works',
    landingPlans: 'Plans & pricing',
    landingPersonal: 'Personal',
    landingAccount: ' Individual account',
    landingMounth: '250 ideas per month',
    landinLanguage: 'Languages: English',
    landinNone: 'Integrations: None',
    landingPrice: '$25 / MONTH',
    landingPriceEUR: '25 € / MONTH',
    landingSmall: 'Small Teams',
    landingUser: 'Up to 20 users',
    landingIdeas: 'Unlimited ideas per month',
    landingSpanish: 'Languages: English/Spanish',
    landingDocs: 'Integrations: Google Docs',
    landingFive: '$500 / month',
    landingFiveEUR: '500 € / MONTH',
    landingBig: 'Big teams',
    landingUnlimit: 'Unlimited users',
    landingPerMounth: 'Unlimited ideas per month',
    landingAny: 'Languages: Any',
    landingIntegrate: 'Custom functionality & integrations',
    landingContact: 'Contact us',
    footerAbout: 'About us',
    footerFaq: 'FAQ',
    footerCareers: 'Careers',
    footerPrivacy: 'Políticas de privacidad',
    footerTerms: 'Terms & Conditions',
    footerContact: 'Contact',
    projectTitle: 'Nombre del proyecto',
    newProject: 'Nuevo proyecto',
    addProject: 'Agregar proyecto',
    sendProject: 'Crear proyecto',
    yourProjects: 'Tus proyectos',
    recentDeleted: 'Borrados recientemente',
    messageError520:
      'Tuve un problema, dame un minuto para responder, por favor.',
    manageSubscription: 'Maneja tu suscripción',
    climateTitle: 'Brainstorming for Climate Solutions',
    climateInformationIdeas: 'La crisis climática exige ideas tan diversas y grandes como el mundo que compartimos. En Seenapse queremos contribuir haciendo lo que mejor sabemos hacer: ayudar a la gente a tener ideas innovadoras.',
    climateInformationInnovation: 'Estamos dando acceso gratuito a Seenapse a las ONG dedicadas al clima y a sus agencias colaboradoras, para ayudarles a replantearse productos, rediseñar procesos, crear campañas de sensibilización convincentes, encontrar nuevas formas de responsabilizar a gobiernos y empresas, o ampliar los límites de la innovación sostenible.',
    climateInformationApply: 'Solicita aquí tu participación en nuestro programa Brainstorming for Climate Solutions:',
    climateName: 'NOMBRE',
    climateWorkEmail: 'EMAIL LABORAL',
    climateButtonApply: 'APLICAR',
    climateWebsite: 'WEBSITE DE LA ONG',
    climateInformationMessage: '¡Gracias por aplicar!',
    climateSoonMessage: 'Estaremos en contacto contigo pronto.',
    nasa: 'Foto cortesía de la',
    casualInformationHelp: 'Cuando solo necesitas ayuda',
    casualInformationWeek: 'un par de veces a la semana',
    casualInformationAccount: 'Cuenta individual',
    casualInformationRequest: '50 solicitudes por mes',
    casualInformationLanguages: 'Idiomas: 40+',
    casualInformationIntegrations: 'Integraciones: Ninguna',
    personalInformationPartner: 'Tu compañero creativo,',
    personalInformationDay: 'día tras día',
    personalInformationAccount: 'Cuenta individual',
    personalInformationRequest: '250 solicitudes por mes',
    personalInformationLanguages: 'Idiomas: 40+',
    personalInformationIntegrations: 'Integraciones: Ninguna',
    smallInformationIdeal: 'Ideal para boutique',
    smallInformationAgencies: 'agencias/consultorías',
    smallInformationUsers: 'Hasta 10 usuarios',
    smallInformationRequest: 'Solicitudes ilimitadas',
    smallInformationLanguages: 'Idiomas: 40+',
    smallInformationIntegrations: 'Integraciones: Google Docs',
    teamsInformationCompany: '¿Empresa grande?',
    teamsInformationEnterprise: '¡Estamos listos para empresas!',
    teamsInformationUsers: 'Usuarios ilimitados',
    teamsInformationRequest: 'Solicitudes ilimitadas',
    teamsInformationLanguages: 'Idiomas: 40+',
    teamsInformationCustom: 'Funcionalidad personalizada',
    teamsInformationIntegrations: 'e integraciones',
    pinnShareProject: 'Compatir proyecto',
    pinSaveButton: 'Enviar',
  },
  en: {
    landingFiveEUR: '500 € / MONTH',
    landingPriceEUR: '25 € / MONTH',

  },
};

export default class Translate {
  static get(name, defaultLabel) {
    const lang = Storage.getItem('lang') || 'en';
    return translate[lang][name] ? translate[lang][name] : defaultLabel;
  }
}
