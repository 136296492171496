import React from 'react'
import Canvas from './Canvas'
import Modal from '../modal/Modal'
import SeenapseGrandParent from './SeenapseGrandParent'
import SeenapseParent from './SeenapseParent'
import SeenapseForm from './SeenapseForm'
import SeenapseURLLoader from './SeenapseURLLoader'
import { sendSeenapse, updateSeenapse } from '../../services/seenapse'
import { withRouter } from 'react-router-dom'
import User from '../../models/UserStore'
import './Seenapse.css'
import { inject, observer } from 'mobx-react'

class AddSeenapse extends React.Component {
  state = {
    isPortrait: true,
    modalElement: null,
    sectionRect: { width: 0, height: 0 },
    form: {
      name: '',
      url: '',
      description: '',
      pid: 0,
      img: '',
      page_title: ''
    },
    error: null,
    loading: false
  }
  section = null
  container = null
  componentDidMount() {
    setTimeout(() => this.fixRatio(), 100)
    window.addEventListener('resize', this.fixRatio)
  }
  componentWillReceiveProps(nextProps) {
    const { parent, child } = nextProps
    if (child && parent) {
      this.updateForm({ alias: child.alias, id: child.id, pid: parent.id })
    } else if (parent) {
      this.updateFormField('pid', parent.id)
    }
  }
  updateForm = updated => {
    const form = { ...this.state.form, ...updated }
    this.setState({ form })
  }
  updateFormField = (field, value) => {
    const form = { ...this.state.form, [field]: value }
    this.setState({ form })
  }
  fixRatio = () => {
    if (this.state.modalElement) {
      const isPortrait = this.modalIsPortrait(this.state.modalElement)
      this.setState({ isPortrait })
    }
    if (this.section) {
      const { width, height } = this.section.getBoundingClientRect()
      this.setState({ sectionRect: { width, height } })
    }
  }
  setModalElement = el => {
    const isPortrait = this.modalIsPortrait(el)
    this.setState({ modalElement: el, isPortrait })
  }
  modalIsPortrait = el => {
    if (el) {
      const { width, height } = el.getBoundingClientRect()
      return width <= height
    }
  }
  publishSeenapse = () => {
    if (!this.state.form.name) {
      this.setState({ error: 'You should provide a title' })
      return
    }
    if (!this.state.form.description) {
      this.setState({ error: 'You should provide a description' })
      return
    }
    if (!this.state.form.url) {
      this.setState({ error: 'You should provide a url' })
      return
    }
    this.setState({ loading: true, error: null })
    const { isNew } = this.props
    if (isNew) {
      sendSeenapse(this.state.form).then(res => {
        this.setState({ loading: false })
        this.props.history.push(`/${this.props.parent.alias}/${res.data.alias}`)
      })
    } else {
      updateSeenapse(this.state.form).then(res => {
        this.setState({ loading: false })
        this.props.history.push(`/${this.props.parent.alias}/${res.data.alias}`)
      })
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.fixRatio)
  }
  render() {
    const { parent, child } = this.props
    return (
      <Modal
        onModalAvailable={this.setModalElement}
        onClose={this.props.onClose}
        open={this.props.open}
        isPortrait={this.state.isPortrait}
      >
        <div ref={el => (this.container = el)} className='seenapse'>
          <SeenapseGrandParent
            isGray={true}
            pathname={this.props.location.pathname}
            height={this.state.sectionRect.height}
            {...parent}
          />
          <section ref={el => (this.section = el)}>
            <Canvas
              isGray={true}
              sectionRect={this.state.sectionRect}
              isPortrait={this.state.isPortrait}
            />
            <SeenapseParent index={1} {...parent} />
            <SeenapseForm
              parent={parent}
              child={child}
              loading={this.state.loading}
              error={this.state.error}
              height={this.state.sectionRect.height}
              updateField={this.updateFormField}
              updateForm={this.updateForm}
              publishSeenapse={this.publishSeenapse}
            />
            <SeenapseURLLoader
              height={this.state.sectionRect.height}
              child={child}
              updateField={this.updateFormField}
              updateForm={this.updateForm}
            />
          </section>
        </div>
      </Modal>
    )
  }
}

export default inject('modalStore')(withRouter(AddSeenapse))
