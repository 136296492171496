import React from 'react';
import "./Alert.css"
import Translate from './../../utils/Translate';

export default function RememberPasswordSuccess(props){
  if (props.open) {
    return <div className="lb on" onClick={props.onOk}>
      <div className="message almost">
        <div className="content">
            <div className="title">{Translate.get('rememberPasswordSuccessInstructions', 'Instructions')}</div>
            {Translate.get('rememberPasswordSuccessText','Please check your email inbox for our message and click on the link provided to change your password :)')}
          </div>
      </div>
    </div>
  }
  return <div/>;
}