import React,{ Fragment,Component } from 'react';
import AlgoliaStore from '../models/AlgoliaStore';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import Loader from '../components/loader'
import SeenapseResults from './SeenapseResults';
import { isInSummaryModal } from '../utils/Utils';
import withLadingBackground from '../utils/withLoadingBackground';
import { sendMetric } from '../services/mixpanel';
import Detail from './Detail';
import './SearchPages.css';
import Translate from '../utils/Translate';

const PAGE_SIZE = 150;
const PATH_IMAGE = 'https://4a1e70ba250472b18020-710c152fdea160d64661dc05136089d3.ssl.cf1.rackcdn.com/';
const linksMessages = [
  {
    id:'matches',
    text: Translate.get('closeMatches', 'close matches')
  },
  {
    id:'approximate',
    text: Translate.get('aproximateMatches', 'aproximate matches')
  },
  {
    id:'people',
    text: Translate.get('people', 'people')
  }
];

const getCategoryIndex = (category) => {
  switch(category){
    case 'matches':
      return 0;
    case 'approximate':
      return 1;
    case 'people':
      return 2;
    default:
      return 0;
  }
}

class SearchPage extends Component {
  state = {
    matches: null,
    approximate: null,
    people: null,
    isOpen: false,
    pages:{
      matches:0,
      approximate:0,
      people:0,
    },
    totalPages:{
      matches:1,
      approximate:1,
      people:1,
    },
    selected: this.props.match.params.type || 'matches',
    bodyTop: 0
  };

  resetState() {
    this.setState({
      matches: null,
      approximate: null,
      people: null,
      pages:{
        matches:0,
        approximate:0,
        people:0,
      },
      totalPages:{
        matches:1,
        approximate:1,
        people:1,
      },
      selected: this.props.match.params.type || 'matches',
    })
  }

  componentWillReceiveProps(nextProps){
    if(!!nextProps.match.params.parent && !!nextProps.match.params.child){
      this.props.modalStore.addBlurOnHeader();
      this.setState({bodyTop: document.querySelector('body').getBoundingClientRect().y, isOpen: true});
    }else{
      this.props.modalStore.removeBlur();
      this.setState({isOpen: false});
    }
    if (
      nextProps.match.params.type &&
      nextProps.match.params.type !== this.state.selected
    ) {
      this.fetchElements();

      this.setState({
        selected: nextProps.match.params.type,
      },() => {
        this.setState({
          currentFilter: linksMessages.filter( linkMessage =>
            linkMessage.id === this.state.selected
          ),
          availableFilter: linksMessages.filter( linkMessage =>
            linkMessage.id !== this.state.selected
          )
        })
      })
    }
  }

  componentDidMount() {
    const pathName = this.props.location.pathname;
    this.fetchElements();
    if(isInSummaryModal(pathName)){
      this.props.modalStore.addBlurOnHeader();
      this.props.modalStore.addBlur();
      this.setState({isOpen: true});
    }
  }

  componentDidUpdate(){
    if(!!this.props.match.params.parent && !!this.props.match.params.child && this.props.modalStore.blur === '0'){
      this.props.modalStore.addBlurOnHeader();
      this.props.modalStore.addBlur();
    }
  }

  fetchElements() {
    this.resetState()
    AlgoliaStore.hits(
      this.props.match.params.query,
      this.state.pages,
      PAGE_SIZE,
    (e, result) => {
      const matches = result.results[0];
      const approximate = result.results[1];
      const people = result.results[2];
      const data = {
        search: this.props.match.params.query,
        exact: result.results[0].nbHits,
        approximate: result.results[1].nbHits,
        people: result.results[2].nbHits
      };
      sendMetric('Successful Search', data);

      this.setState({
         matches,
         approximate,
         people,
         totalPages:{
          matches:matches.nbPages,
          approximate:approximate.nbPages,
          people:people.nbPages,
         },
         currentFilter: linksMessages.filter( linkMessage =>
          linkMessage.id === this.state.selected
         ),
         availableFilter: linksMessages.filter( linkMessage =>
          linkMessage.id !== this.state.selected
         )
      })
    })
  }

  loadItems = (page) => {
    if (this.state.totalPages[this.state.selected] === page)
      return

    const { selected, pages } = this.state;
    pages[selected] += 1

    AlgoliaStore.hits(
      this.props.match.params.query,
      pages,
      PAGE_SIZE,
    (e, result) => {
      const index = getCategoryIndex(selected);
      const categoryResult = result.results[index];
      const newHits = categoryResult.hits;
      const currentHits = this.state[selected].hits;
      categoryResult.hits = [...currentHits, ...newHits];

      this.setState({
        [selected] : categoryResult,
        pages,
      })
    })
  }

  renderCounters = () => {
    const { matches:{query} } = this.state;
    const formatteQuery = query.replace(/\+/g, ' ');
    return(
      <div className="results-seenapses"
        style={{filter: `blur(${this.props.modalStore.blur})`}}>
        <p className="message-results">
          {Translate.get('Showing', 'Showing')} {this.state[this.state.currentFilter[0].id].nbHits}&nbsp;
          {this.state.currentFilter[0].text} for "{formatteQuery}"
        </p>
        <p className="message-results">
        {Translate.get('ShowInstead', 'Show instead')}&nbsp;
          <Link
            to={`/search/${this.state.availableFilter[0].id}/${query}`}
            className="link-seenapse underline">
            {this.state.availableFilter[0].text}
          </Link>
          &nbsp;({this.state[this.state.availableFilter[0].id].nbHits})
          &nbsp;or&nbsp;
          <Link
            to={`/search/${this.state.availableFilter[1].id}/${query}`}
            className="link-seenapse underline">
            {this.state.availableFilter[1].text}
          </Link>
          &nbsp;({this.state[this.state.availableFilter[1].id].nbHits})
        </p>
      </div>
    )
  }

  buildImageUrl(element) {
    return !element.img ? 'https://seenapse.ai/dist/img/avatar_default_v2.png': element.img.includes("avatar_default")
      ? `https://seenapse.ai/dist/img/${element.img}`: element.img.includes("htt")
      ? element.img : `${PATH_IMAGE}${element.img}`
  }

  buildPeopleResult(selected, element) {
    return (
      <Link
        key={element.id}
        to={`/user/${element.objectID}/seenapses`}
        className="link-seenapse underline"
      >
        <div className="link-underline search-results-people"
          key={`${selected}-${element.id}`}>
          <div className="picUs"
            style={{backgroundImage: `url(${this.buildImageUrl(element)})`}} />
          <div className="names-images">
            {element.name}
          </div>
        </div>
      </Link>
     )
  }

  /**
   * @function buildResults
   * @argument {string} selected
   * @description Itera sobre los seenapses devueltos
   * por algolia que ya están en el state.
   */
  buildResults = (selected) => {
    let hits = this.state[selected].hits;
      return hits.map((element, index) => {
        if (selected !== 'people') {
          return (
            <div className="search-results" key={`${selected}-${element.id}}`}>
                <SeenapseResults
                  seenapse={element}
                  index={index}
                  pathImage={PATH_IMAGE}
                  currentPath = {this.props.location.pathname}
                  />
            </div>
          )
        } else {
          return this.buildPeopleResult(selected, element)
        }
      })
  }

  renderInfiniteScroll = (selected) => {
    const { modalStore: { blur } } = this.props;
    return(
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadItems}
        threshold={250}
        hasMore={this.state.pages[selected] !== this.state.totalPages[selected]}
      >
        <section
          className="container-fluid container-search"
          style={{filter:`blur(${blur})`}}
        >
          {this.buildResults(selected)}
        </section>
      </InfiniteScroll>
    )
  }

  render() {
    const { matches, approximate, people, selected } = this.state;
    const { match, location:{ state } } = this.props;
    if(this.state.isOpen){
      document.querySelector('body').classList.add('locked');
      document.querySelector('body').style.top = `${this.state.bodyTop}px`;
    }else{
      document.querySelector('body').classList.remove('locked');
      document.querySelector('body').style.top = ``;
      if(this.state.bodyTop){
        window.scroll(0, -this.state.bodyTop);
        this.setState({
          bodyTop: 0
        });
      }
    }
    if (!matches && !approximate  && !people ) {
      return <Loader/>
    }
    return(
      <Fragment>
        <Fragment>
          {this.renderCounters()}
          {this.renderInfiniteScroll(selected)}
        </Fragment>
        {this.state.isOpen &&(
          <Detail
            open={this.state.isOpen}
            selected={selected}
            query={matches.query}
            parent={match.params.parent}
            child={match.params.child}
            isNewSeenapse={(state || {}).isNewSeenapse}
          />
        )}
      </Fragment>
    );
  }
}

export default inject(
  'modalStore'
)(withLadingBackground(observer(SearchPage)));
