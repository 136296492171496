import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { defaultAvatar } from '../../utils/Utils';
import './Avatar.css';
import avatarDefault from '../../assests/img/avatar-default.png';

const Avatar = ({
  user, onOpenModal, isOpenAvatarMenu, notification,
}) => (
  <Fragment>
    {!isOpenAvatarMenu && user && user.img && (
      <div className="content-avatar">
        <div role="button" onClick={() => onOpenModal('openAvatarMenu')}>
          <img
            src={user.img || avatarDefault}
            alt="user-avatar"
            onError={e => defaultAvatar(e, avatarDefault)}
          />
        </div>
          {notification > 0
        && (
        <span className="total-notifications">
          {notification}
        </span>
        )
          }
      </div>
    )}
    {isOpenAvatarMenu && user && user.img && (
      <div className="content-avatar content-user">
        <figure>
          <img
            src={user.img || avatarDefault}
            alt="user-avatar"
            onError={e => defaultAvatar(e, avatarDefault)}
          />
        </figure>
        <span className="text-item-modal user-name">{user.name}</span>
      </div>
    )}
  </Fragment>
);


export default inject('modalStore')(observer(Avatar));
