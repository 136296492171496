import React from 'react';
import Translate from '../../../utils/Translate';
import './styles.css';


export default function Footer() {
  return (
    <div className="climate-footer">
      <a href="/privacy" className="climate-footer-text climate-footer-divider">
        {Translate.get('footerPrivacy', 'Privacy Policy')}
      </a>
      <a href="/" className="climate-footer-text climate-footer-divider">@2023 Seenapse</a>
      <div className="footer-climate-nasa">
        <p className="climate-footer-text">
          {Translate.get(
            'nasa',
            'Blue marble photo courtesy of',
          )}
        </p>
        <a
          href="https://www.nasa.gov/image-detail/apollo-17-astronauts-capture-iconic-blue-marble-50-years-ago/"
          className="climate-footer-text p-0"
          target="_blank"
          rel="noopener noreferrer"
        >
NASA
        </a>
      </div>
    </div>
  );
}
