import { useState, useEffect } from 'react'

import User from '../models/UserStore'

const useCatalogs = () => {
  const [countries, setCountries] = useState([])
  const [professions, setProfessions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    User.getCatalogs()
      .then(({ data }) => {
        setCountries(data.countries)
        setProfessions(data.professions)
      })
      .catch(error => console.error(error))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { countries, professions, loading }
}
export default useCatalogs
