import { useState } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AddClimates } from '../../services/seenapse';

export const validationSchema = yup.object({
  name: yup.string().required('Required'),
  email: yup.string().required('Required'),
  website: yup.string().required('Required'),
});

export const initialValues = {
  name: '',
  email: '',
  website: '',
};

export default function useClimates() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleRedirect = () => {
    history.push('/climate/thanks');
  };

  const onSubmit = (values) => {
    setLoading(true);
    const data = {
      data: {
        name: values.name,
        email: values.email,
        website: values.website,
      },
    };
    AddClimates(data)
      .then(() => {
        handleRedirect();
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  };

  return { onSubmit, loading };
}
