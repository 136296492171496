import React from "react";
import "./Canvas.css";

export default class Canvas extends React.Component {
  componentWillReceiveProps(nextProps) {
    const {
      isPortrait,
      sectionRect: { width, height }
    } = this.props;
    if (width > 0 && height > 0 && nextProps.isPortrait !== isPortrait) {
      document.querySelector(".canvas").classList.add("transformTransition");
    }
  }
  render() {
    let classes = ["canvas"];
    if (this.props.isGray) classes.push("gray");
    const {
      sectionRect: { width, height },
      isPortrait
    } = this.props;

    if (isPortrait) {
      const fixedWidth = (1000 * height) / 1680;
      return (
        <div
          className={classes.join(" ")}
          style={{
            height,
            width: fixedWidth
          }}
        />
      );
    } else {
      const fixedHeight = (1000 * width) / 1680;
      return (
        <div
          className={classes.join(" ")}
          style={{
            height: width,
            width: fixedHeight
          }}
        />
      );
    }
  }
}
