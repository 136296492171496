import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import "./SignInEmail.css";
import ApiUser from '../../shared/login/ApiUser';
import RememberPasswordSuccess from '../../components/alerts/RememberPasswordSuccess';
import Translate from '../../utils/Translate';

class PasswordRequest extends Component {
  state = {
    email: "",
    password: "",
    description: "",
    showRememberSuccess: false
  };
  
  componentDidUpdate = () => {
    const { child } = this.props;
    if (child && !this.state.name) {
      const { name, description } = child;
      this.setState({
        name,
        description
      });
      this.props.updateForm({ name, description });
    }
  }

  closeModalRememberSuccess = () => {
    window.location = '/';
  }

  updateField = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  onSumit = (e) => {
    e.preventDefault();
    const {email} = this.state;
    ApiUser.rememberPass(email)
      .then((response) =>{
        this.setState({showRememberSuccess: true});
      });
  }

  render() {
    const {showRememberSuccess} = this.state;
    return <div>
      <RememberPasswordSuccess open={showRememberSuccess} onOk={this.closeModalRememberSuccess}/>
      <div class="header-spacer"></div>
        <div class="login-email-form">
        <form  id="formPassword">
            <fieldset>
            <div class="field">
                <label for=""><span class="theme-blue">></span> Email</label>
                <input type="email" id="email" name="email" onChange={(e) => {this.updateField('email', e.target.value)}} required />
            </div>
            </fieldset>
            <div class="action">
            <button id="sendRequest" onClick={this.onSumit} class="right">{Translate.get('resetYourPassword','RESET YOUR PASSWORD')}</button>
            </div>
        </form>
        </div>
    </div>
  }
}

export default inject("modalStore")(observer(withRouter(PasswordRequest)));
