import React from "react";
import { IMAGES_BUCKET } from "../../config";
import Thumbnail from "./Thumbnail";

export default function SeenapseGrandParent(props) {
  const path = props.pathname.includes("search")
    ? `${props.pathname
        .split("/")
        .slice(0, -2)
        .join("/")}/${props.palias}/${props.alias}`
    : `${props.pathname
        .split("/")
        .slice(0, 1)
        .join("/")}/${props.palias}/${props.alias}`;
  return (
    <header>
      <Thumbnail
        index={0}
        isGray={props.isGray}
        height={props.height}
        title={props.pname}
        path={path}
        background={`url(${IMAGES_BUCKET}${props.pimg})`}
      />
    </header>
  );
}
