import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import ImgPartner from '../../assests/img/seenapse/others/1.jpg';
import ImgInteresting from '../../assests/img/seenapse/others/2.jpg';
import ImgCreative from '../../assests/img/seenapse/others/3.jpg';
import ImgTeams1 from '../../assests/img/seenapse/others/board.jpg';
import ImgTeams2 from '../../assests/img/seenapse/others/t4.png';
import ImgTeams3 from '../../assests/img/seenapse/others/t5.png';
import ImgTeams4 from '../../assests/img/seenapse/others/montalvo.jpg';
import ImgTeams5 from '../../assests/img/seenapse/others/tec.jpg';
import ImgTeams6 from '../../assests/img/seenapse/others/vml.jpg';
import Translate from '../../utils/Translate';
import Comments from './comments';
import {
  BigTeamsComponent,
  SmallTeamsComponent,
  PersonalComponent,
} from '../components/susbcription';
import useCountrystore from '../../hooks/store/useCountrystore';

const countries = [
  'AT',
  'BE',
  'CY',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'SK',
  'SI',
  'ES',
  'HR',
];

export default function Landing() {
  const { country } = useCountrystore()

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const isBottom = scrollTop + clientHeight >= scrollHeight;
    if (isBottom) {
      mixpanel.track('Visit', { Behavior: 'Scrolled' });
    }
  };

  useEffect(() => {
    mixpanel.track('Visit', { Behavior: 'Landed' });
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let currency = '';
  if (countries.includes(country)) {
    currency = 'EUR';
  }

  return (
    <div className="main-content">
      <section className="about-section main-section">
        <div
          className="about"
          data-aos-duration="300"
          data-aos="fade-up"
        >
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-last">
              <div className="thumb text-left">
                <h1 className="thumb-title">
                  {Translate.get('landingTitle', 'Meet your new creative partner!')}
                </h1>
                <img src={ImgPartner} alt="img" className="price-img_fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-text text-left">
                <h1>
                  {Translate.get('landingTitle', 'Meet your new creative partner!')}
                </h1>
                <p>
                  {Translate.get('landingSubtitle', 'Seenapse is a unique combination of human lateral thinking and AI’s speed that allows you to generate hundreds of divergent, creative possibilities in minutes.')}
                </p>
                <a href="/register" className="btn">{Translate.get('landingButton', 'Try it for free now')}</a>
              </div>
            </div>
          </div>

          <section
            className="team- main-section"
            data-aos-duration="300"
            data-aos-delay="100"
            data-aos="fade-up"
          >
            <div className="section-title">
              <h1>
                {Translate.get('landingTeams', 'Inspiring teams at')}
              </h1>
            </div>
            <div className="team-logo">
              <div className="logo">
                <img
                  alt="img"
                  src={ImgTeams1}
                  className="price-img_fluid"
                />
              </div>
              <div className="logo">
                <img
                  alt="img"
                  src={ImgTeams2}
                  className="price-img_fluid"
                />
              </div>
              <div className="logo">
                <img
                  alt="img"
                  src={ImgTeams3}
                  className="price-img_fluid"
                />
              </div>
              <div className="logo">
                <img
                  alt="img"
                  src={ImgTeams4}
                  className="price-img_fluid"
                />
              </div>
              <div className="logo">
                <img
                  alt="img"
                  src={ImgTeams5}
                  className="price-img_fluid"
                />
              </div>
              <div className="logo">
                <img
                  alt="img"
                  src={ImgTeams6}
                  className="price-img_fluid"
                />
              </div>
            </div>
          </section>

          <Comments />

          <div
            className="about"
            data-aos-duration="300"
            data-aos-delay="100"
            data-aos="fade-up"
          >
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-last">
                <div className="thumb">
                  <h1 className="thumb-title text-left">
                    {Translate.get('landingInteresting', 'Great ideas, not just so-so')}
                  </h1>
                  <img src={ImgInteresting} alt="img" className="price-img_fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-text">
                  <h1>
                    {Translate.get('landingInteresting', 'Great ideas, not just so-so')}
                  </h1>
                  <p>
                    {Translate.get('landingSurprising', 'You know that ChatGPT and the rest generate ideas that are kind of obvious. Only Seenapse gives you interesting, surprising ideas that point to many different creative directions, inspired by humans, powered by AI.')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="about"
            data-aos-duration="300"
            data-aos-delay="100"
            data-aos="fade-up"
          >
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-last">
                <div className="thumb">
                  <h1 className="thumb-title">
                    {Translate.get('landingProposeTitle', 'You are the Creative Director')}
                  </h1>
                  <img src={ImgCreative} alt="img" className="price-img_fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-text">
                  <h1>
                    {Translate.get('landingProposeTitle', 'You are the Creative Director')}
                  </h1>
                  <p>
                    {Translate.get('landingPropose', 'Let Seenapse quickly propose dozens of divergent possibilities, from which you can spot those ugly ducklings you know you can turn into beautiful swans.')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video-section main-section">
        <div
          className="section-title aos-animate"
          data-aos-duration="500"
          data-aos-delay="700"
          data-aos="fade-up"
        >
          <h1>
            {Translate.get('landingWorks', 'How it works')}
          </h1>
        </div>
        <div
          className="video-thumb aos-animate"
          data-aos-duration="300"
          data-aos-delay="100"
          data-aos="zoom-in"
        >
          <iframe
            src="https://www.youtube.com/embed/_HDUQ5cdI_w"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </section>

      <section className="pricing-section main-section">
        <div className="row gap-4" style={{ margin: '0 auto' }}>
          <PersonalComponent amount="$24.99 / MONTH" />
          <SmallTeamsComponent amount="$499 / MONTH" />
          <BigTeamsComponent />
        </div>
      </section>
    </div>
  );
}
