import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Storage from 'localStorage';
import Translate from '../../utils/Translate';
import SeenapseResults from '../../containers/SeenapseResults';
import logo from '../../assests/img/logo-white.jpeg';
import Loader from '../loader';
import './BeforeGeneratingIdeas.css';
import './Inspired.css';


const PATH_IMAGE = 'https://4a1e70ba250472b18020-710c152fdea160d64661dc05136089d3.ssl.cf1.rackcdn.com/';

const buildResults = () => {
  const hits = JSON.parse(Storage.getItem('inspiredSeenapses'));
  return hits.map((element, index) => (
    <div className="search-results" key={`${index}-${element.id}}`}>
      <SeenapseResults
        seenapse={element}
        index={index}
        pathImage={PATH_IMAGE}
        currentPath="/before-generating-ideas"
      />
    </div>
  ));
};

const BeforeGenerateIdeas = (props) => {
  const { modalStore: { blur } } = props;

  const handleCloseModal = () => {
    const r = window.confirm('Make sure you have copied and pasted the ideas on a document, because when you close the window they will be gone.');
    if (r == true) {
      props.inspiredStore.showModal = !props.inspiredStore.showModal;
      props.modalStore.removeBlur();
    }
  };

  const copyCodeToClipboard = () => {
    const text = props.inspiredStore.message.idea.join('\n');
    navigator.clipboard.writeText(text).then(() => {
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
    });
  };

  const handleSubmit = (e, props) => {
    e.preventDefault();
    const element = e.currentTarget;
    const form = {};

    for (let i = 0; i < element.length; i++) {
      form[element[i].name] = element[i].value;
    }

    const hits = JSON.parse(Storage.getItem('inspiredSeenapses'));
    const seenapses = [];

    if (form.divergence === 'low') {
      hits.map((element, index) => {
        seenapses.push(parseInt(element.objectID));
      });
    }

    if (form.divergence === 'high') {
      console.log(hits.length);
      for (let i = hits.length - 1; i > -1; i--) {
        seenapses.push(parseInt(hits[i].objectID));
      }
    }

    if (form.divergence === 'medium') {
      let i = Math.ceil(hits.length / 2);
      let j = i - 1;
      while (j >= 0) {
        seenapses.push(parseInt(hits[j--].objectID));
        if (i < hits.length) seenapses.push(parseInt(hits[i++].objectID));
      }
    }

    props.modalStore.addBlur();
    props.modalStore.addBlurOnHeader();
    props.inspiredStore.getIdeas(form, seenapses);
  };

  return (
    <Fragment>
      <div className="row">
        {props.inspiredStore.showModal
          ? (
            <div className="inspired-ideas-modal">
              {props.inspiredStore.sending ? (
                <div className="generate-ideas-loader">
                  <Loader />
                </div>)

                : (
                  <div className="row">
                    <span onClick={handleCloseModal} className="inspired-ideas-close">x</span>
                    <div className="col-md-6">
                      <img src={logo} alt="Logo-Seenapse" />
                      <h1>{Storage.getItem('idea') === 'product' ? '' : Storage.getItem('brand')}</h1>
                      <p>
                        {props.inspiredStore.messages.length}
                        {' '}
                        {Translate.get('ideasInspiredByHumans', ' ideas inspired by humans and generated by artificial intelligence')}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="inspired-message">
                        {(props.inspiredStore.messages.map((message, index) => (
                          <h3 key={index} className="inspired-ideasTitle inspired-bottom">
                            {index + 1 }
.
                            {message}
                          </h3>
                        )))}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        id="register"
                        className="inspired-button inspired-ideas-button btnpadding"
                        onClick={copyCodeToClipboard}
                      >
                        {Translate.get('copyIdeas', 'Copy ideas')}
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          ) : (<div />)
        }
        <div className="row" style={{ filter: `blur(${blur})` }}>
          <div className="col-12 col-md-5 container-before-generating-ideas">
            { props.inspiredStore.sending ? (
              <div className="generate-ideas-loader">
                <Loader />
              </div>
            )
              : (
                <form onSubmit={e => handleSubmit(e, props)} className="beforeGeneratingIdeasForm">
                  <h1>{Translate.get('beforeGeneratingIdeas', 'Before Generating them…')}</h1>
                  <p>
                    {Translate.get('thisAreRecommendedSeenapses', 'These are the seenapses that we recommend to generate your ideas with a degree of divergence')}
                  </p>
                  <div className="select-wrapper">
                    <select name="divergence" className="before-generating-ideas-select" required>
                      <option value="low">{Translate.get('low', 'Low')}</option>
                      <option value="medium">{Translate.get('medium', 'Medium')}</option>
                      <option value="high">{Translate.get('high', 'High')}</option>
                    </select>
                  </div>
                  <p>
                    {Translate.get('whenYouClickTheButtonItWill', 'By clicking on the button, we will generate for you ')}
                  </p>
                  <p>
                    <input name="number" className="before-generating-ideas-input" placeholder="1" type="text" required />
                    {' '}
ideas
                  </p>
                  {/* <p>
                    {Translate.get("whichWillSubtract", "which will subtract ")} <strong>5 {Translate.get("credits", "credits ")}</strong>{Translate.get("fromYourBalance", "from your balance of ")} <strong>20</strong>.
                </p> */}
                  <p>{Translate.get('now', '')}</p>
                  <button
                    id="register"
                    type="submit"
                    className="generate-ideas-button"
                  >
                    {Translate.get('go', 'Go!')}
                  </button>
                </form>
              )
            }
          </div>
          <div className="col-12 col-md-7 pl-4 pt-4 pr-4 pb-4">
            <section
              className="container-fluid container-search"
              style={{ filter: `blur(${1})` }}
            >
              {buildResults()}
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default inject('inspiredStore', 'modalStore')(observer(BeforeGenerateIdeas));
