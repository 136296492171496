import { useEffect, useState } from "react";
import User from '../models/UserStore';
import { getUser } from "../services/user";

export default function useUser() {
  const [userId] = useState(User.getUid());
  const [loadingUser, setLoadingUser] = useState(false);
  const [user, setUser] = useState({
    has_subscription: '',
    requests_number: 0,
    days: ''
  })

  useEffect(() => {
    if (userId) {
      getUserInformation()
    }
  }, [userId])

  const getUserInformation = () => {
    setLoadingUser(true)
    getUser(userId)
      .then((response) => {
        setUser(response.data)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoadingUser(false))
  }

  return { user, loadingUser }
}
