import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import InfiniteScroll from 'react-infinite-scroller'
import SeenapseResults from '../SeenapseResults'
import Detail from '../Detail'
import UserStore from '../../models/UserStore'
import DefaultAvatar from '../../assests/img/avatar-default.png'
import NotificationResult from '../notificationResult'
import Context from '../../stores/Notificationstore'
import Translate from './../../utils/Translate'

export const PATH_IMAGE =
  'https://4a1e70ba250472b18020-710c152fdea160d64661dc05136089d3.ssl.cf1.rackcdn.com/'
export const SEENAPSE_PATH_IMAGES = 'https://seenapse.ai/dist/img/'

export const buildImageUrl = element => {
  return !element.img
    ? 'https://seenapse.ai/dist/img/avatar_default_v2.png'
    : element.img.includes('avatar_default')
    ? `${SEENAPSE_PATH_IMAGES}${element.img}`
    : element.img.includes('htt')
    ? element.img
    : `${PATH_IMAGE}${element.img}`
}

export const BuildPeopleResult = ({ element, index }) => {
  if (!element) {
    return
  }
  const { id, name } = element
  return (
    <Link
      key={index}
      to={`/user/${id}/seenapses`}
      className='link-seenapse underline'
    >
      <div className='search-results-people' key={index}>
        <div
          className='picUs'
          style={{
            backgroundImage: `url(${buildImageUrl(element)}),
            url(${DefaultAvatar})`
          }}
        />
        <div className='names-images'>{name}</div>
      </div>
    </Link>
  )
}

export const BuildSeenapseResult = ({ element, index, match }) => {
  const { url } = match
  if (element.type === 'follower') return <div>....loading</div>
  return (
    <div className='search-results' key={index}>
      <SeenapseResults
        currentPath={url}
        seenapse={element}
        index={index}
        pathImage={PATH_IMAGE}
      />
    </div>
  )
}

class AccountResults extends Component {
  static contextType = Context

  state = {
    data: this.props.results.data || this.props.results.seenapses.data,
    currentResult: this.props.results,
    page: this.props.results.page,
    child: this.props.params.child,
    parent: this.props.params.parent,
    isOpen: false,
    bodyTop: 0
  }

  componentDidMount = () => {
    const { resetTotalNotifications } = this.context
    const { isNotifications } = this.props
    const userId = UserStore.getUid()
    if (isNotifications) {
      this.handleNotificationsViewed(userId)
      resetTotalNotifications()
    }
  }

  componentWillReceiveProps(next) {
    const { match, params, results } = next
    if (!!next.match.params.parent && !!next.match.params.child) {
      this.props.modalStore.addBlurOnHeader()
      this.props.modalStore.addBlur()
      this.setState({
        bodyTop: document.querySelector('body').getBoundingClientRect().y,
        isOpen: true
      })
    } else {
      this.setState({ isOpen: false })
    }
    this.setState({
      child: params.child,
      parent: params.parent,
      data: results.data,
      currentResult: results,
      page: results.page,
      isOpen: (match.params.child && match.params.parent && true) || false
    })
  }

  handleNotificationsViewed = async userId => {
    // type === follower => userId = user.id
    // type === fav => userId = user.by.id = seenapse_id = id
    // type === children => seenapse_id = id
    const { data } = this.state
    const notify = data.map(item => {
      let seenapseId
      let hasUserId

      if (item.type === 'fav') {
        hasUserId = item.by.id
      }
      if (item.type === 'follower') {
        hasUserId = item.id
      }
      if (item.type === 'fav') {
        seenapseId = item.id
      } else if (item.type === 'children') {
        seenapseId = item.id
      }

      return {
        type: item.type,
        seenapse_id: seenapseId,
        user_id: hasUserId
      }
    })

    const params = {
      user_id: userId,
      notifications: [...notify]
    }

    try {
      await UserStore.setNotificationsViewed(params)
    } catch (error) {
      console.error(error)
    }
  }

  loadItems() {
    const { context, context_id, page, hitsPerPage } = this.state.currentResult
    const currentPage = parseInt(page, 10) + 1
    UserStore.getResultsByContext(
      context,
      context_id,
      currentPage,
      hitsPerPage
    ).then(response => {
      const currentResults = this.state.data
      this.setState({
        page: currentPage,
        data: [...currentResults, ...response.data.data],
        currentResult: response.data
      })
    })
  }

  buildResults = () => {
    const { typeResult, isNotifications, match } = this.props

    if (isNotifications && this.state.data.length === 0) {
      return (
        <div style={{ color: 'white', fontSize: 20 }}>
          {Translate.get(
            'noResults',
            `You don’t have any notifications at this moment :)`
          )}
        </div>
      )
    }

    return this.state.data.map((element, index) => {
      if (isNotifications) {
        return <NotificationResult element={element} index={index} />
      } else {
        if (typeResult !== 'people') {
          return (
            <BuildSeenapseResult
              element={element}
              index={index}
              match={match}
            />
          )
        } else {
          return <BuildPeopleResult element={element} index={index} />
        }
      }
    })
  }

  renderInfiniteScroll = () => {
    const {
      modalStore: { blur }
    } = this.props
    const { page, currentResult } = this.state

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadItems}
        threshold={250}
        hasMore={page < currentResult.nbPages}
      >
        <section
          className='container-fluid container-search' // revisar porque tiene object,object
          style={{ filter: `blur(${blur})`, padding: '56px' }}
        >
          {this.buildResults()}
        </section>
      </InfiniteScroll>
    )
  }

  render() {
    const { selected, match } = this.props
    let isOpen = (match.params.parent && match.params.child && true) || false
    if (isOpen) {
      document.querySelector('body').classList.add('locked')
      document.querySelector('body').style.top = `${this.state.bodyTop}px`
    } else {
      document.querySelector('body').classList.remove('locked')
      document.querySelector('body').style.top = ``
      if (this.state.bodyTop) {
        window.scroll(0, -this.state.bodyTop)
        this.setState({
          bodyTop: 0
        })
      }
    }
    return (
      <Fragment>
        {this.renderInfiniteScroll(selected)}
        {isOpen && (
          <Detail
            open={isOpen}
            selected={selected}
            parent={match.params.parent}
            child={match.params.child}
          />
        )}
      </Fragment>
    )
  }
}

export default inject('modalStore')(withRouter(observer(AccountResults)))
