import ModalStore from './ModalStore';
import UserStore from './UserStore';
import InspiredStore from "./InspiredStore";


const Stores = {
  modalStore: new ModalStore(),
  userStore: new UserStore(),
  inspiredStore: new InspiredStore(),
};

export default Stores;
