import React, { Fragment } from 'react'
import User from '../../../models/UserStore'
import Storage from 'localStorage'
import { inject } from 'mobx-react'
import mixpanel from 'mixpanel-browser';
/**
 * @class LoginOauth Controla el redirect de las redes para hacer login
 */
class LoginOauth extends React.Component {
  componentDidMount = () => {
    this.setUser()
  }

  /**
   * @argument params Url patterns
   */
  setUser = () => {
    const {
      userStore,
      match: { params }
    } = this.props

    const hasToken = params.userId ? params.token : ''
    userStore.setTempToken(params.token)
    
    User.saveSession(hasToken, params.userId)

    if (!Number(params.userId)) {
      if (params.type === 'email') {
        Storage.setItem('provider', params.type)
      }

      if (
        params.type === 'sso' ||
        params.type === 'twitter' ||
        params.type === 'facebook' ||
        params.type === 'google'
      ) {

        Storage.setItem('provider', params.type)

        if (params.type === 'google') {
          mixpanel.track('Sign up', {     'Method': 'Google'});
        }

        if (params.type === 'sso') {
          mixpanel.track('Sign up', {     'Method': 'SSO'});
        }
        
        
        this.props.history.push('/register/social')
      }
    } else {
      this.props.history.push('/')
      window.location.reload()
    }
  }

  render() {
    return <Fragment>loading...</Fragment>
  }
}
export default inject('userStore')(LoginOauth)
