import React from 'react';
import './styles.css';
import Close from '../../../components/icons/close';
import Translate from '../../../utils/Translate';
import Spinner from '../../../components/spinner';
import useBranchyStore from '../../../hooks/store/useBranchyStore';
import { createPortal } from 'react-dom';

export default function ProjectDialog({
  isOpen,
  onClose,
  loading
}) {
  const [value, setValue] = React.useState('');
  const { createProject } = useBranchyStore();

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const onSubmitProject = () => {
    createProject(value.trim());
    setValue('');
    onClose();
  }

  if (!isOpen) return null;
  const target = document.getElementById('modal-projects');

  return createPortal(
    <div className="project-dialog">
      <div className="project-dialog-ctn">
        <div className="project-dialog-close"><Close color="black" onClick={onClose} /></div>
        <div className="project-dialog-title">
          {Translate.get('addProject', 'Add Project')}
        </div>
        <div className="project-dialog-description">
          <div className="project-dialog-ctninput">
            <label
              htmlFor="projectTitle"
              className="project-dialog-label"
              id="projectTitle"
            >
              {Translate.get('projectTitle', 'Project Name')}
            </label>
            <input
              type="text"
              value={value}
              maxLength="50"
              id="projectTitle"
              placeholder='e.g. "My first project"'
              name="projectTitle"
              className="generate-ideas-input"
              onChange={handleChangeValue}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              type="submit"
              disabled={loading || !value}
              onClick={onSubmitProject}
              className="generate-ideas-button"
              style={{ textAlign: 'center' }}
            >
              {loading
                ? <Spinner style={{ margin: '0 auto', borderLeftColor: '#000' }} />
                : Translate.get('sendProject', 'Create project')
              }
            </button>
          </div>
        </div>
      </div>
    </div>,
    target
  );
}
