import axios from 'axios';
import Storage from 'localStorage';
import { API_HOST, API } from '../shared/constants/Constants';

export const getUser = (id) => {
  const langStore = Storage.getItem('lang');
  let translateLang = Storage.getItem('translate_lang')
  if (!langStore || !translateLang) {
    let userLang = navigator.language || navigator.userLanguage
    const lang = userLang.split('-')[0] === 'es' ? 'es' : 'en'
    Storage.setItem('lang', lang);
    Storage.setItem('translate_lang', lang);
  }
  if (!id) {
    return Promise.resolve();
  }

  return API.get(`${API_HOST}/api/v1/user/${id}?lang=${langStore}`);
};
