import React from 'react';
import '../loader/loader.css';
import loader from '../../assests/img/LoaderIA.gif';

const LoaderIA = () => (
  <div>
    <img src={loader} alt="loader" />
  </div>
);

export default LoaderIA;
