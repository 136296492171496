import algoliasearch from 'algoliasearch';
import Storage  from 'localStorage';

const client = algoliasearch(
  '9JE76GX7DN',
  'a1f28047f7a936635d54af561e0a68f0',
);
client.initIndex('seenapsesv4');

export default class AlgoliaStore {
  static hits(toSearch, page, pageSize, callback) {
    const data = [];
    const exactOptions = {
      'queryType': 'prefixNone',
      'removeWordsIfNoResults': 'allOptional',
      'typoTolerance': 'false',
      'ignorePlurals': false,
      'advancedSyntax': true,
      'synonyms': true,
      'hitsPerPage': pageSize,
      'page': page.matches,
    };

    const aproxOptions = {
      'queryType': 'prefixAll',
      'removeWordsIfNoResults': 'allOptional',
      'typoTolerance': 'true',
      'ignorePlurals': false,
      'advancedSyntax': true,
      'synonyms': false,
      'hitsPerPage': pageSize,
      'page': page.approximate,
    };
    
    const userLang = Storage.getItem('lang') || 'en';
    const lang = userLang.split("-")[0] === 'es' ? 'es' : 'en';

    const queries = [{
      indexName: `seenapses_${lang}_prod`,
      query: toSearch,
      params: exactOptions,
    },
    {
      indexName: `seenapses_${lang}_prod`,
      query: toSearch,
      params: aproxOptions,
    },
    {
      indexName: 'users_prod',
      query: toSearch,
      params: exactOptions,
    }];

    const meta = [{
      model: 'exact',
      label: 'Exact matches',
      color: '#D4E245',
    },
    {
      model: 'approximate',
      label: 'Approximate matches',
      color: '#91CAE5',
    },
    {
      model: 'people',
      label: 'People',
      color: '#9C7BC9',
    }];

    client.search(queries, (err, r) => {
      if (r && r.results) {
        r.results.forEach((item, i) => {
          item.label = meta[i].label;
          item.model = meta[i].model;
          item.color = meta[i].color;
        });
      }

      if (r && r.results.length > 0) {
        for (let i = 0; i < r.results[1].hits.length; i++) {
          let found = false;
          for (let j = 0; j < r.results[0].hits.length; j++) {
            if (r.results[1].hits[i].alias === r.results[0].hits[j].alias) {
              found = true;
            }
          }
          if (!found) {
            data.push(r.results[1].hits[i]);
          }
        }
      }

      r.results[1].hits = data;
      r.results[1].nbHits = data.length;
      return callback(err, r);
    });
  }

  static exact(toSearch, page, pageSize, callback) {
    const exactOptions = {
      'queryType': 'prefixNone',
      'removeWordsIfNoResults': 'allOptional',
      'typoTolerance': 'false',
      'ignorePlurals': false,
      'advancedSyntax': true,
      'synonyms': true,
      'hitsPerPage': pageSize,
      'page': page.matches,
    };
    const query = [{
      indexName: 'seenapsesv4',
      query: toSearch,
      params: exactOptions,
    }];
    client.search(query, (err, r) => {
      if (r && r.results) {
        r.totalPages = Math.ceil(r.results[0].nbHits / pageSize) - 1;
      }
      return callback(err, r);
    });
  }

  approximate(toSearch, page, pageSize, callback) {
    const self = this;
    const data = [];
    const aproxOptions = {
      'queryType': 'prefixAll',
      'removeWordsIfNoResults': 'allOptional',
      'typoTolerance': true,
      'ignorePlurals': false,
      'advancedSyntax': true,
      'synonyms': true,
      'hitsPerPage': pageSize,
      'page': page.aproximate,
    };
    const query = [{
      indexName: 'seenapsesv4',
      query: toSearch,
      params: aproxOptions,
    }];
    client.search(query, (err1, r1) => {
      self.exact(toSearch, page, pageSize, (err2, r2) => {
        if (r1 && r1.results.length > 0) {
          for (let i = 0; i < r1.results[0].hits.length; i++) {
            let found = false;
            for (let j = 0; j < r2.results[0].hits.length; j++) {
              if (r1.results[0].hits[i].alias === r2.results[0].hits[j].alias) {
                found = true;
              }
            }
            if (!found) {
              data.push(r1.results[0].hits[i]);
            }
          }
        }
        r1.results[0].hits = data;
        return callback(err1, r1);
      });
    });
  }

  people(toSearch, page, pageSize, callback) {
    const exactOptions = {
      'queryType': 'prefixNone',
      'removeWordsIfNoResults': 'allOptional',
      'typoTolerance': 'false',
      'ignorePlurals': false,
      'advancedSyntax': true,
      'synonyms': false,
      'hitsPerPage': pageSize,
      'page': page.people,
    }
    const query = [{
      indexName: 'users',
      query: toSearch,
      params: exactOptions,
    }];
    client.search(query, callback);
  }
}
