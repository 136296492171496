import React, { Component, Fragment } from 'react'
import axios from 'axios'
import User from '../models/UserStore'
import ShowMenu from './ShowMenu'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { API_HOST, API } from '../shared/constants/Constants'
import { isInSummaryModal } from '../utils/Utils'
import Storage from 'localStorage'

class SignIn extends Component {
  state = {
    isLogged: false,
    expandedLogIn: false,
    expandedSignUp: false,
    user: null,
    signInAction: true,
    lang: Storage.getItem('lang'),
  }

  signOut = () => {
    const { history } = this.props;
    history.push('/logout')
    this.onCloseModal()
  }

  onOpenModal = (action) => {
    // menu-hamburger
    const pathName = this.props.location.pathname
    this.props.modalStore.addBlur()
    this.props.modalStore.onOpenMenu(action)
    if (isInSummaryModal(pathName)) {
      this.props.modalStore.addBlurOnModal()
    } else {
      this.props.modalStore.addBlur()
    }
  }

  onCloseModal = () => {
    const pathName = this.props.location.pathname
    this.props.modalStore.onCloseMenu()
    if (isInSummaryModal(pathName)) {
      this.props.modalStore.removeBlurOnModal()
      this.props.modalStore.closeSignInModal()
    } else {
      this.props.modalStore.removeBlur()
    }
  }

  switchSignInAction = action => {
    this.setState({
      signInAction: action === 'login'
    })
  }

  /**
   * @description Consume el Api de seenapse, y obtiene los
   * datos del usuario.
   */
  componentDidMount() {
    let {
      history,
      location: { pathname: pathname }
    } = this.props

    if (User.isLoggedIn()) {
      const uid = User.getUid()
      var langStore = Storage.getItem('lang')
      var isDetail = pathname.indexOf('account') != -1 ? '&detail=true':'';
      API
        .get(`${API_HOST}/api/v1/user/${uid}?lang=${langStore}` + isDetail)
        .then(({ data }) => {
          if (!data.is_complete_profile && pathname !== '/register/social') {
            return history.push('/register/social')
          }
          this.setState({
            user: data,
            isLogged: true
          })
        })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isOpen } = nextProps.modalStore

    if (typeof isOpen !== 'undefined' || isOpen) {
      this.onOpenModal('openMenuExtend')
      this.expand('login')
    }

    this.setState({ isLogged: User.isLoggedIn() })
  }

  expand = action => {
    const pathName = this.props.location.pathname
    this.setState({
      expandedLogIn: action === 'login',
      expandedSignUp: action === 'signup'
    })
    if (action) {
      if (isInSummaryModal(pathName)) {
        this.props.modalStore.addBlurOnModal()
      } else {
        this.props.modalStore.removeBlurOnHeader()
      }
    } else {
      if (isInSummaryModal(pathName)) {
        this.props.modalStore.removeBlurOnHeader()
        this.props.modalStore.removeBlurOnModal()
      } else {
        this.props.modalStore.removeBlur()
      }
    }
  }
  handleChangeValueLang = (event, inspiredStore) => {
    const { value } = event.currentTarget
    this.setState({lang: value})
    Storage.setItem('lang', value)
    Storage.setItem('translate_lang', value)
    return false
  }

  render() {
    const {
      isLogged,
      expandedLogIn,
      expandedSignUp,
      user,
      signInAction,
      lang
    } = this.state

    const { inspiredStore, modalStore: { isOpenAvatarMenu, isOpenMenuExtend }} = this.props

    return (
      <Fragment>
        <ShowMenu
          lang={lang}
          handleChangeValueLang={(e) => {
            this.handleChangeValueLang(e)
            inspiredStore.setLang()
          }}
          isLogged={isLogged}
          expand={this.expand}
          isSearch={this.props.isSearch}
          onCloseModal={this.onCloseModal}
          expandedLogIn={expandedLogIn}
          expandedSignUp={expandedSignUp}
          user={user}
          isOpenAvatarMenu={isOpenAvatarMenu}
          isOpenMenuExtend={isOpenMenuExtend}
          signInAction={signInAction}
          switchSignInAction={this.switchSignInAction}
          signOut={this.signOut}
          onOpenModal={this.onOpenModal}
          modalStore={this.props.modalStore}
        />
      </Fragment>
    )
  }
}

export default inject('modalStore','inspiredStore')(withRouter(observer(SignIn)))
