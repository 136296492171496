import React from 'react';
import Privacy  from './privacy';
import Terms from './terms';
import WhatIsSeenapse from './whatIsSeenapse';
import NotFound from '../../content/components/not-found';

const render = pathname => {
  switch(pathname){
    case '/privacy':
      return <Privacy />
    case '/whatisseenapse':
      return <WhatIsSeenapse />
    case '/terms':
      return <Terms />
    default: 
      return <NotFound />
  } 
}

const Info = (props) => {
  const { location: { pathname }} = props;
  return render(pathname)
}

export default Info;
