import React from 'react';

const cache = [];

const randomColors = (max) => {
  const colors = ['#89CCE6', '#9578C2', '#F1993F', '#545A19', '#545A19', '#3B2E4C', '#613D18'];
  const index = Math.ceil(Math.random() * max);
  return colors[index];
};

const withLoadingBackground = Component => props => {
  if (!cache[props.index]) {
    cache.push(randomColors(6));
  }
  return <Component {...props} backgroundColor={cache[props.index]} />;
};

export default withLoadingBackground;
