import React, { Fragment, useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn, parseJwt } from '../utils/Utils';
import User from '../models/UserStore';
import { getUser } from '../services/user';
import Header from '../components/header/Header';
import Storage from 'localStorage'
import ProjectDialog from '../app/projects/addProject';
import useAddProjectstore from '../hooks/store/useAddProjectStore';

export default function ProtectedRoute({
  component: Component,
  ...rest
}) {
  const [userId] = useState(User.getUid());
  const { isOpenDialog, onCloseDialog } = useAddProjectstore()

  const hasUser = isLoggedIn()

  useEffect(() => {
    if (userId !== 'null' || userId) {
      getUser(userId)
        .then((response) => {
          const token = Storage.getItem('token') || '';
          const parseToken = token && parseJwt(token);
          if (!response.data.is_logged_in && parseToken && parseToken.type === 'sso') {
            window.location = '/logout';
            return;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [userId]);

  return (
    <Route
      {...rest}
      render={(props) =>
        hasUser ? (
          <Fragment>
            <Header
              history={props.history}
              user={{
                has_subscription: '',
                days: ''
              }}
            />
            <Component {...props} />
            <ProjectDialog
              isOpen={isOpenDialog}
              onClose={onCloseDialog}
            />
          </Fragment>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}
