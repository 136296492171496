import React from 'react'
import './Alert.css'
import Translate from './../../utils/Translate'

export default function RegisterSuccess(props) {
  if (props.open) {
    const showMessage = props.message
      ? props.message
      : Translate.get(
          'almostText',
          'Please check your email inbox for our message and click on the link provided to activate your account :)'
        )
    return (
      <div className='lb on' onClick={props.onOk}>
        <div className='message almost'>
          <div className='content'>
            <div className='title'>
              {Translate.get('almostTitle', 'Almost done!')}
            </div>
            {showMessage}
          </div>
        </div>
      </div>
    )
  }
  return <div />
}
