import React from 'react';
import Utils from '../../utils/Utils';
import './SignInOptions.css';
import { API_HOST } from '../../shared/constants/Constants';

export default function SignInOptions({ isSearch, expandedLogIn }) {
  return (
    <div
      className={`login-links ${isSearch ? 'container-login-links-search' : 'container-login-links'}`}
    >
      {!isSearch
        && (
        <React.Fragment>
          <a
            className="icon-login no-underline"
            href={Utils.getLinkEmailAuth(expandedLogIn)}
          >
            <span className="logo-links icon-email" />
            <span className="email">
              email
            </span>
          </a>
          <a className="icon-login no-underline" href={`${API_HOST}/auth/google`}>
            <span className="logo-links icono-google" />
            <span className="google">
              google
            </span>
          </a>
        </React.Fragment>
        )
      }

      {isSearch
        && (
        <React.Fragment>
          <a
            className="icon-login icon-login-search no-underline"
            href={Utils.getLinkEmailAuth(expandedLogIn)}
          >
            <span className="logo-links icon-email" />
          </a>
          <a className="icon-login no-underline" href={`${API_HOST}/auth/google`}>
            <span className="logo-links icono-google" />
            <span className="google" style={{ display: 'none' }}>
              google
            </span>
          </a>
        </React.Fragment>
        )
      }
    </div>
  );
}
