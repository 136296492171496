import { useHistory, useLocation } from 'react-router-dom';
import { stringifyQuery, isEmpty, parseQuery } from '../utils/Utils';


export default function useNavigation (){
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = parseQuery(search)

  const onNavigate = (route, { searchParams, state }) => {
    const stringifyParams = !isEmpty(searchParams)
      ? stringifyQuery(searchParams || {})
      : ''

    history.push({
      pathname: route,
      search: stringifyParams,
      state: state
    })
  }

  return {
    onNavigate,
    state: history.location.state || {},
    pathname: history.location.pathname,
    searchParams: searchParams
  }
}
