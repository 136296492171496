import React, { useEffect, useRef, Component } from 'react';
import Chat from './Chat';
import { findNewestCard } from '../utils/cardUtils';
import useBranchyStore from '../../../hooks/store/useBranchyStore';
import Loader from '../../../components/loader'

export default function ChatComponent  ()  {
  const chatEndRef = useRef(null);
  const {
    currentProject,
    loadingProject,
    submiting,
    onMessageToApi
  } = useBranchyStore();

  // Scroll to newest card when conversation updates
  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      scrollToNewestCard();
    }, 100);
    return () => clearTimeout(scrollTimer);
  }, [currentProject]);

  // Function to scroll to the newest card
  const scrollToNewestCard = () => {
    if (currentProject && currentProject.length > 1) {
      const { element: newestCard } = findNewestCard(currentProject);
      if (newestCard) {
        setTimeout(() => {
          newestCard.scrollIntoView({ behavior: 'smooth', block: 'end' });
          // Additional scroll to ensure it works in Safari
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  };


  if (loadingProject || submiting) {
    return (
      <Loader
        style={{ marginTop: 80, zIndex: 500, backdropFilter: 'blur(12px)' }}
      />
    )
  }
  return (
    <div className="chat-container overflow-auto flex flex-col !py-4 bg-custom-black min-h-screen">
      {currentProject.length > 0 && (
        <Chat
          conversations={currentProject}
          onSendMessage={onMessageToApi}
        />
      )}
      <div ref={chatEndRef} />
    </div>
  );
};
