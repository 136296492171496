import axios from 'axios';
import Storage from 'localStorage';

// export const API_HOST = 'http://localhost:4000';
export const API_HOST = 'https://api-v2.seenapse.it'
// export const API_HOST = 'https://api-v2-sandbox.seenapse.it'
// export const API_BRANCHY = "https://paas-txhju.ondigitalocean.app";


export const API = axios.create({ baseURL: API_HOST });


API.interceptors.request.use((config) => {
  const token = Storage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized access detected');
      Storage.clear();
    }
    return Promise.reject(error);
  }
);
