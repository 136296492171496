import React from 'react';
import "./Alert.css"
import Translate from './../../utils/Translate'

export default function CheckErrors(props){
  if (props.open) {
    return <div className="lb on">
      <div className="message check">
        <div className="content">
            <div className="title">{Translate.get('errInfoFields', 'Please check the following')}:</div>
            <ul>
             {
                props.errors.map((e) => {
                return <li>{e}</li>
                })
              } 
            </ul>
          <div className="action">
            <a id="close" onClick={props.onOk}>Ok</a>
          </div>
        </div>
      </div>
    </div>
  }
  return <div/>;
}