import React, { Fragment, Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Storage from 'localStorage'
import DefaultAvatar from '../../assests/img/avatar-default.png'
import { defaultAvatar } from '../../utils/Utils'
import './Account.css'
import Utils from '../../utils/Utils'
import UserStore from '../../models/UserStore'
import avatarDefault from '../../assests/img/avatar-default.png'
import Translate from './../../utils/Translate'

const optionsOfAccount = [
  {
    text: 'seenapses',
    link: 'seenapses'
  },
  {
    text: Translate.get('favorites', 'favorites'),
    link: 'favs'
  },
  {
    text: Translate.get('followers', 'followers'),
    link: 'followers'
  },
  {
    text: Translate.get('followees', 'followees'),
    link: 'followees'
  }
]

const optionsOfNotifications = [
  {
    text: 'seenapsed',
    link: 'seenapsed'
  },
  {
    text: Translate.get('favorited', 'favorited'),
    link: 'favorited'
  },
  {
    text: Translate.get('followed', 'followed'),
    link: 'followed'
  }
]
const userId = Storage.getItem('uid')
class HeaderAccount extends Component {
  state = {
    followLabel: Translate.get('follow', 'FOLLOW'),
    areFollower: false
  }

  componentDidMount() {
    this.checkFollowStatus()
  }

  buildLinks(selected, userData) {
    const {
      isInNotifications,
      match: { params }
    } = this.props
    const results = isInNotifications
      ? optionsOfNotifications
      : optionsOfAccount
    const resultsFiltered = results.filter(e => e.link !== selected)

    return (
      <p className='message-results'>
        {Translate.get('ShowInstead', 'Show instead')}{' '}
        {!isInNotifications && Translate.get('their', 'their')}
        {resultsFiltered.map((result, index) => {
          return (
            <Fragment key={index}>
              <Link
                key={index}
                to={`/${params.module}/${this.props.userData.uid}/${result.link}`}
                style={{ marginLeft: 5 }}
                className='link-seenapse underline'
              >
                {!isInNotifications && result.text}
                {isInNotifications && `${userData[result.text]} ${result.text}`}
              </Link>
              {isInNotifications && index === 0 && <span> or </span>}
            </Fragment>
          )
        })}
        {isInNotifications &&
          ' ' + Translate.get('notifications', 'notifications')}
      </p>
    )
  }

  getClassResults() {
    return this.props.displayUserInfo
      ? 'results-seenapses-account'
      : 'results-seenapses'
  }

  checkFollowStatus = () => {
    const { userData } = this.props
    const areFollower = userData.followers.ids.includes(parseInt(userId))
    let followLabel = areFollower
      ? Translate.get('unFollow', 'UNFOLLOW')
      : Translate.get('follow', 'FOLLOW')
    this.setState({ followLabel: followLabel, areFollower: areFollower })
  }

  handleFollowStatus = () => {
    const { params } = this.props
    const { areFollower } = this.state
    if (userId) {
      UserStore.followStatus(params.userId, areFollower).then(data => {
        this.setState({
          followLabel: !areFollower
            ? Translate.get('unFollow', 'UNFOLLOW')
            : Translate.get('follow', 'FOLLOW'),
          areFollower: !areFollower
        })
      })
    } else {
      this.props.modalStore.openMessageLogin()
    }
  }

  render() {
    const { followLabel } = this.state
    const {
      selected,
      displayUserInfo,
      total,
      userData,
      isInNotifications,
      match: { params }
    } = this.props
    const {
      modalStore: { blur }
    } = this.props
    const bio = (userData || {}).bio
    if (bio) {
      bio.replace('\n', '')
    }
    const userId = Storage.getItem('uid')
    return (
      <Fragment>
        <div style={{ filter: `blur(${blur})` }}>
          {displayUserInfo && (
            <div id='header-info'>
              <div className='info-container'>
                <div className='info'>
                  <div className='user-avatar'>
                    <img
                      src={userData.img || avatarDefault}
                      alt='Avatar Account'
                      onError={e => defaultAvatar(e, DefaultAvatar)}
                    />
                  </div>
                  <h4 className='name'>{userData.name}&nbsp;&nbsp;</h4>
                  <h4 className='cat' style={{ marginBottom: 0, fontSize: 16 }}>
                    {bio}
                  </h4>
                  <h6 className='cat'>
                    <small>
                      Seenapse rank:{' '}
                      {Utils.getSeenapserLevel(userData.seenapses.nbHits)}
                    </small>
                  </h6>
                </div>
                {params.userId !== userId  && (
                  <div className='right'>
                    <div className='title' onClick={this.handleFollowStatus}>
                      <h2 className='pref theme-blue'>{followLabel}</h2>
                    </div>
                  </div>
                )}
                {(params.module === 'account' || params.userId === userId) && (
                  <div className='button-prefs'>
                    <Link className='theme-blue' to='/update-profile'>
                      {Translate.get('editPrefs', 'EDIT PREFERENCES')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={this.getClassResults()}>
            {!isInNotifications ? (
              <p className='message-results'>
                {Translate.get('Showing', 'Showing')} {total}&nbsp;
                {userData.name} {selected === 'favs' ? 'favorites' : selected}
              </p>
            ) : (
              <p className='message-results'>
                {Translate.get('Showing', 'Showing')} {userData[selected]}{' '}
                {selected} {Translate.get('notifications', 'notifications')}
              </p>
            )}
            {this.buildLinks(selected, userData)}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default inject('modalStore')(withRouter(observer(HeaderAccount)))
