import React,{ Fragment,Component } from 'react';
import AlgoliaStore from '../models/AlgoliaStore';
import { inject, observer } from 'mobx-react';
import Loader from '../components/loader'
import SeenapseResults from './SeenapseResults';
import withLadingBackground from '../utils/withLoadingBackground';
import Detail from './Detail';
import './BeforeGeneratingPage.css';
import Translate from '../utils/Translate';
import Storage from "localStorage"


const PAGE_SIZE = 150;
const PATH_IMAGE = 'https://4a1e70ba250472b18020-710c152fdea160d64661dc05136089d3.ssl.cf1.rackcdn.com/';
const buildResults = (selected) => {
    let hits = JSON.parse(Storage.getItem('inspiredSeenapses'));
      return hits.map((element, index) => {
        return (
        <div className="search-results" key={`${selected}-${element.id}}`}>
            <SeenapseResults
                seenapse={element}
                index={index}
                pathImage={PATH_IMAGE}
                currentPath = {"/before-generating-ideas"}
                />
        </div>
        )
        
      })
}

const BeforeGenerateIdeas = props => {
    return(
        <Fragment>
            <div className="row">
                <div className="col-12 col-md-5 container-before-generating-ideas">
                    <h1>{Translate.get("beforeGeneratingIdeas", "Before Generating them…")}</h1>
                    <p>{Translate.get("thisAreRecommendedSeenapses", "These are the seenapses that we recommend to generate your ideas with a degree of divergence")}
                    </p>
                    <div class="select-wrapper">
                        <select className="before-generating-ideas-select">
                            <option value="ideaT1">{Translate.get("low", "Low")}</option>
                            <option value="ideaT2">{Translate.get("medium", "Medium")}</option>
                            <option value="ideaT3">{Translate.get("high", "High")}</option>
                        </select>
                    </div>
                    <p>
                        {Translate.get("whenYouClickTheButtonItWill", "By clicking on the button, we will generate for you ")} 
                    </p>
                    <p>
                        <input className="before-generating-ideas-input" placeholder="0" type="number"/> ideas
                    </p>
                    <p>
                        {Translate.get("whichWillSubtract", "which will subtract ")} <strong>5 {Translate.get("credits", "credits ")}</strong>{Translate.get("fromYourBalance", "from your balance of ")} <strong>20</strong>.
                    </p>
                    <p>{Translate.get("now", "")}</p>
                    <button
                        id="register"
                        type="submit"
                        className="generate-ideas-button"
                    >
                        {Translate.get("go", "Go!")}
                    </button>
                </div>
                <div className="col-12 col-md-7 pl-4 pt-4 pr-4 pb-4">
                    <section
                    className="container-fluid container-search"
                    style={{filter:`blur(${1})`}}
                    >
                        {buildResults()}
                    </section>
                </div>
                
            </div>
        </Fragment>
   )
}

class BeforeGeneratingPage extends Component {
  state = {
    matches: null,
    approximate: null,
    people: null,
    isOpen: true,
    pages:{
      matches:0,
      approximate:0,
      people:0,
    },
    totalPages:{
      matches:1,
      approximate:1,
      people:1,
    },
    selected: this.props.match.params.type || 'matches',
    bodyTop: 0
  };

  resetState() {
    this.setState({
      matches: null,
      approximate: null,
      people: null,
      pages:{
        matches:0,
        approximate:0,
        people:0,
      },
      totalPages:{
        matches:1,
        approximate:1,
        people:1,
      },
      selected: this.props.match.params.type || 'matches',
    })
  }

  componentWillReceiveProps(nextProps){
    if(!!nextProps.match.params.parent && !!nextProps.match.params.child){
      this.props.modalStore.addBlurOnHeader();
      this.setState({bodyTop: document.querySelector('body').getBoundingClientRect().y, isOpen: true});
    }else{
      this.props.modalStore.removeBlur();
      this.setState({isOpen: false});
    }
  }

  componentDidMount() {
    this.props.modalStore.addBlur();
    this.props.modalStore.addBlurOnHeader();
    const pathName = this.props.location.pathname;
    this.fetchElements();
  }

  componentDidUpdate(){
  }

  fetchElements() {
    this.resetState()
   
    AlgoliaStore.hits(
      this.props.match.params.query,
      this.state.pages,
      PAGE_SIZE,
    (e, result) => {
      const matches = result.results[0];
      const approximate = result.results[1];
      const people = result.results[2];
      const data = {
        search: this.props.match.params.query,
        exact: result.results[0].nbHits,
        approximate: result.results[1].nbHits,
        people: result.results[2].nbHits
      };
      this.setState({
         matches,
         approximate,
         people,

      })
      
    })
  }

  render() {
    const { matches, approximate, people, selected } = this.state;
    const { match, location:{ state } } = this.props;
    const { modalStore: { blur } } = this.props;
    
    if (this.state.isOpen){
      document.querySelector('body').classList.add('locked');
      document.querySelector('body').style.top = `${this.state.bodyTop}px`;
    }else{
      document.querySelector('body').classList.remove('locked');
      document.querySelector('body').style.top = ``;
      if (this.state.bodyTop){
        window.scroll(0, -this.state.bodyTop);
        this.setState({
          bodyTop: 0
        });
      }
    }
    
    if (!matches && !approximate  && !people ) {
      return <Loader/>
    }

    return(
      <Fragment>
        <Fragment>
            <div className="row" style={{filter:`blur(${blur})`}}>
                <div className="col-12 col-md-5 container-before-generating-ideas">
                    <h1>{Translate.get("beforeGeneratingIdeas", "Before Generating them…")}</h1>
                    <p>{Translate.get("thisAreRecommendedSeenapses", "These are the seenapses that we recommend to generate your ideas with a degree of divergence")}
                    </p>
                    <div class="select-wrapper">
                        <select className="before-generating-ideas-select">
                            <option value="ideaT1">{Translate.get("low", "Low")}</option>
                            <option value="ideaT2">{Translate.get("medium", "Medium")}</option>
                            <option value="ideaT3">{Translate.get("high", "High")}</option>
                        </select>
                    </div>
                    <p>
                        {Translate.get("whenYouClickTheButtonItWill", "By clicking on the button, we will generate for you ")} 
                    </p>
                    <p>
                        <input className="before-generating-ideas-input" placeholder="0" type="number"/> ideas
                    </p>
                    <p>
                        {Translate.get("whichWillSubtract", "which will subtract ")} <strong>5 {Translate.get("credits", "credits ")}</strong>{Translate.get("fromYourBalance", "from your balance of ")} <strong>20</strong>.
                    </p>
                    <p>{Translate.get("now", "")}</p>
                    <button
                        id="register"
                        type="submit"
                        className="generate-ideas-button"
                    >
                        {Translate.get("go", "Go!")}
                    </button>
                </div>
                <div className="col-12 col-md-7 pl-4 pt-4 pr-4 pb-4">
                    <section
                        className="container-fluid container-search"
                        style={{filter:`blur(${blur})`, maxHeight:'90vh', overflowY: 'scroll'}}
                    >
                        {buildResults()}
                    </section>
                </div>
                
            </div>
        </Fragment>
        {this.state.isOpen &&(
          <Detail
            open={this.state.isOpen}
            selected={selected}
            query={matches.query}
            parent={match.params.parent}
            child={match.params.child}
            isNewSeenapse={(state || {}).isNewSeenapse}
          />
        )}
      </Fragment>
    );
  }
}

export default inject(
  'modalStore',
)(withLadingBackground(observer(BeforeGeneratingPage)));
