import { observable, decorate, action } from "mobx"
import { addIdea, getInspiration, getIdeas, getTranslation } from '../services/seenapse'
import Storage from "localStorage"

class InspiredStore {
  isOpen = false
  ideaCreated = {}
  messages = []
  sending = false
  showModal = false
  lang = Storage.getItem('lang')

  openModal = () => {
    this.isOpen = true
  }

  closeModal = () => {
    this.isOpen = false
  }

  clearIdeaCreate = () => {
    this.message = ""
    this.sending = false
    this.showModal = false
  }

  setLang = () => {
    this.lang = Storage.getItem('lang')
  }

  saveValue = (name, value) => {
    const cleanValue = this.cleanStr(value)
    Storage.setItem(name, cleanValue)
    this.ideaCreated = {
      ...this.ideaCreated,
      [name]: value
    }
  }

  getValues = () => {
    this.ideaCreated = {
      brand: Storage.getItem("brand") || "",
      whatisit: Storage.getItem("whatisit") || "",
      keyattributes: Storage.getItem("keyattributes") || "",
      occasion: Storage.getItem("occasion") || "",
      problem: Storage.getItem("problem") || "",
    }
  }
   
  cleanStr(description) {
    return description
              .replace(/&#039;/g, "'")
              .replace(/&quot;/g, "\"")
              .replace(/"/g, "")
              .replace(/\s\s+/g, "")
              .replace(/(<([^>]+)>)/ig, "")
              .trim()
  }

  async getInspiration(values) {
    const formBody = await this.getBody()
    const params = {
      form: formBody
    }

    this.sending = true
    this.showModal = true

    getInspiration(params)
      .then(({ data }) => {
        Storage.setItem('inspiredSeenapses', JSON.stringify(data.results.hits))
        const seenapses = this.buildSeenapses(values.divergence)
        let i = 0;
        this.messages = [];
        for(i=0; i<10; i++) {
          this.getIdeas(formBody, [seenapses[Math.floor((Math.random()*seenapses.length))]]);
        }
      })
      .catch((error) => {
        this.sending = false
        console.error(error)
      })
  }

  getIdeas(formBody, seenapses) {
    const params = {
      seenapses: seenapses,
      form: formBody
    }
    
    getIdeas(params)
      .then(({ data }) => {
        this.messages.push(data.data[0]); 
      })
      .catch((error) => {
        console.error(error)
        this.sending = false

        if (error.response) {
          if(error.response.status ===400) {
            window.location = '/'
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      })
      .finally(() => {
        this.sending = false
    })
  }

  async getBody() {
    const lang = Storage.getItem("translate_lang")
    const idea = Storage.getItem("idea") || 'hero_content'
    const bodyIdea = await this.getBodyIdeaLang(idea, lang)
    return this.setDefaults(bodyIdea)
  }

  getIdeaValues() {
    const defaultValues = {
      brainstorm_idea: Storage.getItem("brainstormIdea"),
      problem: Storage.getItem("problem"),
      key_attributes: Storage.getItem("keyattributes"),
      what_is_it: Storage.getItem("whatisit"),
      concept: Storage.getItem("concept"),
      topic: Storage.getItem("topic"),
      pillar: Storage.getItem("pillar"),
      occasion: Storage.getItem('occasion')
    }
    return defaultValues
  }

  async getBodyIdeaLang(idea, lang) {
    const values = this.getIdeaValues()
    return {
      "en": this.getBodyIdea(idea, values),
      "es": this.getBodyCustomLangIdea(idea, values)
    }[lang];
  }

  async getBodyCustomLangIdea(idea, values) {
    const toTranslate = this.getBodyIdea(idea, values)
    const translation = await getTranslation({translate: toTranslate});
    return translation.data.data;
  }

  getBodyIdea(idea, values) {
    return {
      "hero_content": this.getBodyHerocontent(values),
      "headline": this.getBodyHeadlines(values),
      "naming": this.getBodyNaming(values),
      "product": this.getBodyProduct(values),
      "tagline": this.getBodyTagline(values),
      "video_script": this.getBodyVideoScript(values),
      "brainstorm": this.getBodyBrainstorm(values),
      "socialpost": this.getBodySocialPost(values)
    }[idea]
  }

  getBodyHerocontent(values) {
    return  { 
      what_is_it: values.what_is_it,
      key_attributes: values.key_attributes,
      occasion: values.occasion
    }
  }

  getBodyHeadlines(values) {
    return {
      what_is_it: values.what_is_it,
      key_attributes: values.key_attributes,
      occasion: values.occasion
    }
  }
  
  getBodyNaming(values) {
    return {
      what_is_it: values.what_is_it,
      key_attributes: values.key_attributes
    }
  }

  getBodyProduct(values) {
    return {
      problem: values.problem
    }
  }

  getBodyTagline(values) {
    return {
      what_is_it: values.what_is_it,
      key_attributes: values.key_attributes
    }
  }

  getBodyVideoScript(values) {
    return {
      what_is_it: values.what_is_it,
      key_attributes: values.key_attributes,
      occasion: values.occasion
    }
  }

  getBodyBrainstorm(values) {
    return {
      brainstorm_idea: values.brainstorm_idea
    }
  }

  getBodySocialPost(values) {
    return {
      tone: values.tone,
      key_attributes: values.key_attributes,
      concept: values.concept,
      pillar: values.pillar,
      topic: values.topic
    }
  }
  
  setDefaults(bodyIdea) {
   return {
      uid: Storage.getItem("uid"),
      lang: 'en',
      translate_lang: Storage.getItem("translate_lang"),
      idea: Storage.getItem("idea") || 'hero_content',
      brand: Storage.getItem("brand"),
      divergence: Storage.getItem("divergence") || 'low',
      number: 1,
      generate_images: true,
      ...bodyIdea
    }
  }
  
  buildSeenapses(type) {
    let hits = JSON.parse(Storage.getItem('inspiredSeenapses'));
    let seenapses = [];
  
    if (type === 'low') {
      hits.map((element, index) => {
        seenapses.push(parseInt(element.objectID));
      })
    }
  
    if (type === 'high') {
      console.log( hits.length);
      for (let i = hits.length-1; i > -1; i--) {
        seenapses.push(parseInt(hits[i].objectID));
      }
    }
  
    if (type === 'medium') {
      let i = Math.ceil(hits.length/2);
      let j = i - 1;
      while (j >= 0)
      {
        seenapses.push(parseInt(hits[j--].objectID));
        if (i < hits.length) seenapses.push(parseInt(hits[i++].objectID));
      }
    }
    return seenapses;
  }
}

export default decorate(InspiredStore, {
  isOpen: observable,
  messages: observable,
  openModal: action,
  sending: observable,
  showModal: observable,
  ideaCreated: observable,
})
