import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Seenapse from '../components/detail/Seenapse'
import AddSeenapse from '../components/detail/AddSeenapse'
import { sendMetric } from '../services/mixpanel'
import './Detail.css'
import Storage from 'localStorage'
import { API_HOST } from '../shared/constants/Constants'

class Detail extends Component {
  state = {
    parent: null,
    child: null,
    parentParam: null,
    childParam: null
  }

  getSeenapses = (alias, category) => {
    const userLang = Storage.getItem('lang') || 'en'
    const lang = userLang.split('-')[0] === 'es' ? 'es' : 'en'
    axios
      .get(`${API_HOST}/api/v1/seenapse/alias/${alias}?lang=${lang}`)
      .then(({ data }) => {
        this.setState({
          [category]: data
        })
      })
  }

  componentWillReceiveProps(nextParams) {
    const { parentParam, childParam } = this.state
    if (
      nextParams.parent &&
      nextParams.child &&
      nextParams.parent !== parentParam &&
      nextParams.child !== childParam
    ) {
      this.setState({
        parentParam: nextParams.parent,
        childParam: nextParams.child,
        open: true
      })
      this.props.modalStore.addBlur()
      this.getSeenapses(nextParams.parent, 'parent')
      this.getSeenapses(nextParams.child, 'child')
    }
  }

  componentDidMount() {
    const { parent, child, isNew } = this.props
    if (parent && child) {
      this.props.modalStore.addBlur()
      this.getSeenapses(parent, 'parent')
      this.getSeenapses(child, 'child')
      const data = {
        alias: `${parent}/${child}`
      }
      sendMetric('Seenapse View', data)
    }
    if (isNew && parent) {
      this.props.modalStore.addBlur()
      this.getSeenapses(parent, 'parent')
    }
  }

  onCloseModal = e => {
    if (this.props.isNew) {
      return
    }

    const stoppers = [
      'seenapseImg',
      'thumbnail',
      'content',
      'seenapse-url-loader'
    ]
    let isStopper = false
    stoppers.forEach(stopper => {
      if (e.target.closest(`.${stopper}`)) {
        isStopper = true
      }
    })
    if (isStopper) return
    const {
      selected,
      query,
      history,
      modalStore,
      match: {
        params: { module: modulo, userId, section }
      },
      location: { pathname }
    } = this.props
    modalStore.removeBlur()
    if (pathname.indexOf('search') !== -1 && pathname.split('/').length <= 6) {
      history.push(`/search/${selected}/${query}`)
    } else if (userId) {
      history.push(`/${modulo}/${userId}/${section}`)
    } else if (pathname.indexOf('before-generating-ideas') !== -1)  {
      history.push(`/before-generating-ideas`)
    } else {
      history.push(`/`)
    }
  }

  validatingTheElementsTarget = e => {
    const { showMessageLogin } = this.props.modalStore
    let getClass = ''
    if (showMessageLogin) {
      return false
    }
    if (typeof e.target.className === 'object') {
      getClass = e.target.className.baseVal
    } else {
      getClass = e.target.className
    }

    this.getFirstClass(getClass)
  }

  getFirstClass(getClass) {
    const firstClass = getClass.split(' ')
    const position = firstClass.length - 1
    if (firstClass[position] === 'close-modal') {
      this.onCloseModal()
    }
  }

  render() {
    const { parent, child } = this.state
    const { open, location, isNew, isEdit } = this.props
    if (isEdit) {
      return (
        <AddSeenapse
          isNew={false}
          parent={parent}
          child={child}
          open={open}
          onClose={this.onCloseModal}
          location={location}
          validatingTheElementsTarget={this.validatingTheElementsTarget}
          index={1}
        />
      )
    } else if (isNew) {
      return (
        <AddSeenapse
          isNew={true}
          parent={parent}
          child={child}
          open={open}
          onClose={this.onCloseModal}
          location={location}
          validatingTheElementsTarget={this.validatingTheElementsTarget}
          index={1}
        />
      )
    } else {
      return (
        <Seenapse
          isNew={isNew}
          parent={parent}
          child={child}
          open={open}
          onClose={this.onCloseModal}
          location={location}
          validatingTheElementsTarget={this.validatingTheElementsTarget}
          index={1}
        />
      )
    }
  }
}

export default inject('modalStore')(withRouter(observer(Detail)))
