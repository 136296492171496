import React from 'react';
import ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker'
import mixpanel from 'mixpanel-browser';
import { BrowserRouter } from 'react-router-dom';
import AOS from 'aos';

import './index.css';
import 'aos/dist/aos.css';

import Routes from './router';

mixpanel.init("6005a050026c4fd4d872681c3aa77b8e");

AOS.init();

ReactDOM.render(
  <BrowserRouter>
    <Routes />
  </BrowserRouter>,
  document.getElementById('root'),
);
// registerServiceWorker()
