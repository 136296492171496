import React from 'react'
import IosCloseIcon from 'react-ionicons/lib/IosClose'
import { inject, observer } from 'mobx-react'

import './Modal.css'

const Modal = props => {
  const { modalStore } = props
  const classes = ['modal']
  classes.push(props.isPortrait ? 'portrait' : 'landscape')
  if (props.open) {
    return (
      <div
        style={{
          filter: `blur(${
            modalStore.blurOnModalSession ? modalStore.blurSession : '0'
          })`
        }}
        ref={props.onModalAvailable}
        className={classes.join(' ')}
        onClick={props.onClose}
      >
        <div className='background'></div>
        {props.children}
      </div>
    )
  }
  return null
}
export default inject('modalStore')(observer(Modal))
