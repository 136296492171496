import axios from 'axios';
import Storage from 'localStorage';
import {API_HOST} from '../shared/constants/Constants';

export const updateFavs = (seenapseId) => {
  const headers = {
    user_id: parseInt(Storage.getItem('uid')),
    seenapse_id: seenapseId,
    lang: Storage.getItem('lang')
  };

  return axios.post(`${API_HOST}/api/v1/action/fav`, headers);
};
