import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import "./PasswordChange.css";
import ApiUser from '../../shared/login/ApiUser';
import Translate from '../../utils/Translate';
import CheckErrors from '../../components/alerts/CheckErrors';

class PasswordChange extends Component {
  state = {
    password: "",
    password_confirmation: "",
    showRememberSuccess: false,
    showAlertErrors: false
  };
  
  closeModalErrors = () => {
    this.setState({showAlertErrors: false});
  }

  updateField = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  onSumit = (e) => {
    e.preventDefault();
    const {password} = this.state;
    const {match: {params: token}} = this.props;
    const errors = this.validateForm(this.state);
    
    if (errors.length > 0) {
      this.setState({errors: errors, showAlertErrors: true});
      return
    }

    ApiUser.updatePassword(token, password)
      .then((response) =>{
        window.location = '/login/email';
      })
      .catch( error => alert("error"));
  }

  validateForm = (formData) => {
    var errorMsg = {
      password_confirmation: Translate.get('errPassConfirmation' ,'Please enter a valid email confirm password'),
      password: Translate.get('errPass', 'Please enter a password'),
      password_not_match: Translate.get('errPassNotMatch', 'Passwords not matched'),
    };
    var errors = [];
    for (var k in formData) {
      if (typeof formData[k] !== 'function') {
        if (k != 'bio' && k != 'profilepic') {
          if (formData[k].length==0) {
            errors.push(errorMsg[k]);
          }
        }
      }
    }
    
    if (formData.password !== formData.password_confirmation) {
        errors.push(errorMsg['password_not_match'])
    }

    return errors;
  }

  render() {
    const {showAlertErrors, errors} = this.state;
    return <div>
      <CheckErrors open={showAlertErrors} errors={errors} onOk={this.closeModalErrors}/>
      <div class="header-spacer"></div>
        <div class="login-email-form">
        <form  id="formPassword">
            <fieldset>
            <div class="field">
                <label for=""><span class="theme-blue m-right">></span>{Translate.get('newPassword', 'New password')}</label>
                <input type="password" id="password" name="password" onChange={(e) => {this.updateField('password', e.target.value)}} required />
            </div>
            </fieldset>
            <fieldset>
            <div class="field">
                <label for=""><span class="theme-blue m-right">></span>{Translate.get('passwordConfirm', 'Confirm your Password')}</label>
                <input type="password" id="password" name="password_confirmation" onChange={(e) => {this.updateField('password_confirmation', e.target.value)}} required />
            </div>
            </fieldset>
            <div class="action">
            <button id="sendRequest" onClick={this.onSumit} class="right">{Translate.get('resetYourPassword','RESET YOUR PASSWORD')}</button>
            </div>
        </form>
        </div>
    </div>
  }
}

const change = inject("modalStore")(observer(PasswordChange));
export default withRouter(change)
