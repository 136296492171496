import React, { Fragment } from 'react';
import Subscription from '../../app/subscription';
import useUser from '../../hooks/useUser';
import Loader from '../../components/loader';
import useCountrystore from '../../hooks/store/useCountrystore';
import { validateTrialFinish } from '../../utils/Utils';
import './content.css';

const Content = () => {
  const { user, loadingUser } = useUser()
  const { country } = useCountrystore()
  // If days are less than 7 to show the form
  // If days are more than 7 and suscription == null to show the trail was finish
  // If days are more than 7 and suscription == 1 to show the form
  // If days are more than 7 and suscription == 2 to show the renew message
  const showTrialFinish = !validateTrialFinish(user)
  const showRenew = user.days >= 7 && user.has_subscription === 2;
  return (
    <div className="main-content">
      {loadingUser
        ? <Loader
          style={{
            marginTop: 80,
            zIndex: 500,
            backdropFilter: 'blur(12px)'
          }}
        />
        :
        <Subscription
          user={user}
          country={country}
          showRenew={showRenew}
          showTrialFinish={showTrialFinish}
        />
      }
    </div>
  );
};

export default Content;
